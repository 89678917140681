import React, { Component } from "react";
import * as APPCONFIG from "../../../config/app-config.js";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import AuthService from "../../../session/AuthService";
import moment from "moment";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import MyYuukke from "../../../api/MyYuukke";
import Swal from "sweetalert2";
import dummyimage from "../../../img/Yuukke_Cover.jpg";
import banner from "../../../img/banner.jpg";
import { withRouter } from "react-router";
import { AiFillMessage } from "react-icons/ai";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { MdOutlineGroups } from "react-icons/md";
import { giveUserAction } from "../../../utils.js";
import { needPhotVerification } from "../../../redux/actions/commonApiActions.js";
class GroupsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 0,
      topic_name: "",
      menuStatewithDelete: false,
    };

    this.movesubjectidtosubjects = this.movesubjectidtosubjects.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.getMessageFiles = this.getMessageFiles.bind(this);
    // this.getTagList = this.getTagList.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.getMessageFiles(this.props.grouporeventFiles, this.props.fileType);
    //  this.getTagList(this.props.tagsValue);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tagsValue != nextProps.tagsValue) {
      //  this.getTagList(nextProps.tagsValue);
    }
    this.getMessageFiles(nextProps.grouporeventFiles, nextProps.fileType);
  }

  render() {
    const { t } = this.props;
    const {
      subjectId,
      subjectName,
      questionsCount,
      createdDate,
      expiryDate,
      memberShipPrice,
      memberShipType,
      membersCount,
      grouporeventFiles,
      subjectDescription,
      fileType,
      displayName,
      userId,
      status,
      tagListId,
      startDate,
      communityTypeName,
    } = this.props;
    return (
      <div>
        <div className="announcementWrapperOuter">
          <div
            className="forumCardWrapper"
            style={{ borderRadius: "10px", cursor: "pointer" }}
            onClick={(e) =>
              this.handleChangeForumCard(e, subjectId, subjectName)
            }
          >
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                {this.state.fileData &&
                  this.state.fileData.slice(0, 1).map((photo) => (
                    <div class="community-v2-card">
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={photo}
                        alt="name"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                      />
                    </div>
                  ))}
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                {" "}
                <div>
                  <h6 className="font-weight-bold mb-1">{subjectName}</h6>
                  <p className="text-secondary " style={{ fontSize: "12px" }}>
                    {this.getSearchedTagValue(this.props.tagsValue)}
                  </p>{" "}
                  <p className="text-secondary f-12 d-flex">
                    <MdOutlineGroups
                      style={{
                        width: "20px",
                        height: "24px",
                        color: "#A00030",
                        paddingBottom: "5px",
                      }}
                    />{" "}
                    {membersCount <= 1 ? (
                      <p className="memberCount">
                        {membersCount} {t("Member")}
                      </p>
                    ) : (
                      <p className="memberCount">
                        {membersCount} {t("Members")}
                      </p>
                    )}
                  </p>
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={12} xs={12}>
                {/* {status === 'private' && ( */}
                <div class="forum-applyButton">
                  <button onClick={(e) => this.joinGroup(e)}>
                    {t("Join")}
                  </button>
                </div>
                {/* )} */}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";

    console.log("Tag Value", searchKeyword);
    console.log("Tag Value tags", tags);

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().startsWith(searchKeyword.toLocaleLowerCase())
      );
      // const result = tags.filter(val => val.tagName.toLowerCase().startsWith(searchKeyword.toUpperCase()))

      console.log("Tag searchvalue", result);

      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };
  // getTagList(tagList) {
  //   console.log("tagList",tagList);
  //   console.log(tagList);
  //   // console.log(this.props.tagList);
  //    var subjectName = new Array();
  //   if (tagList !== undefined && tagList.length > 0) {
  //     for (let i = 0; i < tagList.length; i++) {
  //       for (let j = 0; j < this.props.tagList.data.length; j++) {
  //         if (tagList[i].tagId == this.props.tagList.data[j].tagId) {
  //           subjectName.push(this.props.tagList.data[j].tagName);
  //         }
  //       }
  //       var subjects = subjectName.join(",");

  //       this.setState({ questionTags: subjects });
  //     }
  //   }
  // }
  editGroup() {
    this.props.history.push(`/updateGroup/${this.props.subjectId}`);
  }

  deletePost(event, subId) {
    event.preventDefault();
    event.stopPropagation();

    Swal.fire({
      title: "Are you sure to delete this event?",
      text: "",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var deleteSubId = {
          subjectId: subId,
        };
        MyYuukke.deleteSubjectById(deleteSubId)
          .then((response) => {
            this.props.getGroupsorEventsList();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  joinGroup = (event) => {
    let userName = this.props.loginDetails.dName;
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");

      this.props.needPhotVerification(true);
    } else {
      Swal.fire({
        title: `${this.props.t("Do you want to join the community ?")}`,
        showCancelButton: true,
        confirmButtonText: `${this.props.t("confirm")}`,
        cancelButtonText: `${this.props.t("cancel")}`,
        reverseButtons: true,
        icon: "warning",
      })
        .then((result) => {
          if (result.value) {
            Swal.fire({
              title: `${this.props.t("Joined Successfully")}`,
              showConfirmButton: true,
              icon: "success",
            }).then((result) => {
              this.saveOrUpdateMemberList(userName, this.props.subjectId, "");
            });
          }
        })

        .catch((error) => {
          console.log(error);
        });
    }
    event.preventDefault();
    event.stopPropagation();
  };
  saveOrUpdateMemberList(userName, subId, memberShipPrice) {
    let date = moment().format("YYYY-MM-DD");
    let memberList = {
      userName: userName,
      groupsOrEvents: subId,
      paymentAmount: memberShipPrice,
      joinDate: date,
    };
    MyYuukke.saveMemberList(memberList)
      .then((response) => {
        var gName = this.props.subjectName.trim();
        gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
        this.props.history.push(
          `/${this.props.workspaceType}/forum-community/${subId}/${gName}`,
          { from: window.location.pathname }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChangeForumCard = (event, id, subjectName) => {
    var gName = subjectName.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceType}/forum-community/${id}/${gName}`,
      { from: window.location.pathname }
    );
    // }
  };
  movesubjectidtosubjects(id, subjectName) {
    console.log("communityTypeName", this.props.communityTypeName);
    var gName = subjectName.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    this.props.history.push(
      `/${this.props.workspaceType}/groupDescription/${id}/${gName}`
    );
    // if(this.props.communityTypeName === "Forum Community"){
    //   //forum
    //     this.props.history.push(`/forum-community/${id}/${gName}`);
    //   }else{
    // this.props.history.push(`/groupDescription/${id}/${gName}`)
    //  }

    // this.props.gotoGroups(
    //   subjectId,
    //   true,
    //   subjectName,
    //   "fgroup",
    //   grouporeventFiles,
    //   fileType,
    //   subjectDescription,
    //   memberShipPrice,
    //   memberShipType,
    //   expiryDate,
    //   createdDate,
    //   displayName,
    //   userId,
    //   status,
    //   tagListId,
    //   startDate
    // );
  }

  async getMessageFiles(files, type) {
    if (type == "image") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/image/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "text") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(dummyimage);
        }
        await this.setState({ fileData: fileList });
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "video") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push(`${APPCONFIG.API_URL}auth/video/${files[i].fileName}`);
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "pdf") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else if (type == "file") {
      let fileList = [];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          fileList.push({
            fileName: `${APPCONFIG.API_URL}auth/file/${files[i].fileName}`,
            originalFileName: files[i].originalFileName,
          });
        }
        await this.setState({ fileData: fileList });
        console.log("FILE LIST");
        console.log(this.state.fileData);
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    } else {
      let fileList = [];
      if (files.length > 0) {
      } else {
        fileList.push(dummyimage);
        await this.setState({ fileData: fileList });
      }
    }
  }
}

const mapStateToProps = function (state) {
  return {
    tagList: state.commonApi.taglist,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};
const mapDispacthToProps = (dispatch) => {
  return {
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupsCard)));

// export default withTranslation()(withRouter(GroupsCard));
