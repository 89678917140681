import React, { useEffect } from "react";
import BlockUi from "react-block-ui";
import { Modal } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";
import EventForm from "../screens/Calender/EventForm";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getUserCommunityList } from "../redux/actions/subjectActions";

const MemberEvent = (props) => {
  const dispatch = useDispatch();
  const communityList = useSelector(
    (state) => state.subjects.userOwnedCommunity
  );
  useEffect(() => {
    dispatch(getUserCommunityList());
  }, []);
  return (
    <Modal
      show="true"
      onHide={() => props.handleClosePopUp("close")}
      style={{ borderRadius: "12px" }}
    >
      <Modal.Header className="text-center">
        {!props.backPrevent && (
          <IoMdArrowBack
            onClick={() => props.handleClosePopUp("back")}
            style={{
              cursor: "pointer",
              marginRight: "auto",
              marginBottom: "8px",
              width: "20px",
              height: "20px",
              color: "#000000",
            }}
          />
        )}
        <h6>Add Event in Your Community</h6>
        <AiOutlineCloseCircle
          onClick={() => props.handleClosePopUp("close")}
          style={{
            cursor: "pointer",
            marginLeft: "auto",
            marginBottom: "8px",
            width: "20px",
            height: "20px",
            color: "#000000",
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <EventForm
          onBack={() => props.handleClosePopUp("back")}
          isHomePost={true}
          communityList={communityList}
        />
      </Modal.Body>
    </Modal>
  );
};

export default MemberEvent;
