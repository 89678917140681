import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import EditableTextField from "../../../components/EditableTextField";
import EditableDropdown from "../../../components/EditableDropdown";
import EditableDropdownGrade from "../../../components/EditableDropdownGrade";
import AuthService from "../../../session/AuthService";
import ProfileService from "../../../api/ProfileService";
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import moment from "moment";
import EditableDate from "../../../components/EditableDatePicker";
import { withRouter } from "react-router";
import BlockUi from "react-block-ui";
import LoadService from "../../../utils/LoadService";
import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";
import * as microsoftTeams from "@microsoft/teams-js";
import { connect } from "react-redux";
import FindFriendsFilter from "../../../api/FindFriendsFilter";
import "../../css/mylike.css";
import { FiTwitter } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import TagMenuProfile from "../../../components/TagMenuProfile";

import { VscCheck } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import EditableTextFieldLinkedIn from "../../../components/EditableTextFieldLinkedIn";
import EditableTextFieldFacebook from "../../../components/EditableTextFieldFacebook";
import EditableTextFieldTwitter from "../../../components/EditableTextFieldTwitter";

class FirstSetupProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schoolId: "",
      schools: [],

      disbaledTagMenu: false,
      disbaledTagMenuLearn: false,
      disbaledTagMenuTeach: false,
      TagMenuValue: null,
      disabledSaveBtn: true,
      age: 0,
      userProfile: {},
      langList: [],
      semail: "",
      gender: "",
      firstLanguage: "",
      flanguages: "",
      joinyear: "",
      joinYear: "",
      liveyear: "",
      liveYear: "",
      schoolList: [],
      fromCountry: [],
      areaNames: [],
      linkedInValid: true,
      genderList: [
        { id: "male", name: this.props.t("Male") },
        { id: "female", name: this.props.t("Female") },
        { id: "other", name: this.props.t("Other") },
      ],
      subjectName: [],
      learningTeachingSubjectName: [],
      teachingSubjectName: [],
      editProfile: false,
      gradeList: [],
      grade: "",
      curriculumList: [],
      curriculamId: "",
      trackList: [],
      country: [],
      trackId: "",
      savebtnState: false,
      dName: "",
      firstName: "",
      lastName: "",
      countryId: "",
      location: "",
      blocking: false,
      professionID: false, //new
      companyName: "", //new
      areaOfInterest: false, //new
      industryId: false, //new
      industrysList: [],
      areaofinterestsList: [],
      ShortBio: "", //new
      linkedin: "", //new
      facebook: "", //new
      twitter: "", //new
      title: "", //new
      // profession: [
      //   {
      //     id: 1,
      //     name: "Salaried",
      //   },
      //   {
      //     id: 2,
      //     name: "Self-employeed",
      //   },
      // ],
      areaofInterestList: [],
      validLinkedIn: "",
      validFacebook: "",
      validTwitter: "",
      profession: "",
      professionList: [
        {
          id: 1,
          name: "student",
        },
        {
          id: 2,
          name: "Working professional",
        },
        {
          id: 3,
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          id: 4,
          name: "Entreprenur",
        },
        {
          id: 5,
          name: "Homemaker",
        },
        {
          id: 6,
          name: "Freelancer",
        },
      ],
    };
    this.block = this.block.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.backProfile = this.backProfile.bind(this);
    this.handleClickTagMenuEdit = this.handleClickTagMenuEdit.bind(this);
    this.getSelectedLanguage = this.getSelectedLanguage.bind(this);
    this.storeUserData = this.storeUserData.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.getGradeDetails = this.getGradeDetails.bind(this);
    this.getLanguageNameAlone = this.getLanguageNameAlone.bind(this);
    this.getSubjectNameAlone = this.getSubjectNameAlone.bind(this);
    this.getAreaofInterestId = this.getAreaofInterestId.bind(this);
    this.getlearningSubjectId = this.getlearningSubjectId.bind(this);
    this.getteachingSubjectId = this.getteachingSubjectId.bind(this);
    this.getProfileValues = this.getProfileValues.bind(this);
    this.getTrackAndCurriculum = this.getTrackAndCurriculum.bind(this);
    this.getCuriculumDetails = this.getCuriculumDetails.bind(this);
    this.gettrackDetails = this.gettrackDetails.bind(this);
    this.getOldLanguageList = this.getOldLanguageList.bind(this);
    this.onDataCheck = this.onDataCheck.bind(this);
    this.onDataCheckFacebook = this.onDataCheckFacebook.bind(this);
    this.onDataCheckTwitter = this.onDataCheckTwitter.bind(this);
    this.getOldLearningSubjectList = this.getOldLearningSubjectList.bind(this);
    this.getOldTeachingSubjectList = this.getOldTeachingSubjectList.bind(this);
    this.handleClickTagMenuEditLearn =
      this.handleClickTagMenuEditLearn.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleClickTagMenuEditTeach =
      this.handleClickTagMenuEditTeach.bind(this);
    this.getGradebasedCurriculum = this.getGradebasedCurriculum.bind(this);
    this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.getAllSchool = this.getAllSchool.bind(this);
    // this.getAllSchoolglobal=this.getAllSchoolglobal.bind(this);
    this.getIndustryDetails = this.getIndustryDetails.bind(this);
    this.getAllIndustry = this.getAllIndustry.bind(this);
    this.getIndustry = this.getIndustry.bind(this);

    this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
    this.getSchoolDetails = this.getSchoolDetails.bind(this);
    this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
    this.getGradeByCurriculumIdUpdate =
      this.getGradeByCurriculumIdUpdate.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallLanguage = this.getallLanguage.bind(this);
    this.getallSubject = this.getallSubject.bind(this);
    this.getTrackAndCurriculumList = this.getTrackAndCurriculumList.bind(this);
    this.getGrade = this.getGrade.bind(this);
    this.getGradeLists = this.getGradeLists.bind(this);
    this.getSchool = this.getSchool.bind(this);
    this.getAreaofInterest = this.getAreaofInterest.bind(this);
    this.getOldAreaOfInterest = this.getOldAreaOfInterest.bind(this);
    this.getAllCountry = this.getAllCountry.bind(this);
    this.getCountry = this.getCountry.bind(this);
  }

  handleClickTagMenuEdit() {
    this.setState({
      disbaledTagMenu: false,
      savebtnState: false,
    });
  }

  handleClickTagMenuEditLearn() {
    this.setState({
      disbaledTagMenuLearn: false,
      savebtnState: false,
    });
  }

  handleClickTagMenuEditTeach() {
    this.setState({
      disbaledTagMenuTeach: false,
      savebtnState: false,
    });
  }

  componentDidMount() {
    this.getAllCountry();

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    let userData = this.props.loginDetails;

    if (AuthService.isMSTeamsApp()) {
      microsoftTeams.appInitialization.notifySuccess();
    }

    //var selected_date = moment(userData.dob).format("DD/MM/yyyy");

    var selected_date = moment(userData.dob).format("DD/MM/yyyy");
    let userProfile = userData;
    console.log("userprofile");
    console.log("**********userData", userData);

    if (userData) {
      let age = "";
      if (userData.dob) {
        age = selected_date;
      }

      let sEmail = "";
      if (userData.secEmail) {
        sEmail = userData.secEmail;
      }

      let gender = "";
      if (userData.gender) {
        gender = userData.gender;
      }
      let location = "";
      if (userData.location) {
        location = userData.location;
      }
      let grade = "";
      if (userData.gradeId) {
        grade = userData.gradeId;
      }
      let firstLanguage = "";
      if (userData.fLanguage) {
        firstLanguage = userData.fLanguage;
      }
      let joinYear = "";
      if (userData.jYear) {
        joinYear = userData.jYear;
      }
      let liveYear = "";
      if (userData.lYear) {
        liveYear = userData.lYear;
      }

      let curriculumId = "";
      if (userData.cId) {
        curriculumId = userData.cId;
      }
      let trackId = "";
      if (userData.tId) {
        trackId = userData.tId;
      }
      let countryId = "";
      if (userData.countryId) {
        countryId = userData.countryId;
      }
      let dName = "";
      if (userData.dName) {
        dName = userData.dName;
      }

      let firstName = "";
      if (userData.fName) {
        firstName = userData.fName;
      }

      let lastName = "";
      if (userData.lName) {
        lastName = userData.lName;
      }

      let schoolId = "";
      if (userData.sclId) {
        schoolId = userData.sclId;
      }

      console.log(userData);

      let companyName = "";
      if (userData.company_Id) {
        companyName = userData.company_Id;
      }

      let areaOfInterest = "";
      if (userData.area_of_interest) {
        areaOfInterest = userData.area_of_interest;
      }

      let ShortBio = "";
      if (userData.biography) {
        ShortBio = userData.biography;
      }

      let industry = "";
      if (userData.industry_Id) {
        industry = userData.industry_Id;
      }

      let profession = "";

      if (userData.profession_Id) {
        profession = userData.profession_Id;
      }
      let twitter = "";
      if (userData.twitter) {
        twitter = userData.twitter;
      }
      let linkedin = "";
      if (userData.linkedin) {
        linkedin = userData.linkedin;
      }
      let facebook = "";
      if (userData.facebook) {
        facebook = userData.facebook;
      }
      let title = "";
      if (userData.title) {
        title = userData.title;
      }
      this.getProfileValuesV2(
        companyName,
        profession,
        industry,
        countryId,
        ShortBio,
        areaOfInterest,
        twitter,
        linkedin,
        facebook,
        title
      );

      this.getProfileValues(
        userProfile,
        age,
        countryId,
        sEmail,
        gender,
        grade,
        location,
        firstLanguage,
        joinYear,
        liveYear,
        curriculumId,
        trackId,
        dName,
        firstName,
        lastName,
        schoolId
      );
    }

    let schoolList = [];
    var currentYear = new Date().getUTCFullYear();
    for (var i = 1980; i <= currentYear; i++) {
      var obj = {};
      obj["id"] = i;
      obj["name"] = i;
      schoolList.push(obj);
    }
    this.setState({ schoolList: schoolList });
    this.getallGrade();
    if (this.props.firstTime === true) {
      this.getTrackAndCurriculum();
      this.getAllSchool();
      this.getAllAreaofInterest(userData.profileAreaOfInterest);
      this.getAllIndustry();
      console.log(this.state.grade !== "");
    } else {
      this.getTrackAndCurriculumList();
      this.getSchool();
      this.getAreaofInterest(userData.profileAreaOfInterest);
      this.getIndustry();
    }
  }

  initfunction() {
    let userData = this.props.loginDetails;
    //this.getallLanguage(userData.uLanguage);
    //this.getallSubject(userData.uLearn, userData.uTeach);
  }
  getAllCountry() {
    let getAllCountry = {};
    ProfileService.getAllCountry(getAllCountry)
      .then((response) => {
        var result = response.data;
        console.log("get all country by id", result.data);
        this.setState({ countryList: result.data });
        console.log("countryList", this.state.countryList);
        this.getCountry();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCountry() {
    var country = this.state.countryList;
    console.log("countryList" + country);
    if (country) {
      var lList = [];
      for (var i = 0; i < country.length; i++) {
        var obj = {};
        obj["id"] = country[i].countryId;
        obj["name"] = country[i].countryName;
        lList.push(obj);
      }

      this.setState({ country: lList });
      console.log(lList);
    }
    console.log("country", this.state.country);
  }
  handleForm(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async getProfileValuesV2(
    companyName,
    profession,
    industry,
    countryId,
    ShortBio,
    areaOfInterest,
    twitter,
    linkedin,
    facebook,
    title
  ) {
    await this.setState({
      companyName,
      profession: profession,
      industryId: industry,
      countryId: countryId,
      ShortBio: ShortBio,
      areaOfInterest: areaOfInterest,
      twitter: twitter,
      linkedin: linkedin,
      facebook: facebook,
      title: title,
    });
  }

  async getProfileValues(
    userProfile,
    age,
    countryId,
    email,
    gender,

    grade,
    location,
    firstLanguage,
    joinYear,
    liveYear,
    curriculumId,
    trackId,
    dName,
    firstName,
    lastName,
    schoolId
  ) {
    await this.setState({
      userProfile: userProfile,
      age: age,
      countryId: countryId,
      semail: email,
      gender: gender,
      grade: grade,

      location: location,
      firstLanguage: firstLanguage,
      flanguages: firstLanguage,
      joinYear: joinYear,
      joinyear: joinYear,
      liveYear: liveYear,
      liveyear: liveYear,
      curriculumId: curriculumId,
      trackId: trackId,
      dName: dName,
      firstName: firstName,
      lastName: lastName,
      schoolId: schoolId,
    });
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  render() {
    const { t } = this.props;
    const { disbaledTagMenu } = this.state;

    return (
      <div
        className="screenInnerGap"
        style={{
          marginLeft: "22px",
          marginRight: "22px",
          marginBottom: "22px",
          marginTop: "80px",
        }}
      >
        <div className="askAQuestionCardWrapper">
          <div className="proileFormWrapper">
            <BlockUi
              tag="div"
              blocking={this.state.blocking}
              message={t("Loading, please wait")}
              color="#60c2e7"
            >
              <Grid container spacing={0}>
                <Grid item md={12} sm={12} xs={12}>
                  <p className="profileTitle">
                    Hi {this.props.loginDetails.dName}
                  </p>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <EditableTextField
                    label={t("First Name") + " *"}
                    value={this.state.firstName}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      this.setState({
                        firstName: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <EditableTextField
                    label={t("Last Name") + " *"}
                    value={this.state.lastName}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      this.setState({
                        lastName: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <EditableTextField
                    label={t("Display Name") + " *"}
                    value={this.state.dName}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      this.setState({
                        dName: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <EditableDate
                    label={t("Date of birth") + " *"}
                    value={this.state.age}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      this.setState({
                        age: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>

                {/* //state */}
                <Grid item md={6} sm={12} xs={12}>
                  <EditableTextField
                    label={t("Location") + " *"}
                    value={this.state.location}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      this.setState({
                        location: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                  {/* <EditableDropdownGrade
                  label={t("Location")}
                  value={this.state.grade}
                  options={this.state.gradeList}
                  getProgramId={this.state.curriculumId}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      grade: value,
                      disabledSaveBtn: false,
                    });
                  }}
                /> */}
                </Grid>

                {/* region/ */}
                <Grid item md={6} sm={12} xs={12}>
                  <EditableDropdown
                    label={t("Country") + " *"}
                    value={this.state.countryId}
                    options={this.state.country}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      console.log(value);
                      this.setState({
                        countryId: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>
                {/* //new field */}
                <Grid item md={6} sm={12} xs={12}>
                  <EditableDropdown
                    label={t("Profession") + " *"}
                    value={this.state.profession}
                    options={this.state.professionList}
                    handleClickEdits={this.handleClickEdit}
                    getSelectedValue={(value) => {
                      console.log(value);
                      this.setState({
                        profession: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>

                {/* <Grid item md={6} sm={12} xs={12}>
                <EditableDropdown
                  label={t("Industry")}
                  value={this.state.industryId}
                  options={this.state.industrysList}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    console.log(value);
                    this.setState({
                      industryId: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid> */}

                {/* { //new api } */}
                {/* <Grid item md={6} sm={12} xs={12}>
                <EditableTextField
                  label={t("Company Name")}
                  value={this.state.companyName}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    this.setState({
                      companyName: value,
                      disabledSaveBtn: false,
                    });
                  }}
                  // alphabet={true}
                />
              </Grid> */}
                {/* new field */}

                {/* <Grid item md={6} sm={12} xs={12}>
                <EditableDropdown
                  label={t("Job Functions")}
                  value={this.state.schoolId}
                  options={this.state.schools}
                  handleClickEdits={this.handleClickEdit}
                  getSelectedValue={(value) => {
                    console.log(value);
                    this.setState({
                      schoolId: value,
                      disabledSaveBtn: false,
                    });
                  }}
                />
              </Grid> */}

                {/* // new api */}
                <Grid item md={6} sm={12} xs={12}>
                  <label>{t("Area of Interest") + " *"}</label>
                  <TagMenuProfile
                    allMenus={this.state.areaofInterestList}
                    addLabel={"Add"}
                    selectedOldName={this.state.subjectNames}
                    isTagMenuFilter={true}
                    value={"English"}
                    getSelectedMenus={(value) => {
                      this.setState({
                        areaOfInterest: value,
                        savebtnState: false,
                      });
                      this.getAreaofInterestId(value);

                      this.setState({
                        languages: value,
                        disabledSaveBtn: false,
                      });
                    }}
                  />
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <EditableTextFieldLinkedIn
                        label={t("LinkedIn")}
                        value={this.state.linkedin}
                        onChange={this.handleForm}
                        onDataCheck={this.onDataCheck}
                        handleClickEdits={this.handleClickEdit}
                        getSelectedValue={(value) => {
                          this.setState({
                            linkedin: value,
                            disabledSaveBtn: false,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={1} alignItems="flex-start">
                      {this.state.validLinkedIn == "true" && (
                        <VscCheck
                          style={{
                            width: "24px",
                            marginTop: "24px",
                            height: "52px",
                            color: "#32CD32",
                          }}
                        />
                      )}

                      {this.state.validLinkedIn == "false" && (
                        <VscChromeClose
                          style={{
                            width: "24px",
                            marginTop: "24px",
                            height: "52px",
                            color: "#FF0000",
                          }}
                        />
                      )}
                      {this.state.validLinkedIn == "" && <></>}
                    </Grid>
                    <Grid item xs={1}>
                      <FaLinkedinIn
                        style={{
                          width: "20px",
                          marginTop: "20px",
                          height: "58px",
                          color: "#F97516",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(this.state.linkedin, "_blank")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <EditableTextFieldTwitter
                        label={t("Twitter")}
                        value={this.state.twitter}
                        onChange={this.handleForm}
                        onDataCheck={this.onDataCheckTwitter}
                        handleClickEdits={this.handleClickEdit}
                        getSelectedValue={(value) => {
                          this.setState({
                            twitter: value,
                            disabledSaveBtn: false,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={1} alignItems="flex-start">
                      {this.state.validTwitter == "true" && (
                        <VscCheck
                          style={{
                            width: "24px",
                            marginTop: "24px",
                            height: "52px",
                            color: "#32CD32",
                          }}
                        />
                      )}

                      {this.state.validTwitter == "false" && (
                        <VscChromeClose
                          style={{
                            width: "24px",
                            marginTop: "24px",
                            height: "52px",
                            color: "#FF0000",
                          }}
                        />
                      )}
                      {this.state.validTwitter == "" && <></>}
                    </Grid>
                    <Grid item xs={1}>
                      <FiTwitter
                        style={{
                          width: "23px",
                          marginTop: "23px",
                          height: "53px",
                          color: "#F97516",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(this.state.twitter, "_blank")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <EditableTextFieldFacebook
                        label={t("FaceBook")}
                        value={this.state.facebook}
                        onDataCheck={this.onDataCheckFacebook}
                        handleClickEdits={this.handleClickEdit}
                        onChange={this.handleForm}
                        getSelectedValue={(value) => {
                          this.setState({
                            facebook: value,
                            disabledSaveBtn: false,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} alignItems="flex-start">
                      {this.state.validFacebook == "true" && (
                        <VscCheck
                          style={{
                            width: "24px",
                            marginTop: "24px",
                            height: "52px",
                            color: "#32CD32",
                          }}
                        />
                      )}

                      {this.state.validFacebook == "false" && (
                        <VscChromeClose
                          style={{
                            width: "24px",
                            marginTop: "24px",
                            height: "52px",
                            color: "#FF0000",
                          }}
                        />
                      )}
                      {this.state.validFacebook == "" && <></>}
                    </Grid>
                    <Grid item xs={1}>
                      <FiFacebook
                        style={{
                          width: "24px",
                          marginTop: "24px",
                          height: "52px",
                          color: "#F97516",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(this.state.facebook, "_blank")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* region/ */}
                <Grid item md={6} sm={12} xs={12}>
                  <div
                    className={
                      disbaledTagMenu
                        ? "editableTagMenu formFieldGroup disabledField"
                        : "editableTagMenu formFieldGroup"
                    }
                  >
                    <Grid container spacing={0} alignItems="flex-end">
                      <Grid item xs={11}>
                        <label>
                          {" "}
                          {t("What others want to see about you?") + " *"}
                        </label>
                        <textarea
                          className="formInputsDescriptions"
                          name="ShortBio"
                          rows="4"
                          cols="50"
                          value={this.state.ShortBio}
                          disabled={disbaledTagMenu}
                          onChange={this.handleForm}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {/* <IconButton
                      aria-label="Edit"
                      size="small"
                      onClick={this.handleClickTagMenuEdit}
                    >
                      <CreateIcon fontSize="inherit" />
                    </IconButton> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  {!this.state.savebtnState && (
                    <div className="profileFormBtn">
                      <button onClick={this.saveProfile}>{t("Save")}</button>
                    </div>
                  )}
                  {this.state.savebtnState && (
                    <p style={{ textAlign: "center", color: "green" }}>
                      {t("Saved")}
                    </p>
                  )}
                </Grid>
              </Grid>
            </BlockUi>
          </div>
        </div>
      </div>
    );
  }

  onDataCheck(key) {
    this.setState({ validLinkedIn: key });
  }

  onDataCheckFacebook(key) {
    this.setState({ validFacebook: key });
  }

  onDataCheckTwitter(key) {
    this.setState({ validTwitter: key });
  }

  storeUserData(userData) {
    this.setState({
      age: userData.dob,
      level: userData.gradeId,
      SecondaryEmail: userData.gradeId,
    });
  }

  editProfile() {
    this.setState({ editProfile: true });
  }

  backProfile() {
    this.setState({ editProfile: false });
  }

  saveProfile() {
    if (this.state.firstName === "" || this.state.firstName === undefined) {
      toast.warn("Please enter valid first name");
    } else if (
      this.state.lastName === "" ||
      this.state.lastName === undefined
    ) {
      toast.warn("Please enter valid last name");
    } else if (this.state.dName === "" || this.state.dName === undefined) {
      toast.warn("Please enter valid display name");
    } else if (!this.state.age) {
      toast.warn("Please enter valid date of birth");
    } else if (
      this.state.location === "" ||
      this.state.location === undefined
    ) {
      toast.warn("Please enter a valid location");
    } else if (
      this.state.countryId === "" ||
      this.state.countryId === undefined
    ) {
      toast.warn("Please choose a valid country");
    } else if (
      this.state.profession === "" ||
      this.state.profession === undefined
    ) {
      toast.warn("Please choose a valid profession");
    } else if (
      this.state.selectedAreaofInterestId == "" ||
      this.state.selectedAreaofInterestId == undefined
    ) {
      toast.warn("Please choose a area of interset");
    } else if (
      this.state.validLinkedIn == "false" &&
      !this.state.linkedin == ""
    ) {
      toast.warn("Enter valid Linked Url !");
      // this.setState({linkedin:""})
    } else if (
      this.state.validTwitter == "false" &&
      !this.state.twitter == ""
    ) {
      toast.warn("Enter valid Twitter Url !");
      // this.setState({twitter:""})
    } else if (
      this.state.validFacebook == "false" &&
      !this.state.facebook == ""
    ) {
      toast.warn("Enter valid Facebook Url !");
      // this.setState({facebook:""})
    } else if (this.state.ShortBio == "" || this.state.ShortBio == undefined) {
      toast.warn("Please share something about you");
    } else {
      //   this.block(true);
      let updateProfile = {
        firstTimeSetup: "true",
        dob: this.state.age,
        gradeId: this.state.grade,
        secondaryEmail: this.state.semail,
        gender: this.state.gender,
        languageId: [],
        firstLanguage: this.state.flanguages,
        teachingSubjectId: [],
        subjectId: [],
        joinYear: this.state.joinyear,
        liveYear: this.state.liveyear,
        curriculumId: this.state.curriculumId,
        trackId: this.state.trackId,
        displayName: this.state.dName,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        schoolId: this.state.schoolId,
        biography: this.state.ShortBio, // new
        companyName: this.state.companyName, //new column
        industryId: this.state.industryId, // new column
        areaOfInterest: this.state.selectedAreaofInterestId, // new column
        professionId: this.state.profession, // new column
        linkedin: this.state.linkedin, // new column
        twitter: this.state.twitter, // new column
        facebook: this.state.facebook, // new column
        title: this.state.title, //new column
        location: this.state.location, //new column
        countryId: this.state.countryId,
      };

      ProfileService.updateUserProfile(updateProfile)
        .then((response) => {
          var result = response.data;
          // this.setState({ savebtnState: true });
          AuthService.registerAuth("", result.data);
          this.props.storeLoginDetails(
            result.data,
            this.props.authUser.loginType
          );
          LoadService.redirect(
            this.props.history,
            `/${this.props.workspaceType}/home`
          );
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error in updating Profile");
          this.block(false);
        });
    }
  }
  getSelectedLanguage(languages) {}
  async getLanguageNameAlone(languageList) {
    var arraySubjectNames = [];
    for (let i = 0; i < languageList.length; i++) {
      arraySubjectNames.push(languageList[i].languageName);
    }

    await this.setState({ subjectName: arraySubjectNames });
  }

  async getAreaofInterestId(selectedareaName) {
    var areaofinterestid = [];
    if (this.state.areaofinterestData && this.state.areaofinterestData.length) {
      for (let i = 0; i < this.state.areaofinterestData.length; i++) {
        for (let j = 0; j < selectedareaName.length; j++) {
          if (
            this.state.areaofinterestData[i].areaName === selectedareaName[j]
          ) {
            areaofinterestid.push(this.state.areaofinterestData[i].areaId);
          }
        }
      }
    }
    await this.setState({ selectedAreaofInterestId: areaofinterestid });

    //
  }

  async getSubjectNameAlone(subjectList) {
    var arraySubjectNames = [];
    for (let i = 0; i < subjectList.length; i++) {
      arraySubjectNames.push(subjectList[i].subjectName);
    }

    await this.setState({ learningTeachingSubjectName: arraySubjectNames });
  }

  async getteachingSubjectId(selectedSubjectName) {
    var subjectId = [];
    if (this.props.subjectsList.data.length > 0) {
      if (
        this.props.subjectsList &&
        this.props.subjectsList.data &&
        this.props.subjectsList.data.length
      ) {
        for (let i = 0; i < this.props.subjectsList.data.length; i++) {
          for (let j = 0; j < selectedSubjectName.length; j++) {
            if (
              this.props.subjectsList.data[i].subjectName ===
              selectedSubjectName[j]
            ) {
              subjectId.push(this.props.subjectsList.data[i].subjectId);
            }
          }
        }
      }
      await this.setState({ selectedTeachingSubjectId: subjectId });
    }

    //
  }

  async getlearningSubjectId(selectedSubjectName) {
    var subjectId = [];
    if (this.props.subjectsList.data.length > 0) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < selectedSubjectName.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectName ===
            selectedSubjectName[j]
          ) {
            subjectId.push(this.props.subjectsList.data[i].subjectId);
          }
        }
      }
      await this.setState({ selectedLearningSubjectId: subjectId });
    }
  }

  getallGrade() {
    console.log(AuthService.getGradeList());
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        this.getGradeDetails(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getTrackAndCurriculum() {
    //this.props.getAllCommonCurriculumTrackList(true, this.props.personalLanguage)
    var langs = {
      lang: this.props.personalLanguage,
    };
    ProfileService.getTrackAndCurriculumData(langs)
      .then((response) => {
        var result = response.data;
        //AuthService.storeCurriculumTrackList(result.data)
        this.getCuriculumDetails(result.data.curriculum);
        this.gettrackDetails(result.data.track);
      })
      .catch((error) => {
        // console.log(error)
      });
    //
  }
  getGradebasedCurriculum() {
    if (this.props.loginDetails.gradeId !== "") {
      this.getGradeByCurriculumIdUpdate(this.props.loginDetails.cId);
    }
  }

  getTrackAndCurriculumList() {
    var result = this.props.curriculumtrackList;
    console.log("Co", result);
    this.getCuriculumDetails(result);
  }
  getGradeByCurriculumId(value) {
    this.setState({ grade: "" });
    console.log("getGradeByCurriculumId" + value);
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    console.log(getGradeByCurriculumId);
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        console.log(response.data.data.gradeListById);

        this.getGradeDetails(response.data.data.gradeListById);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllCuricullam() {
    ProfileService.getAllCurriculum()
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  getGradeByCurriculumIdUpdate(value) {
    console.log("getGradeByCurriculumId" + value);
    let getGradeByCurriculumId = {
      curriculumId: value,
    };
    console.log(getGradeByCurriculumId);
    ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
      .then((response) => {
        console.log(response.data.data.gradeListById);

        this.getGradeDetails(response.data.data.gradeListById);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  gettrackDetails(track) {
    if (track) {
      var lList = [];
      for (var i = 0; i < track.length; i++) {
        var obj = {};
        obj["id"] = track[i].trackId;
        obj["name"] = track[i].trackName;
        lList.push(obj);
      }

      this.setState({ trackList: lList });
    } else {
      console.log("No track present");
    }
  }

  getCuriculumDetails(curriculumList) {
    console.log(curriculumList);
    if (curriculumList) {
      var lList = [];
      for (var i = 0; i < curriculumList.length; i++) {
        var obj = {};
        obj["id"] = curriculumList[i].curriculumId;
        obj["name"] = curriculumList[i].curriculumName;
        lList.push(obj);
      }

      this.setState({ curriculumList: lList });
    } else {
      console.log("No curriculum present");
    }
  }

  getGradeDetails(langList) {
    if (langList) {
      var lList = [];
      for (var i = 0; i < langList.length; i++) {
        var obj = {};
        obj["id"] = langList[i].gradeId;
        obj["name"] = langList[i].gradeName;
        lList.push(obj);
      }

      this.setState({ gradeList: lList });
    }
  }

  async getOldLanguageList(oldLanguage, result) {
    var subjectName = [];
    console.log("oldLanguage");
    console.log(result.data);
    console.log("this.state.subjectDetails");
    for (let i = 0; i < result.data.length; i++) {
      for (let j = 0; j < oldLanguage.length; j++) {
        if (result.data[i].languageId === oldLanguage[j].languageId) {
          subjectName.push(result.data[i].languageName);
        }
      }
    }
    console.log(subjectName);
    await this.setState({ subjectNames: subjectName });
    this.getSubjectId(subjectName);
  }

  async getOldLearningSubjectList(oldLearningSubjects) {
    var subjectName = [];
    console.log("oldLearningSubjects");
    console.log(oldLearningSubjects);
    console.log("this.state.subjectDetails");
    console.log(this.props.subjectsList);
    if (this.props.subjectsList.data.length > 0) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < oldLearningSubjects.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectId ===
            oldLearningSubjects[j].subjectId
          ) {
            subjectName.push(this.props.subjectsList.data[i].subjectName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ learningsubjectNames: subjectName });
      this.getlearningSubjectId(subjectName);
    }
  }

  async getOldTeachingSubjectList(oldTeachingSubjects) {
    var subjectName = [];
    console.log("oldTeachingSubjects");
    console.log(oldTeachingSubjects);
    console.log("this.state.subjectDetails");
    console.log(this.props.subjectsList);
    if (this.props.subjectsList.data.length > 0) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < oldTeachingSubjects.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectId ===
            oldTeachingSubjects[j].teachingSubjectId
          ) {
            subjectName.push(this.props.subjectsList.data[i].subjectName);
          }
        }
      }
      console.log(subjectName);
      await this.setState({ teachingsubjectNames: subjectName });
      this.getteachingSubjectId(subjectName);
    }
  }

  block(blocking) {
    this.setState({ blocking: blocking });
  }

  getAllSchool() {
    console.log("getAllSchool");
    FindFriendsFilter.getAllSchool()
      .then((response) => {
        var result = response.data;
        this.getSchoolDetails(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSchoolDetails(schoolList) {
    console.log("schoolList" + schoolList);
    if (schoolList) {
      var lList = [];
      for (var i = 0; i < schoolList.length; i++) {
        var obj = {};
        obj["id"] = schoolList[i].schoolId;
        obj["name"] = schoolList[i].schoolName;
        lList.push(obj);
      }

      this.setState({ schools: lList });
      console.log(lList);
    }
    console.log(this.state.schools);
  }

  getAllIndustry() {
    ProfileService.getAllIndustry()
      .then((response) => {
        var result = response.data.data;
        this.getIndustryDetails(result);
        console.log("Id", result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGrade() {
    var result = this.props.languageList;
    console.log("List grade", this.props.languageList);
    this.getGradeLists(result);
  }
  getGradeLists(langList) {
    if (langList) {
      var lList = [];
      for (var i = 0; i < langList.length; i++) {
        var obj = {};
        obj["id"] = langList[i].gradeId;
        obj["name"] = langList[i].gradeName;
        lList.push(obj);
      }

      this.setState({ gradeList: lList });
    }
  }

  getSchool() {
    var schoolList = this.props.schoolList;
    console.log("schoolList" + schoolList);
    if (schoolList) {
      var lList = [];
      for (var i = 0; i < schoolList.length; i++) {
        var obj = {};
        obj["id"] = schoolList[i].schoolId;
        obj["name"] = schoolList[i].schoolName;
        lList.push(obj);
      }

      this.setState({ schools: lList });
      console.log(lList);
    }
    console.log(this.state.schools);
  }
  getIndustry() {
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
      console.log(lList);
    }
  }
  getAreaofInterest(areaOfInterest) {
    // var areaofinterestList = this.props.areaofinterestList
    // if (areaofinterestList) {
    //     var lList = [];
    //     for (var i = 0; i < areaofinterestList.length; i++) {
    //         var obj = {};
    //         obj["id"] = areaofinterestList[i].areaId;
    //         obj["name"] = areaofinterestList[i].areaName;
    //         lList.push(obj);
    //     }

    //     this.setState({ areaofinterestsList: lList });
    //     console.log(lList);

    // }
    if (areaOfInterest != undefined) {
      this.getOldAreaOfInterest(areaOfInterest, this.props.areaofinterestList);
    }
    this.setState({ areaofinterestData: this.props.areaofinterestList });
    this.getAreaofInterestDetails(this.props.areaofinterestList);
  }
  async getOldAreaOfInterest(oldreaofinterest, result) {
    var areaName = [];
    console.log("oldLanguage");

    console.log("this.state.subjectDetails");
    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < oldreaofinterest.length; j++) {
        if (result[i].areaId === oldreaofinterest[j].areaOfInterest) {
          areaName.push(result[i].areaName);
        }
      }
    }
    console.log("this.state.subjectDetails", areaName);
    await this.setState({ subjectNames: areaName });
    await this.setState({ areaNames: areaName });
    console.log("this.state.subjectDetails", this.state.subjectNames);
    this.getAreaofInterestId(areaName);
  }
  async getIndustryDetails(industryList) {
    console.log("loginDetails", this.props.loginDetails);
    console.log(industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      await this.setState({ industrysList: lList });
      console.log(lList);
      console.log("industrysList");
      console.log(this.state.industrysList);
    }
  }

  getAllAreaofInterest(areaOfInterest) {
    let data = {
      data: "",
    };
    ProfileService.getAreaOfInterest(data)
      .then((response) => {
        var result = response.data.data.user;
        this.setState({ areaofinterestData: result });
        if (areaOfInterest != undefined) {
          this.getOldAreaOfInterest(areaOfInterest, result);
        }
        this.getAreaofInterestDetails(result);
      })
      .catch((error) => {
        // console.log(error)
      });
  }

  async getAreaofInterestDetails(areaofinterestList) {
    console.log("areaofinterestsList");
    console.log(areaofinterestList);
    // if (areaofinterestList) {
    //     var lList = [];
    //     for (var i = 0; i < areaofinterestList.length; i++) {
    //         var obj = {};
    //         obj["id"] = areaofinterestList[i].areaId;
    //         obj["name"] = areaofinterestList[i].areaName;
    //         lList.push(obj);
    //     }

    //     await this.setState({ areaofinterestsList: lList });
    //     console.log(lList);

    //     console.log(this.state.areaofinterestsList);
    // }

    var arrayAreaofinterest = [];
    for (let i = 0; i < areaofinterestList.length; i++) {
      arrayAreaofinterest.push(areaofinterestList[i].areaName);
    }

    await this.setState({ areaofInterestList: arrayAreaofinterest });
  }

  getallLanguage(uLanguage) {
    this.getOldLanguageList(uLanguage, this.props.languageList);
    this.getLanguageNameAlone(this.props.languageList.data);
    this.setState({ subjectDetails: this.props.languageList.data });
    if (this.props.languageList.data) {
      var lList = [];
      for (var i = 0; i < this.props.languageList.data.length; i++) {
        var obj = {};
        obj["id"] = this.props.languageList.data[i].languageId;
        obj["name"] = this.props.languageList.data[i].languageName;
        lList.push(obj);
      }

      this.setState({ langList: lList });
    }
  }
  getFriendsList() {
    var friend = this.props.FriendsList;
    this.setState({
      FriendsList: friend,
    });
  }
  getallSubject(uLearn, uTeach) {
    this.getOldLearningSubjectList(uLearn);
    this.getOldTeachingSubjectList(uTeach);
    this.getSubjectNameAlone(this.props.subjectsList.data);
  }
}

const mapStateToProps = function (state) {
  console.log("languageList");
  console.log(state.commonApi.industrylist);
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    personalLanguage: state.profileValue.lang,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist.curriculum,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    industryList: state.commonApi.industrylist.data,
    regionList: state.commonApi.regionlist,
    areaofinterestList: state.commonApi.areaofinterestlist,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(FirstSetupProfile)));

// import React, { Component } from "react";
// import { Grid } from "@material-ui/core";
// import EditableTextField from "../../../components/EditableTextField";
// import EditableDropdown from "../../../components/EditableDropdown";
// import EditableDropdownGrade from "../../../components/EditableDropdownGrade";
// import AuthService from "../../../session/AuthService";
// import ProfileService from "../../../api/ProfileService";
// import { toast } from "react-toastify";
// import i18n from "../../../i18n";
// import { withTranslation } from "react-i18next";
// import moment from "moment";
// import EditableDate from "../../../components/EditableDatePicker";
// import { withRouter } from "react-router";
// import BlockUi from "react-block-ui";
// import LoadService from "../../../utils/LoadService";
// import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";
// import * as microsoftTeams from "@microsoft/teams-js";
// import { connect } from "react-redux";
// import FindFriendsFilter from "../../../api/FindFriendsFilter";
// import "../../css/mylike.css";
// import { FiTwitter } from "react-icons/fi";
// import { FiFacebook } from "react-icons/fi";
// import { FaLinkedinIn } from "react-icons/fa";
// import TagMenuProfile from "../../../components/TagMenuProfile";

// import { VscCheck } from "react-icons/vsc";
// import { VscChromeClose } from "react-icons/vsc";
// import EditableTextFieldLinkedIn from "../../../components/EditableTextFieldLinkedIn";
// import EditableTextFieldFacebook from "../../../components/EditableTextFieldFacebook";
// import EditableTextFieldTwitter from "../../../components/EditableTextFieldTwitter";

// class FirstSetupProfile extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       schoolId: "",
//       schools: [],

//       disbaledTagMenu: false,
//       disbaledTagMenuLearn: false,
//       disbaledTagMenuTeach: false,
//       TagMenuValue: null,
//       disabledSaveBtn: true,
//       age: 0,
//       userProfile: {},
//       langList: [],
//       semail: "",
//       gender: "",
//       firstLanguage: "",
//       flanguages: "",
//       joinyear: "",
//       joinYear: "",
//       liveyear: "",
//       liveYear: "",
//       schoolList: [],
//       fromCountry: [],
//       validLinkedIn: "",
//       validFacebook: "",
//       validTwitter: "",
//       areaNames: [],
//       genderList: [
//         { id: "male", name: this.props.t("Male") },
//         { id: "female", name: this.props.t("Female") },
//         { id: "other", name: this.props.t("Other") },
//       ],
//       subjectName: [],
//       areaofInterestList: [],
//       learningTeachingSubjectName: [],
//       teachingSubjectName: [],
//       editProfile: false,
//       gradeList: [],
//       grade: "",
//       curriculumList: [],
//       curriculamId: "",
//       trackList: [],
//       trackId: "",
//       savebtnState: false,
//       dName: "",
//       firstName: "",
//       lastName: "",
//       blocking: false,
//       professionID: false, //new
//       companyName: "", //new
//       areaOfInterest: [], //new
//       industryId: false, //new
//       industrysList: [],
//       areaofinterestsList: [],
//       ShortBio: "", //new
//       linkedin: "", //new
//       facebook: "", //new
//       twitter: "", //new
//       title: "", //new
//       profession: [
//         {
//           id: 1,
//           name: "Salaried",
//         },
//         {
//           id: 2,
//           name: "Self-employeed",
//         },
//       ],
//     };
//     this.block = this.block.bind(this);
//     this.saveProfile = this.saveProfile.bind(this);
//     this.editProfile = this.editProfile.bind(this);
//     this.backProfile = this.backProfile.bind(this);
//     this.handleClickTagMenuEdit = this.handleClickTagMenuEdit.bind(this);
//     this.getSelectedLanguage = this.getSelectedLanguage.bind(this);
//     this.storeUserData = this.storeUserData.bind(this);
//     this.getallGrade = this.getallGrade.bind(this);
//     this.getGradeDetails = this.getGradeDetails.bind(this);
//     this.getLanguageNameAlone = this.getLanguageNameAlone.bind(this);
//     this.getSubjectNameAlone = this.getSubjectNameAlone.bind(this);
//     this.getAreaofInterestId = this.getAreaofInterestId.bind(this);
//     this.getlearningSubjectId = this.getlearningSubjectId.bind(this);
//     this.getteachingSubjectId = this.getteachingSubjectId.bind(this);
//     this.getProfileValues = this.getProfileValues.bind(this);
//     this.getTrackAndCurriculum = this.getTrackAndCurriculum.bind(this);
//     this.getCuriculumDetails = this.getCuriculumDetails.bind(this);
//     this.gettrackDetails = this.gettrackDetails.bind(this);
//     this.getOldLanguageList = this.getOldLanguageList.bind(this);
//     this.getOldLearningSubjectList = this.getOldLearningSubjectList.bind(this);
//     this.getOldTeachingSubjectList = this.getOldTeachingSubjectList.bind(this);
//     this.handleClickTagMenuEditLearn =
//       this.handleClickTagMenuEditLearn.bind(this);
//     this.handleClickEdit = this.handleClickEdit.bind(this);
//     this.handleClickTagMenuEditTeach =
//       this.handleClickTagMenuEditTeach.bind(this);
//     this.getGradebasedCurriculum = this.getGradebasedCurriculum.bind(this);
//     this.getGradeByCurriculumId = this.getGradeByCurriculumId.bind(this);
//     this.handleForm = this.handleForm.bind(this);
//     this.getAllSchool = this.getAllSchool.bind(this);
//     // this.getAllSchoolglobal=this.getAllSchoolglobal.bind(this);
//     this.getIndustryDetails = this.getIndustryDetails.bind(this);
//     this.getAllIndustry = this.getAllIndustry.bind(this);
//     this.getIndustry = this.getIndustry.bind(this);

//     this.getAllAreaofInterest = this.getAllAreaofInterest.bind(this);
//     this.getSchoolDetails = this.getSchoolDetails.bind(this);
//     this.getAreaofInterestDetails = this.getAreaofInterestDetails.bind(this);
//     this.getGradeByCurriculumIdUpdate =
//       this.getGradeByCurriculumIdUpdate.bind(this);
//     this.getFriendsList = this.getFriendsList.bind(this);
//     this.getallLanguage = this.getallLanguage.bind(this);
//     this.getallSubject = this.getallSubject.bind(this);
//     this.getTrackAndCurriculumList = this.getTrackAndCurriculumList.bind(this);
//     this.getGrade = this.getGrade.bind(this);
//     this.getGradeLists = this.getGradeLists.bind(this);
//     this.getSchool = this.getSchool.bind(this);
//     this.getAreaofInterest = this.getAreaofInterest.bind(this);
//     this.getOldAreaOfInterest = this.getOldAreaOfInterest.bind(this);
//     this.onDataCheck = this.onDataCheck.bind(this);
//     this.onDataCheckFacebook = this.onDataCheckFacebook.bind(this);
//     this.onDataCheckTwitter = this.onDataCheckTwitter.bind(this);
//   }

//   handleClickTagMenuEdit() {
//     this.setState({
//       disbaledTagMenu: false,
//       savebtnState: false,
//     });
//   }

//   handleClickTagMenuEditLearn() {
//     this.setState({
//       disbaledTagMenuLearn: false,
//       savebtnState: false,
//     });
//   }

//   handleClickTagMenuEditTeach() {
//     this.setState({
//       disbaledTagMenuTeach: false,
//       savebtnState: false,
//     });
//   }

//   componentDidMount() {
//     let langValue = AuthService.getCommonLanguages();
//     i18n.changeLanguage(langValue);

//     let userData = this.props.loginDetails;

//     if (AuthService.isMSTeamsApp()) {
//       microsoftTeams.appInitialization.notifySuccess();
//     }
//     var selected_date = moment(userData.dob).format("DD/MM/YYYY");
//     let userProfile = userData;
//     console.log("userprofile");
//     console.log("**********userData", userData);

//     if (userData) {
//       let age = "";
//       if (userData.dob) {
//         age = selected_date;
//       }

//       let sEmail = "";
//       if (userData.secEmail) {
//         sEmail = userData.secEmail;
//       }

//       let gender = "";
//       if (userData.gender) {
//         gender = userData.gender;
//       }
//       let grade = "";
//       if (userData.gradeId) {
//         grade = userData.gradeId;
//       }
//       let firstLanguage = "";
//       if (userData.fLanguage) {
//         firstLanguage = userData.fLanguage;
//       }
//       let joinYear = "";
//       if (userData.jYear) {
//         joinYear = userData.jYear;
//       }
//       let liveYear = "";
//       if (userData.lYear) {
//         liveYear = userData.lYear;
//       }

//       let curriculumId = "";
//       if (userData.cId) {
//         curriculumId = userData.cId;
//       }
//       let trackId = "";
//       if (userData.tId) {
//         trackId = userData.tId;
//       }

//       let dName = "";
//       if (userData.dName) {
//         dName = userData.dName;
//       }

//       let firstName = "";
//       if (userData.fName) {
//         firstName = userData.fName;
//       }

//       let lastName = "";
//       if (userData.lName) {
//         lastName = userData.lName;
//       }

//       let schoolId = "";
//       if (userData.sclId) {
//         schoolId = userData.sclId;
//       }

//       console.log(userData);

//       let companyName = "";
//       if (userData.company_Id) {
//         companyName = userData.company_Id;
//       }

//       let areaOfInterest = "";
//       if (userData.area_of_interest) {
//         areaOfInterest = userData.area_of_interest;
//       }

//       let ShortBio = "";
//       if (userData.biography) {
//         ShortBio = userData.biography;
//       }

//       let industry = "";
//       if (userData.industry_Id) {
//         industry = userData.industry_Id;
//       }

//       let profession = "";

//       if (userData.profession_Id) {
//         profession = userData.profession_Id;
//       }
//       let twitter = "";
//       if (userData.twitter) {
//         twitter = userData.twitter;
//       }
//       let linkedin = "";
//       if (userData.linkedin) {
//         linkedin = userData.linkedin;
//       }
//       let facebook = "";
//       if (userData.facebook) {
//         facebook = userData.facebook;
//       }
//       let title = "";
//       if (userData.title) {
//         title = userData.title;
//       }
//       this.getProfileValuesV2(
//         companyName,
//         profession,
//         industry,
//         ShortBio,
//         areaOfInterest,
//         twitter,
//         linkedin,
//         facebook,
//         title
//       );

//       this.getProfileValues(
//         userProfile,
//         age,
//         sEmail,
//         gender,
//         grade,
//         firstLanguage,
//         joinYear,
//         liveYear,
//         curriculumId,
//         trackId,
//         dName,
//         firstName,
//         lastName,
//         schoolId
//       );
//     }

//     let schoolList = [];
//     var currentYear = new Date().getUTCFullYear();
//     for (var i = 1980; i <= currentYear; i++) {
//       var obj = {};
//       obj["id"] = i;
//       obj["name"] = i;
//       schoolList.push(obj);
//     }
//     this.setState({ schoolList: schoolList });
//     this.getallGrade();
//     // if (this.props.firstTime === true) {
//     this.getTrackAndCurriculum();
//     this.getAllSchool();
//     this.getAllAreaofInterest(userData.profileAreaOfInterest);
//     this.getAllIndustry();
//     console.log(this.state.grade !== "");
//     // } else {
//     //   this.getTrackAndCurriculumList();
//     //   this.getSchool();
//     //   this.getAreaofInterest(userData.profileAreaOfInterest);
//     //   this.getIndustry();
//     // }
//   }

//   initfunction() {
//     let userData = this.props.loginDetails;
//     //this.getallLanguage(userData.uLanguage);
//     //this.getallSubject(userData.uLearn, userData.uTeach);
//   }

//   handleForm(event) {
//     this.setState({
//       [event.target.name]: event.target.value,
//     });
//   }

//   async getProfileValuesV2(
//     companyName,
//     profession,
//     industry,
//     ShortBio,
//     areaOfInterest,
//     twitter,
//     linkedin,
//     facebook,
//     title
//   ) {
//     await this.setState({
//       companyName,
//       professionID: profession,
//       industryId: industry,
//       ShortBio: ShortBio,
//       areaOfInterest: areaOfInterest,
//       twitter: twitter,
//       linkedin: linkedin,
//       facebook: facebook,
//       title: title,
//     });
//   }

//   async getProfileValues(
//     userProfile,
//     age,
//     email,
//     gender,
//     grade,
//     firstLanguage,
//     joinYear,
//     liveYear,
//     curriculumId,
//     trackId,
//     dName,
//     firstName,
//     lastName,
//     schoolId
//   ) {
//     await this.setState({
//       userProfile: userProfile,
//       age: age,
//       semail: email,
//       gender: gender,
//       grade: grade,
//       firstLanguage: firstLanguage,
//       flanguages: firstLanguage,
//       joinYear: joinYear,
//       joinyear: joinYear,
//       liveYear: liveYear,
//       liveyear: liveYear,
//       curriculumId: curriculumId,
//       trackId: trackId,
//       dName: dName,
//       firstName: firstName,
//       lastName: lastName,
//       schoolId: schoolId,
//     });
//   }

//   handleClickEdit() {
//     this.setState({ savebtnState: false });
//   }

//   render() {
//     const { t } = this.props;
//     const { disbaledTagMenu } = this.state;

//     return (
//       <div
//         className="screenInnerGap"
//         style={{
//           marginLeft: "22px",
//           marginRight: "22px",
//           marginBottom: "22px",
//           marginTop: "80px",
//         }}
//       >
//         <div className="askAQuestionCardWrapper">
//           <div className="proileFormWrapper">
//             <BlockUi
//               tag=""
//               blocking={this.state.blocking}
//               message={t("Loading, please wait")}
//               color="#60c2e7"
//             >
//               <Grid container spacing={2}>
//                 <Grid item md={12} sm={12} xs={12}>
//                   <p className="profileTitle">
//                     Hi {this.props.loginDetails.dName}
//                   </p>
//                 </Grid>
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableTextField
//                     label={t("First Name")}
//                     value={this.state.firstName}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         firstName: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableTextField
//                     label={t("Last Name")}
//                     value={this.state.lastName}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         lastName: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableTextField
//                     label={t("Display Name")}
//                     value={this.state.dName}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         dName: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>
//                 {/* <Grid item md={6} sm={12} xs={12}>
//                             <EditableDropdown
//                                 label={t("Gender")}
//                                 value={this.state.gender}
//                                 options={this.state.genderList}
//                                 handleClickEdits={this.handleClickEdit}
//                                 getSelectedValue={(value) => {
//                                     this.setState({
//                                         gender: value,
//                                         disabledSaveBtn: false,
//                                     });
//                                 }}
//                             />
//                         </Grid> */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableDate
//                     label={t("Date of birth")}
//                     value={this.state.age}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         age: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>

//                 {/* //state */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableDropdownGrade
//                     label={t("Location")}
//                     value={this.state.grade}
//                     options={this.state.gradeList}
//                     getProgramId={this.state.curriculumId}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         grade: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>

//                 {/* region/ */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableDropdown
//                     label={t("Country")}
//                     value={this.state.curriculumId}
//                     options={this.state.curriculumList}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       console.log(value);
//                       this.setState({
//                         curriculumId: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>
//                 {/* //new field */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   {/* <EditableDropdown
//                                 label={t("profession")}
//                                 value={this.state.professionID}
//                                 options={this.state.profession}
//                                 handleClickEdits={this.handleClickEdit}
//                                 getSelectedValue={(value) => {
//                                     console.log(value);
//                                     this.setState({
//                                         professionID: value,
//                                         disabledSaveBtn: false,
//                                     });
//                                 }}
//                             /> */}
//                   <EditableDropdown
//                     label={t("Industry")}
//                     value={this.state.industryId}
//                     options={this.state.industrysList}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       console.log(value);
//                       this.setState({
//                         industryId: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>
//                 {/*
//                         <Grid item md={12} sm={12} xs={12}>

//                         </Grid> */}

//                 {/* //new field */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableTextField
//                     label={t("Company Name")}
//                     value={this.state.companyName}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         companyName: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>

//                 {/* { //new api } */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableDropdown
//                     label={t("Job Functions")}
//                     value={this.state.schoolId}
//                     options={this.state.schools}
//                     handleClickEdits={this.handleClickEdit}
//                     getSelectedValue={(value) => {
//                       console.log(value);
//                       this.setState({
//                         schoolId: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>
//                 {/* new field */}

//                 <Grid item md={6} sm={12} xs={12}>
//                   <label>{t("Area of Interest")}</label>
//                   <TagMenuProfile
//                     allMenus={this.state.areaofInterestList}
//                     addLabel={"Add"}
//                     selectedOldName={this.state.subjectNames}
//                     isTagMenuFilter={true}
//                     value={"English"}
//                     getSelectedMenus={(value) => {
//                       this.setState({
//                         areaOfInterest: value,
//                         savebtnState: false,
//                       });
//                       this.getAreaofInterestId(value);
//                     }}
//                   />
//                 </Grid>

//                 {/* // new api */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <EditableTextField
//                     label={t("Title (Optional)")}
//                     value={this.state.title}
//                     handleClickEdits={this.handleClickEdit}
//                     onChange={this.handleForm}
//                     getSelectedValue={(value) => {
//                       this.setState({
//                         title: value,
//                         disabledSaveBtn: false,
//                       });
//                     }}
//                   />
//                 </Grid>

//                 <Grid item md={6} sm={12} xs={12}>
//                   <Grid container spacing={0}>
//                     <Grid item xs={10}>
//                       <EditableTextFieldLinkedIn
//                         label={t("LinkedIn")}
//                         value={this.state.linkedin}
//                         onChange={this.handleForm}
//                         onDataCheck={this.onDataCheck}
//                         handleClickEdits={this.handleClickEdit}
//                         getSelectedValue={(value) => {
//                           this.setState({
//                             linkedin: value,
//                             disabledSaveBtn: false,
//                           });
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={1} alignItems="flex-start">
//                       {this.state.validLinkedIn == "true" && (
//                         <VscCheck
//                           style={{
//                             width: "24px",
//                             marginTop: "24px",
//                             height: "52px",
//                             color: "#32CD32",
//                           }}
//                         />
//                       )}

//                       {this.state.validLinkedIn == "false" && (
//                         <VscChromeClose
//                           style={{
//                             width: "24px",
//                             marginTop: "24px",
//                             height: "52px",
//                             color: "#FF0000",
//                           }}
//                         />
//                       )}
//                       {this.state.validLinkedIn == "" && <></>}
//                     </Grid>
//                     <Grid item xs={1}>
//                       <FaLinkedinIn
//                         style={{
//                           width: "20px",
//                           marginTop: "20px",
//                           height: "58px",
//                           color: "#F97516",
//                           cursor: "pointer",
//                         }}
//                         onClick={() =>
//                           window.open(this.state.linkedin, "_blank")
//                         }
//                       />
//                     </Grid>
//                   </Grid>
//                 </Grid>

//                 <Grid item md={6} sm={12} xs={12}>
//                   <Grid container spacing={0}>
//                     <Grid item xs={10}>
//                       <EditableTextFieldTwitter
//                         label={t("Twitter")}
//                         value={this.state.twitter}
//                         onChange={this.handleForm}
//                         onDataCheck={this.onDataCheckTwitter}
//                         handleClickEdits={this.handleClickEdit}
//                         getSelectedValue={(value) => {
//                           this.setState({
//                             twitter: value,
//                             disabledSaveBtn: false,
//                           });
//                         }}
//                       />
//                     </Grid>

//                     <Grid item xs={1} alignItems="flex-start">
//                       {this.state.validTwitter == "true" && (
//                         <VscCheck
//                           style={{
//                             width: "24px",
//                             marginTop: "24px",
//                             height: "52px",
//                             color: "#32CD32",
//                           }}
//                         />
//                       )}

//                       {this.state.validTwitter == "false" && (
//                         <VscChromeClose
//                           style={{
//                             width: "24px",
//                             marginTop: "24px",
//                             height: "52px",
//                             color: "#FF0000",
//                           }}
//                         />
//                       )}
//                       {this.state.validTwitter == "" && <></>}
//                     </Grid>
//                     <Grid item xs={1}>
//                       <FiTwitter
//                         style={{
//                           width: "23px",
//                           marginTop: "23px",
//                           height: "53px",
//                           color: "#F97516",
//                           cursor: "pointer",
//                         }}
//                         onClick={() =>
//                           window.open(this.state.twitter, "_blank")
//                         }
//                       />
//                     </Grid>
//                   </Grid>
//                 </Grid>

//                 <Grid item md={6} sm={12} xs={12}>
//                   <Grid container spacing={0}>
//                     <Grid item xs={10}>
//                       <EditableTextFieldFacebook
//                         label={t("FaceBook")}
//                         value={this.state.facebook}
//                         onDataCheck={this.onDataCheckFacebook}
//                         handleClickEdits={this.handleClickEdit}
//                         onChange={this.handleForm}
//                         getSelectedValue={(value) => {
//                           this.setState({
//                             facebook: value,
//                             disabledSaveBtn: false,
//                           });
//                         }}
//                       />
//                     </Grid>
//                     <Grid item xs={1} alignItems="flex-start">
//                       {this.state.validFacebook == "true" && (
//                         <VscCheck
//                           style={{
//                             width: "24px",
//                             marginTop: "24px",
//                             height: "52px",
//                             color: "#32CD32",
//                           }}
//                         />
//                       )}

//                       {this.state.validFacebook == "false" && (
//                         <VscChromeClose
//                           style={{
//                             width: "24px",
//                             marginTop: "24px",
//                             height: "52px",
//                             color: "#FF0000",
//                           }}
//                         />
//                       )}
//                       {this.state.validFacebook == "" && <></>}
//                     </Grid>
//                     <Grid item xs={1}>
//                       <FiFacebook
//                         style={{
//                           width: "24px",
//                           marginTop: "24px",
//                           height: "52px",
//                           color: "#F97516",
//                           cursor: "pointer",
//                         }}
//                         onClick={() =>
//                           window.open(this.state.facebook, "_blank")
//                         }
//                       />
//                     </Grid>
//                   </Grid>
//                 </Grid>

//                 {/* region/ */}
//                 <Grid item md={6} sm={12} xs={12}>
//                   <div
//                     className={
//                       disbaledTagMenu
//                         ? "editableTagMenu formFieldGroup disabledField"
//                         : "editableTagMenu formFieldGroup"
//                     }
//                   >
//                     <Grid container spacing={1} alignItems="flex-end">
//                       <Grid item xs={11}>
//                         <label>
//                           {" "}
//                           {t("What others want to see about you?")}
//                         </label>
//                         <textarea
//                           className="formInputsDescriptions"
//                           name="ShortBio"
//                           rows="4"
//                           cols="50"
//                           value={this.state.ShortBio}
//                           disabled={disbaledTagMenu}
//                           onChange={this.handleForm}
//                         />
//                       </Grid>
//                       <Grid item xs={1}>
//                         {/* <IconButton
//                       aria-label="Edit"
//                       size="small"
//                       onClick={this.handleClickTagMenuEdit}
//                     >
//                       <CreateIcon fontSize="inherit" />
//                     </IconButton> */}
//                       </Grid>
//                     </Grid>
//                   </div>
//                 </Grid>

//                 <Grid item md={6} sm={12} xs={12}>
//                   {!this.state.savebtnState && (
//                     <div className="profileFormBtn">
//                       <button onClick={this.saveProfile}>{t("Save")}</button>
//                     </div>
//                   )}
//                   {this.state.savebtnState && (
//                     <p style={{ textAlign: "center", color: "green" }}>
//                       {t("Saved")}
//                     </p>
//                   )}
//                 </Grid>
//               </Grid>
//             </BlockUi>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   storeUserData(userData) {
//     this.setState({
//       age: userData.dob,
//       level: userData.gradeId,
//       SecondaryEmail: userData.gradeId,
//     });
//   }

//   editProfile() {
//     this.setState({ editProfile: true });
//   }

//   backProfile() {
//     this.setState({ editProfile: false });
//   }

//   saveProfile() {
//     var l = this.state.linkedin;
//     var f = this.state.facebook;
//     var t = this.state.twitter;
//     var linkedin = l.substring(0, 25);
//     var facebook = f.substring(0, 23);
//     var twitter = t.substring(0, 20);

//     console.log(this.state.curriculumId);
//     console.log(this.state.grade);
//     if (this.state.firstName === "" || this.state.firstName === undefined) {
//       toast.warn("Please enter first name");
//     } else if (
//       this.state.lastName === "" ||
//       this.state.lastName === undefined
//     ) {
//       toast.warn("Please enter last name");
//     } else if (this.state.dName === "" || this.state.dName === undefined) {
//       toast.warn("Please enter display name");
//     } else if (
//       this.state.curriculumId === "" ||
//       this.state.curriculumId === undefined
//     ) {
//       toast.warn("Please choose country");
//     } else if (this.state.grade === "" || this.state.grade === undefined) {
//       toast.warn("Please choose location");
//     }
//     //  else if (
//     //   this.state.schoolId === "" ||
//     //   this.state.schoolId === undefined
//     // ) {
//     //   toast.warn("Please choose job functions");
//     // }
//     else if (this.state.gender === "" || this.state.gender === undefined) {
//       toast.warn("Please choose gender");
//     }
//     // else if (
//     //   this.state.companyName === "" ||
//     //   this.state.companyName === undefined
//     // ) {
//     //   toast.warn("Please enter company name");
//     // }
//     // else if (
//     //   this.state.industryId === "" ||
//     //   this.state.industryId === undefined
//     // )
//     // {
//     //   toast.warn("Please choose a industry");
//     // }
//     else if (
//       this.state.selectedAreaofInterestId === "" ||
//       this.state.selectedAreaofInterestId === undefined
//     ) {
//       toast.warn("Please choose a area of interest");
//     }
//     // } else if (
//     //   this.state.professionID === "" ||
//     //   this.state.professionID === undefined
//     // ) {
//     //   toast.warn("Please choose a profession");
//     // }
//     // else if (
//     //   !/(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
//     //     this.state.linkedin
//     //   )
//     // ) {
//     //   toast.warn("Enter valid Linked Url !");
//     // }else if (
//     //   !/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(
//     //     this.state.twitter
//     //   )
//     // ) {
//     //   toast.warn("Enter valid Twitter Url !");
//     // } else if (
//     //   !/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(this.state.facebook)
//     // ) {
//     //   toast.warn("Enter valid Fackbook Url !");
//     // }
//     else {
//       let updateProfile = {
//         firstTimeSetup: "true",
//         dob: this.state.age,
//         gradeId: this.state.grade,
//         secondaryEmail: this.state.semail,
//         gender: this.state.gender,
//         languageId: [],
//         firstLanguage: this.state.flanguages,
//         teachingSubjectId: [],
//         subjectId: [],
//         joinYear: this.state.joinyear,
//         liveYear: this.state.liveyear,
//         curriculumId: this.state.curriculumId,
//         trackId: this.state.trackId,
//         displayName: this.state.dName,
//         firstName: this.state.firstName,
//         lastName: this.state.lastName,
//         schoolId: this.state.schoolId,
//         biography: this.state.ShortBio, // new
//         companyName: this.state.companyName, //new column
//         industryId: this.state.industryId, // new column
//         areaOfInterest: this.state.selectedAreaofInterestId, // new column
//         linkedin: this.state.linkedin, // new column
//         twitter: this.state.twitter, // new column
//         facebook: this.state.facebook, // new column
//         title: this.state.title, //new column
//       };

//       ProfileService.updateUserProfile(updateProfile)
//         .then((response) => {
//           var result = response.data;
//           // this.setState({ savebtnState: true });
//           AuthService.registerAuth("", result.data);
//           this.props.storeLoginDetails(
//             result.data,
//             this.props.authUser.loginType
//           );
//           this.props.history.push("/home");
//         })
//         .catch((error) => {
//           console.log(error);
//           toast.error("Error in updating Profile");
//           this.block(false);
//         });
//     }
//   }
//   getSelectedLanguage(languages) {}
//   async getLanguageNameAlone(languageList) {
//     var arraySubjectNames = [];
//     for (let i = 0; i < languageList.length; i++) {
//       arraySubjectNames.push(languageList[i].languageName);
//     }

//     await this.setState({ subjectName: arraySubjectNames });
//   }

//   async getAreaofInterestId(selectedareaName) {
//     var areaofinterestid = [];
//     if (this.state.areaofinterestData && this.state.areaofinterestData.length) {
//       for (let i = 0; i < this.state.areaofinterestData.length; i++) {
//         for (let j = 0; j < selectedareaName.length; j++) {
//           if (
//             this.state.areaofinterestData[i].areaName === selectedareaName[j]
//           ) {
//             areaofinterestid.push(this.state.areaofinterestData[i].areaId);
//           }
//         }
//       }
//     }
//     await this.setState({ selectedAreaofInterestId: areaofinterestid });

//     //
//   }

//   async getSubjectNameAlone(subjectList) {
//     var arraySubjectNames = [];
//     for (let i = 0; i < subjectList.length; i++) {
//       arraySubjectNames.push(subjectList[i].subjectName);
//     }

//     await this.setState({ learningTeachingSubjectName: arraySubjectNames });
//   }

//   async getteachingSubjectId(selectedSubjectName) {
//     var subjectId = [];
//     if (this.props.subjectsList.data.length > 0) {
//       if (
//         this.props.subjectsList &&
//         this.props.subjectsList.data &&
//         this.props.subjectsList.data.length
//       ) {
//         for (let i = 0; i < this.props.subjectsList.data.length; i++) {
//           for (let j = 0; j < selectedSubjectName.length; j++) {
//             if (
//               this.props.subjectsList.data[i].subjectName ===
//               selectedSubjectName[j]
//             ) {
//               subjectId.push(this.props.subjectsList.data[i].subjectId);
//             }
//           }
//         }
//       }
//       await this.setState({ selectedTeachingSubjectId: subjectId });
//     }

//     //
//   }

//   async getlearningSubjectId(selectedSubjectName) {
//     var subjectId = [];
//     if (this.props.subjectsList.data.length > 0) {
//       for (let i = 0; i < this.props.subjectsList.data.length; i++) {
//         for (let j = 0; j < selectedSubjectName.length; j++) {
//           if (
//             this.props.subjectsList.data[i].subjectName ===
//             selectedSubjectName[j]
//           ) {
//             subjectId.push(this.props.subjectsList.data[i].subjectId);
//           }
//         }
//       }
//       await this.setState({ selectedLearningSubjectId: subjectId });
//     }
//   }

//   getallGrade() {
//     console.log(AuthService.getGradeList());
//     ProfileService.getAllGrades()
//       .then((response) => {
//         var result = response.data;
//         this.getGradeDetails(result.data.grade);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   getTrackAndCurriculum() {
//     //this.props.getAllCommonCurriculumTrackList(true, this.props.personalLanguage)
//     var langs = {
//       lang: this.props.personalLanguage,
//     };
//     ProfileService.getTrackAndCurriculumData(langs)
//       .then((response) => {
//         var result = response.data;
//         //AuthService.storeCurriculumTrackList(result.data)
//         this.getCuriculumDetails(result.data.curriculum);
//         this.gettrackDetails(result.data.track);
//       })
//       .catch((error) => {
//         // console.log(error)
//       });
//     //
//   }
//   getGradebasedCurriculum() {
//     if (this.props.loginDetails.gradeId !== "") {
//       this.getGradeByCurriculumIdUpdate(this.props.loginDetails.cId);
//     }
//   }

//   getTrackAndCurriculumList() {
//     var result = this.props.curriculumtrackList;
//     console.log("Co", result);
//     this.getCuriculumDetails(result);
//   }
//   getGradeByCurriculumId(value) {
//     this.setState({ grade: "" });
//     console.log("getGradeByCurriculumId" + value);
//     let getGradeByCurriculumId = {
//       curriculumId: value,
//     };
//     console.log(getGradeByCurriculumId);
//     ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
//       .then((response) => {
//         console.log(response.data.data.gradeListById);

//         this.getGradeDetails(response.data.data.gradeListById);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   getAllCuricullam() {
//     ProfileService.getAllCurriculum()
//       .then((response) => {})
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   getGradeByCurriculumIdUpdate(value) {
//     console.log("getGradeByCurriculumId" + value);
//     let getGradeByCurriculumId = {
//       curriculumId: value,
//     };
//     console.log(getGradeByCurriculumId);
//     ProfileService.getGradeByCurriculumById(getGradeByCurriculumId)
//       .then((response) => {
//         console.log(response.data.data.gradeListById);

//         this.getGradeDetails(response.data.data.gradeListById);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   gettrackDetails(track) {
//     if (track) {
//       var lList = [];
//       for (var i = 0; i < track.length; i++) {
//         var obj = {};
//         obj["id"] = track[i].trackId;
//         obj["name"] = track[i].trackName;
//         lList.push(obj);
//       }

//       this.setState({ trackList: lList });
//     } else {
//       console.log("No track present");
//     }
//   }

//   getCuriculumDetails(curriculumList) {
//     console.log(curriculumList);
//     if (curriculumList) {
//       var lList = [];
//       for (var i = 0; i < curriculumList.length; i++) {
//         var obj = {};
//         obj["id"] = curriculumList[i].curriculumId;
//         obj["name"] = curriculumList[i].curriculumName;
//         lList.push(obj);
//       }

//       this.setState({ curriculumList: lList });
//     } else {
//       console.log("No curriculum present");
//     }
//   }

//   getGradeDetails(langList) {
//     if (langList) {
//       var lList = [];
//       for (var i = 0; i < langList.length; i++) {
//         var obj = {};
//         obj["id"] = langList[i].gradeId;
//         obj["name"] = langList[i].gradeName;
//         lList.push(obj);
//       }

//       this.setState({ gradeList: lList });
//     }
//   }

//   async getOldLanguageList(oldLanguage, result) {
//     var subjectName = [];
//     console.log("oldLanguage");
//     console.log(result.data);
//     console.log("this.state.subjectDetails");
//     for (let i = 0; i < result.data.length; i++) {
//       for (let j = 0; j < oldLanguage.length; j++) {
//         if (result.data[i].languageId === oldLanguage[j].languageId) {
//           subjectName.push(result.data[i].languageName);
//         }
//       }
//     }
//     console.log(subjectName);
//     await this.setState({ subjectNames: subjectName });
//     this.getSubjectId(subjectName);
//   }

//   async getOldLearningSubjectList(oldLearningSubjects) {
//     var subjectName = [];
//     console.log("oldLearningSubjects");
//     console.log(oldLearningSubjects);
//     console.log("this.state.subjectDetails");
//     console.log(this.props.subjectsList);
//     if (this.props.subjectsList.data.length > 0) {
//       for (let i = 0; i < this.props.subjectsList.data.length; i++) {
//         for (let j = 0; j < oldLearningSubjects.length; j++) {
//           if (
//             this.props.subjectsList.data[i].subjectId ===
//             oldLearningSubjects[j].subjectId
//           ) {
//             subjectName.push(this.props.subjectsList.data[i].subjectName);
//           }
//         }
//       }
//       console.log(subjectName);
//       await this.setState({ learningsubjectNames: subjectName });
//       this.getlearningSubjectId(subjectName);
//     }
//   }

//   async getOldTeachingSubjectList(oldTeachingSubjects) {
//     var subjectName = [];
//     console.log("oldTeachingSubjects");
//     console.log(oldTeachingSubjects);
//     console.log("this.state.subjectDetails");
//     console.log(this.props.subjectsList);
//     if (this.props.subjectsList.data.length > 0) {
//       for (let i = 0; i < this.props.subjectsList.data.length; i++) {
//         for (let j = 0; j < oldTeachingSubjects.length; j++) {
//           if (
//             this.props.subjectsList.data[i].subjectId ===
//             oldTeachingSubjects[j].teachingSubjectId
//           ) {
//             subjectName.push(this.props.subjectsList.data[i].subjectName);
//           }
//         }
//       }
//       console.log(subjectName);
//       await this.setState({ teachingsubjectNames: subjectName });
//       this.getteachingSubjectId(subjectName);
//     }
//   }

//   block(blocking) {
//     this.setState({ blocking: blocking });
//   }

//   getAllSchool() {
//     console.log("getAllSchool");
//     FindFriendsFilter.getAllSchool()
//       .then((response) => {
//         var result = response.data;
//         this.getSchoolDetails(result.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   getSchoolDetails(schoolList) {
//     console.log("schoolList" + schoolList);
//     if (schoolList) {
//       var lList = [];
//       for (var i = 0; i < schoolList.length; i++) {
//         var obj = {};
//         obj["id"] = schoolList[i].schoolId;
//         obj["name"] = schoolList[i].schoolName;
//         lList.push(obj);
//       }

//       this.setState({ schools: lList });
//       console.log(lList);
//     }
//     console.log(this.state.schools);
//   }

//   getAllIndustry() {
//     ProfileService.getAllIndustry()
//       .then((response) => {
//         var result = response.data.data;
//         this.getIndustryDetails(result);
//         console.log("Id", result);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }
//   getGrade() {
//     var result = this.props.languageList;
//     console.log("List grade", this.props.languageList);
//     this.getGradeLists(result);
//   }
//   getGradeLists(langList) {
//     if (langList) {
//       var lList = [];
//       for (var i = 0; i < langList.length; i++) {
//         var obj = {};
//         obj["id"] = langList[i].gradeId;
//         obj["name"] = langList[i].gradeName;
//         lList.push(obj);
//       }

//       this.setState({ gradeList: lList });
//     }
//   }

//   getSchool() {
//     var schoolList = this.props.schoolList;
//     console.log("schoolList" + schoolList);
//     if (schoolList) {
//       var lList = [];
//       for (var i = 0; i < schoolList.length; i++) {
//         var obj = {};
//         obj["id"] = schoolList[i].schoolId;
//         obj["name"] = schoolList[i].schoolName;
//         lList.push(obj);
//       }

//       this.setState({ schools: lList });
//       console.log(lList);
//     }
//     console.log(this.state.schools);
//   }
//   getIndustry() {
//     var industryList = this.props.industryList;
//     console.log("loginDetails", this.props.loginDetails);
//     console.log(industryList);
//     if (industryList) {
//       var lList = [];
//       for (var i = 0; i < industryList.length; i++) {
//         var obj = {};
//         obj["id"] = industryList[i].industryId;
//         obj["name"] = industryList[i].industryName;
//         lList.push(obj);
//       }

//       this.setState({ industrysList: lList });
//       console.log(lList);
//     }
//   }
//   getAreaofInterest(areaOfInterest) {
//     if (areaOfInterest != undefined) {
//       this.getOldAreaOfInterest(areaOfInterest, this.props.areaofinterestList);
//     }

//     this.setState({ areaofinterestData: this.props.areaofinterestList });
//     this.getAreaofInterestDetails(this.props.areaofinterestList);
//   }
//   async getOldAreaOfInterest(oldreaofinterest) {
//     var areaName = [];
//     console.log("oldLanguage");

//     console.log("this.state.subjectDetails");
//     for (let i = 0; i < this.state.areaofinterestData.length; i++) {
//       for (let j = 0; j < oldreaofinterest.length; j++) {
//         if (
//           this.state.areaofinterestData[i].areaId ===
//           oldreaofinterest[j].areaOfInterest
//         ) {
//           areaName.push(this.state.areaofinterestData[i].areaName);
//         }
//       }
//     }
//     console.log("this.state.subjectDetails", areaName);
//     await this.setState({ subjectNames: areaName });
//     console.log("this.state.subjectDetails", this.state.subjectNames);
//     this.getAreaofInterestId(areaName);
//   }

//   async getIndustryDetails(industryList) {
//     console.log("loginDetails", this.props.loginDetails);
//     console.log(industryList);
//     if (industryList) {
//       var lList = [];
//       for (var i = 0; i < industryList.length; i++) {
//         var obj = {};
//         obj["id"] = industryList[i].industryId;
//         obj["name"] = industryList[i].industryName;
//         lList.push(obj);
//       }

//       await this.setState({ industrysList: lList });
//       console.log(lList);
//       console.log("industrysList");
//       console.log(this.state.industrysList);
//     }
//   }

//   getAllAreaofInterest(areaOfInterest) {
//     let data = {
//       data: "",
//     };
//     ProfileService.getAreaOfInterest(data)
//       .then((response) => {
//         var result = response.data.data.user;
//         this.setState({ areaofinterestData: result });

//         if (areaOfInterest != undefined) {
//           this.getOldAreaOfInterest(areaOfInterest, result);
//         }
//         this.getAreaofInterestDetails(result);
//       })
//       .catch((error) => {
//         // console.log(error)
//       });
//   }

//   async getAreaofInterestDetails(areaofinterestList) {
//     var arrayAreaofinterest = [];
//     for (let i = 0; i < areaofinterestList.length; i++) {
//       arrayAreaofinterest.push(areaofinterestList[i].areaName);
//     }

//     await this.setState({ areaofInterestList: arrayAreaofinterest });
//   }

//   onDataCheck(key) {
//     this.setState({ validLinkedIn: key });
//   }

//   onDataCheckFacebook(key) {
//     this.setState({ validFacebook: key });
//   }

//   onDataCheckTwitter(key) {
//     this.setState({ validTwitter: key });
//   }

//   getallLanguage(uLanguage) {
//     this.getOldLanguageList(uLanguage, this.props.languageList);
//     this.getLanguageNameAlone(this.props.languageList.data);
//     this.setState({ subjectDetails: this.props.languageList.data });
//     if (this.props.languageList.data) {
//       var lList = [];
//       for (var i = 0; i < this.props.languageList.data.length; i++) {
//         var obj = {};
//         obj["id"] = this.props.languageList.data[i].languageId;
//         obj["name"] = this.props.languageList.data[i].languageName;
//         lList.push(obj);
//       }

//       this.setState({ langList: lList });
//     }
//   }
//   getFriendsList() {
//     var friend = this.props.FriendsList;
//     this.setState({
//       FriendsList: friend,
//     });
//   }
//   getallSubject(uLearn, uTeach) {
//     this.getOldLearningSubjectList(uLearn);
//     this.getOldTeachingSubjectList(uTeach);
//     this.getSubjectNameAlone(this.props.subjectsList.data);
//   }
// }
// const mapStateToProps = function (state) {
//   console.log("languageList");
//   console.log(state.commonApi.industrylist);
//   return {
//     subjectsList: state.commonApi.subjectslist,
//     languageList: state.commonApi.languagelist,
//     personalLanguage: state.profileValue.lang,
//     tagList: state.commonApi.taglist,
//     curriculumtrackList: state.commonApi.curriculumtracklist.curriculum,
//     schoolList: state.commonApi.schoollist,
//     FriendsList: state.commonApi.friendslist,
//     loginDetails: state.loginDetailsApi.loginDetails,
//     authUser: state.loginDetailsApi.authUser,
//     industryList: state.commonApi.industrylist.data,
//     regionList: state.commonApi.regionlist,
//     areaofinterestList: state.commonApi.areaofinterestlist,
//   };
// };

// const mapDispacthToProps = (dispatch) => {
//   return {
//     storeLoginDetails: (loginDetails, type) =>
//       dispatch(storeLoginDetails(loginDetails, type)),
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispacthToProps
// )(withTranslation()(withRouter(FirstSetupProfile)));
