import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDirectory } from "../../redux/actions/directoryActions";
import * as APPCONFIG from "../../config/app-config.js";
import BlockUi from "react-block-ui";
import {
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { MdEventAvailable, MdOutlineMailOutline } from "react-icons/md";
import { BiPhone } from "react-icons/bi";
import { setSelectedUserAction } from "../../redux/actions/chatActions";
import { giveUserAction } from "../../utils";
import { needPhotVerification } from "../../redux/actions/commonApiActions";
import dummyimage from "../../img/home/directory.jpg";
import { BsChatLeftDots } from "react-icons/bs";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Lightbox from "react-image-lightbox";
import { GoLocation } from "react-icons/go";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Menu, MenuItem } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import HomeService from "../../api/HomeService";
import Swal from "sweetalert2";
import defaultAvatar from "../../img/home/directory.jpg";

function DirectoryList(props) {
  const dispatch = useDispatch();
  const { t } = props;
  const workspaceType = useSelector(
    (state) => state.loginDetailsApi.loginDetails?.userWorkSpace?.slug
  );
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const loaderStatus = useSelector((state) => state.directory.loaderStatus);
  const singleDirectoryList = useSelector(
    (state) => state.directory.singleDirectoryList
  );
  const selectedImage = singleDirectoryList?.galleryImages?.length
    ? singleDirectoryList?.galleryImages[selectedImageIndex]
    : [];
  const galleryImage = singleDirectoryList?.galleryImages;
  const [apiCalled, setApiCalled] = useState(false);
  console.log("singleDirectoryList", singleDirectoryList);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [lightBox, setLightBox] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [menuState, setMenuState] = useState("");
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        onBack();
      }
    };
  }, [history]);

  const previewStatus = (e, image) => {
    setOpenBox(!openBox);
    setLightBox(image);
    e.preventDefault();
  };
  useEffect(() => {
    dispatch(getSingleDirectory(props.match.params.directoryId));
    setApiCalled(true);
  }, [location?.pathname]);
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };
  const moveToPreviousImage = (event) => {
    setSelectedImageIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex < 0 ? galleryImage.length - 1 : newIndex;
    });
    event.preventDefault();
  };

  const moveToNextImage = (event) => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % galleryImage.length);
    event.preventDefault();
  };
  const offerAmount = (total, discount) => {
    let final = parseInt(total ? total : 0) - parseInt(discount ? discount : 0);
    return final ? final : total;
  };
  const handleClick = (event, email) => {
    window.location.href = `mailto:${email}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClickPhone = (event, number) => {
    window.location.href = `tel:${number}`;
    event.preventDefault();
    event.stopPropagation();
  };
  const onBack = () => {
    props.history.push(`/${workspaceType}/directory`, {
      from: "back",
    });
  };
  const images = (list) => {
    console.log("image loaderStatus outside", loaderStatus);
    if (list && !loaderStatus) {
      console.log("image loaderStatus", loaderStatus);
      return (
        <>
          {list &&
            list.map((image, index) => (
              <img
                src={`${APPCONFIG.API_URL}auth/image/${image}`}
                alt={`Image ${index}`}
                className={
                  index === selectedImageIndex
                    ? "image-item-dir active"
                    : "image-item-dir"
                }
                onClick={() => setSelectedImageIndex(index)}
                key={index}
                onLoad={handleImageLoad}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
              />
            ))}
        </>
      );
    } else {
      return "";
    }
  };
  // const loadImage = useMemo(
  //   () => images(singleDirectoryList?.galleryImages),
  //   [singleDirectoryList?.galleryImages]
  // );
  const moveRelatedDir = (directoryId) => {
    props.history.push(`/${workspaceType}/directory-details/${directoryId}`);
  };

  const enquiryUser = (e, userId) => {
    e.preventDefault();
    e.stopPropagation();
    var userData = {
      userId: userId,
      userName: singleDirectoryList?.chatUserName
        ? singleDirectoryList.chatUserName
        : "User",
      userType: 1,
      profilePhoto: "",
    };
    console.log("userData", userData);
    if (giveUserAction(loginDetails?.profile_complete)) {
      dispatch(needPhotVerification(true));
    } else {
      dispatch(setSelectedUserAction(userData));
    }
  };

  const editDirectory = () => {
    setOpenMenu(!openMenu);
    props.history.push(
      `/${workspaceType}/business/user-directory/${props.match.params.directoryId}`
    );
  };
  const deleteDirectory = () => {
    setOpenMenu(!openMenu);
    let id = {
      directoryId: singleDirectoryList.directoryId,
    };

    Swal.fire({
      title: "Are you sure to delete this directory ?",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: false,
    }).then((result) => {
      if (result.value) {
        HomeService.deleteDirectory(id)
          .then((item) => {
            console.log("Delete directory", item);
          })
          .catch((error) => {
            console.log("Error", error);
          });
        Swal.fire({
          title: "Deleted successfully",
          icon: "success",
          type: "success",
          showConfirmButton: true,
        }).then((result) => {
          props.history.push(`/${workspaceType}/directory`);
        });
      }
    });
  };
  return (
    <BlockUi
      tag="div"
      message={t("Loading, please wait")}
      style={{ minHeight: "90px" }}
      className="wallLoadingPage"
      blocking={loaderStatus}
    >
      <>
        {!loaderStatus && apiCalled && (
          <>
            <div
              style={{
                marginRight: "12px",
                marginLeft: "0px",
                marginBottom: "12px",
              }}
              className="ansBackQuestion d-flex uploader"
            >
              <ArrowBackIcon onClick={() => onBack()} />
              <p onClick={() => onBack()}>{t("Back to Directory")}</p>
              <div className="ml-auto">
                {loginDetails.uId === singleDirectoryList?.userId && (
                  <MoreHorizIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      setMenuState(e.target);
                      setOpenMenu(!openMenu);
                    }}
                  />
                )}
                {openMenu && (
                  <Menu
                    id="simple-menu"
                    anchorEl={menuState}
                    keepMounted
                    open={menuState}
                    onClose={() => setOpenMenu(!openMenu)}
                  >
                    <div
                      className="menuCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => editDirectory()}
                    >
                      <Edit className="menuItem" />{" "}
                      <MenuItem>
                        <p style={{ color: "black" }}>{t("Edit")}</p>
                      </MenuItem>
                    </div>
                    <div
                      className="menuCard"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteDirectory()}
                    >
                      <Delete className="menuItem" />{" "}
                      <MenuItem>
                        <p style={{ color: "black" }}>{t("Delete")}</p>
                      </MenuItem>
                    </div>
                  </Menu>
                )}
              </div>
            </div>

            <div className="full-image-container position-relative">
              <img
                src={`${APPCONFIG.API_URL}auth/image/${singleDirectoryList?.serviceImage}`}
                alt="Selected"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}
                onClick={(e) =>
                  previewStatus(
                    e,
                    `${APPCONFIG.API_URL}auth/image/${singleDirectoryList?.serviceImage}`
                  )
                }
              />
              <div>
                <h4
                  style={{ fontSize: "32px", fontWeight: "700" }}
                  className="m-0"
                >
                  {singleDirectoryList?.serviceName}
                </h4>
                <h6 style={{ fontSize: "28px" }} className="m-0">
                  {singleDirectoryList?.serviceType}
                </h6>
                <div className="dir-card-icons">
                  {/* {singleDirectoryList?.phoneNumber && (
                    <div
                      className="div-card-headr-icons"
                      onClick={(e) =>
                        handleClickPhone(e, singleDirectoryList?.phoneNumber)
                      }
                    >
                      <BiPhone />
                      <p>{singleDirectoryList?.phoneNumber}</p>
                    </div>
                  )} */}
                  <div
                    className="div-card-headr-icons"
                    onClick={(e) => handleClick(e, singleDirectoryList?.email)}
                  >
                    <MdOutlineMailOutline />
                    <p>{singleDirectoryList?.email}</p>
                  </div>
                  {singleDirectoryList?.location && (
                    <div className="div-card-headr-icons">
                      <GoLocation />
                      <p>{singleDirectoryList?.location}</p>
                    </div>
                  )}
                </div>
              </div>
              <section
                className="position-absolute"
                style={{ right: "12px", bottom: "12px" }}
              >
                {singleDirectoryList?.userId &&
                  singleDirectoryList.userId !== loginDetails?.uId && (
                    <button
                      className="border-none"
                      onClick={(e) =>
                        enquiryUser(e, singleDirectoryList.userId)
                      }
                    >
                      <BsChatLeftDots className="mr-2" />
                      {t("Chat")}
                    </button>
                  )}
              </section>
            </div>
            {openBox && (
              <Lightbox
                mainSrc={lightBox}
                onCloseRequest={() => setOpenBox(false)}
              />
            )}

            <div className="row mt-3">
              <div className="col-md-8 col-12">
                {singleDirectoryList?.galleryImages?.length !== 0 && (
                  <div className="image-gallery">
                    {/* {selectedImage && imagesLoaded ? ( */}
                    <div className="selected-image-container">
                      <img
                        src={`${APPCONFIG.API_URL}auth/image/${selectedImage}`}
                        alt="Selected"
                        className="selected-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                        onClick={(e) =>
                          previewStatus(
                            e,
                            `${APPCONFIG.API_URL}auth/image/${selectedImage}`
                          )
                        }
                      />
                      <div
                        className="arrow left-arrow"
                        onClick={moveToPreviousImage}
                      >
                        <AiFillLeftCircle className="svg-icons-dir" />
                      </div>
                      <div
                        className="arrow right-arrow"
                        onClick={moveToNextImage}
                      >
                        <AiFillRightCircle className="svg-icons-dir" />
                      </div>
                    </div>
                    {/* ) : (
                      <div className="skeleton" />
                    )} */}

                    <div className="image-list">
                      {singleDirectoryList?.galleryImages &&
                        singleDirectoryList?.galleryImages.map(
                          (image, index) => (
                            <img
                              src={`${APPCONFIG.API_URL}auth/image/${image}`}
                              alt={`Image ${index}`}
                              className={
                                index === selectedImageIndex
                                  ? "image-item-dir active"
                                  : "image-item-dir"
                              }
                              onClick={() => setSelectedImageIndex(index)}
                              key={index}
                              onLoad={handleImageLoad}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyimage;
                              }}
                            />
                          )
                        )}
                    </div>
                  </div>
                )}
                {singleDirectoryList?.galleryImages &&
                  singleDirectoryList?.galleryImages?.length === 0 && (
                    <img src={dummyimage} alt="" />
                  )}
                {/* <div
                    className="dir-card d-flex   mt-4 flex-wrap"
                    style={{ gap: "1rem" }}
                  >
                    <h6>Description</h6>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: singleDirectoryList?.serviceDetails,
                      }}
                    />
                  </div> */}
              </div>
              <div
                className="col-md-4 col-12 d-flex flex-column "
                style={{ gap: "2rem" }}
              >
                {/* <div className="dir-card">
                    <h6>Special Deals</h6>
                    <p className="bg-color">
                      {singleDirectoryList?.serviceName}
                    </p>

                    {singleDirectoryList?.promotionPrice && (
                      <span>
                        Extra ₹ {singleDirectoryList?.promotionPrice} off
                      </span>
                    )}
                    {singleDirectoryList?.promotionPrice && (
                      <p style={{ textDecoration: "line-through" }}>
                        {" "}
                        {singleDirectoryList?.cost}
                      </p>
                    )}
                    <p className="text-dark">
                      ₹{" "}
                      {offerAmount(
                        singleDirectoryList?.cost,
                        singleDirectoryList?.promotionPrice
                      )}
                    </p>
                    {singleDirectoryList.userId &&
                      singleDirectoryList.userId !== loginDetails?.uId && (
                        <button
                          onClick={() =>
                            enquiryUser(singleDirectoryList.userId)
                          }
                        >
                          Enquiry
                        </button>
                      )}
                  </div> */}
                <div className="dir-card">
                  {singleDirectoryList?.serviceCategory && (
                    <>
                      <h5 className="font-weight-bold mt-1">Category</h5>
                      <p className="text-transform-capitalize">
                        {singleDirectoryList?.serviceCategory}
                      </p>
                    </>
                  )}

                  <h5 className="font-weight-bold mt-1">{t("Description")}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: singleDirectoryList?.serviceDetails,
                    }}
                  />
                  {/* <div className="mt-3">
                    {singleDirectoryList?.userId &&
                      singleDirectoryList.userId !== loginDetails?.uId && (
                        <button
                          onClick={() =>
                            enquiryUser(singleDirectoryList.userId)
                          }
                        >
                          <BsChatLeftDots className="mr-2" />
                          Enquiry
                        </button>
                      )}
                  </div> */}
                  {singleDirectoryList?.onlineAvailable === "true" && (
                    <h6 className="mt-3 bg-color">
                      <MdEventAvailable className="button-svg" /> Available in
                      online
                    </h6>
                  )}
                </div>
                {/* {singleDirectoryList?.slotTime && (
                    <div className="dir-card">
                      <h5>Opening Hours</h5>
                      <div className="d-flex justify-content-between  align-items-center flex-wrap">
                        <p>{singleDirectoryList?.slotTime}</p>
                        <p>Monday</p>
                <p> 8:00am - 10:00pm</p>
                      </div>{" "}
                    </div>
                  )} */}

                {singleDirectoryList?.relatedService?.length !== 0 && (
                  <div className="dir-sub-card ">
                    <h5>Related Your Service</h5>
                    {singleDirectoryList?.relatedService.map((item, index) => (
                      <div
                        key={index}
                        className="dir-similar"
                        onClick={() => moveRelatedDir(item.slug)}
                      >
                        <img
                          src={`${APPCONFIG.API_URL}auth/image/${item.serviceImage}`}
                          className="image-item-dir"
                          alt="service"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = dummyimage;
                          }}
                        />
                        <div style={{ flex: 1 }} className="p-2 mt-2">
                          <section className="d-flex gap-2">
                            <img
                              style={{ cursor: "pointer", width: "4rem" }}
                              // onClick={() =>
                              //   this.showFriendsProfile(this.state.postValue.userId)
                              // }
                              className="rounded-circle"
                              src={
                                APPCONFIG.API_URL +
                                "api/getUserPhoto/" +
                                item.userId +
                                "/" +
                                item.userName
                              }
                              alt="user"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = defaultAvatar;
                              }}
                            />{" "}
                            <ul
                              className="m-0 p-0"
                              style={{ listStyle: "none" }}
                            >
                              <li
                                style={{ fontSize: "14px" }}
                                className="font-weight-bold text-capitalize mt-1 single-line"
                              >
                                {item.userName}
                              </li>
                              <li
                                className="text-secondary"
                                style={{ fontSize: "12px" }}
                              >
                                {item.location}
                              </li>
                            </ul>
                          </section>
                          <p
                            className="font-weight-bold mt-3 text-secondary"
                            style={{ fontSize: "12px" }}
                          >
                            {item.serviceName}
                          </p>
                          <p
                            className="elpsis-line"
                            style={{ fontSize: "10px" }}
                            dangerouslySetInnerHTML={{
                              __html: item.serviceDetails,
                            }}
                          />
                          {item.serviceCategory && (
                            <p
                              className="mt-3 bg-color"
                              style={{ fontSize: "12px" }}
                            >
                              {t("Category")} : {item.serviceCategory}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* <div className="dir-card">
              <div className="dir-card-icons">
                <AiOutlineUser className="svg-icons text-secondary " />
                <p>{singleDirectoryList?.userName}</p>
              </div>
              <div
                className="dir-card-icons mt-2"
                onClick={(e) =>
                  handleClickPhone(e, singleDirectoryList?.phoneNumber)
                }
              >
                <BiPhone className="svg-icons text-secondary" />
                <p>{singleDirectoryList?.phoneNumber}</p>
              </div>
              <div
                className="dir-card-icons text-secondary mt-2"
                onClick={(e) => handleClick(e, singleDirectoryList?.email)}
              >
                <MdOutlineMailOutline className="svg-icons text-secondary" />
                <p>{singleDirectoryList?.email}</p>
              </div>
            </div> */}
              </div>
            </div>
          </>
        )}
      </>
    </BlockUi>
  );
}

export default withTranslation()(withRouter(DirectoryList));
