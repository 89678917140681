import React, { Component } from "react";
import Home from "./Home/Home";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import i18n from "../../i18n";
import { connect } from "react-redux";
import {
  referralUserAddToWorkSpace,
  workSpaceUserLogin,
} from "../../redux/actions/loginDetailsActions";
import {
  getAnnouncementList,
  workSpaceLoginStats,
} from "../../redux/actions/commonApiActions";
import { extractValuesFromUrl, extractValuesSingleFromUrl } from "../../utils";
import { getNotificationList } from "../../redux/actions/notificationActions";
class Square extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tablocation: 0,
      announcementId: this.props.location.announcementId,
      showAskQuestion: false,
      fundingImage: true,
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleshowAskQuestion = this.handleshowAskQuestion.bind(this);
    this.movetoannouncement = this.movetoannouncement.bind(this);
  }

  handleChangeTab(event, newIndex) {
    this.setState({
      tablocation: newIndex,
      announcementId: 0,
    });
  }

  componentDidMount() {
    let auth = this.props.authUser.type;
    const { type } = this.props.match.params;
    if (!auth) {
      this.props.history.push(`/login`);
    }
    let sectionData = sessionStorage.getItem("WORKSPACE");

    if (
      !this.props.loginDetails.profile_complete &&
      !this.props.workSpaceComplete
    ) {
      console.log("LOGIN VIA WORKSPACE");
      //Workspace setup
      var invited_email = sessionStorage.getItem("invited_email");
      var dnsp = sessionStorage.getItem("dnsp");

      // if (!this.props.loginDetails.biography || !this.props.loginDetails.dob) {
      //   console.log("LOGIN workspace");
      //   this.props.history.push(`/workspace`);
      // }
      //Check any default workspace present
      if (
        this.props.workspaceType &&
        this.props.loginDetails.userWorkSpaceList?.length > 1 &&
        this.props.loginDetails?.userWorkSpace?.workSpaceId !== 0
      ) {
        console.log("LOGIN VIA WORKSPACE default");
        this.props.workSpaceLoginStats(true);
      }
      // else if (this.props.loginDetails.userWorkSpaceList?.length > 1) {
      //   //Workspace user without photo setup
      //   console.log("LOGIN VIA WORKSPACE 1");
      //   this.props.history.push(`/workspace`);
      // }
      else if (dnsp && invited_email) {
        //References via email
        console.log("LOGIN VIA WORKSPACE 2");

        this.props.referralUserAddToWorkSpace(invited_email, dnsp);
        this.props.history.push(`/workspace`);
      } else {
        // this.props.history.push(`/photo-set-up`);

        console.log("user-login-workspace", this.props.workspaceType);
        this.props.history.push(`/${sectionData}/home`);
      }
    } else if (
      this.props.loginDetails?.profile_complete &&
      localStorage.getItem("ARTICLE_SLUG")
    ) {
      //Article link share
      console.log(
        "LOGIN VIA ARTICLE LINK",
        localStorage.getItem("ARTICLE_SLUG")
      );
      this.props.history.push(
        `/articles/${localStorage.getItem("ARTICLE_SLUG")}`
      );
      localStorage.removeItem("ARTICLE_SLUG");
    } else if (
      sessionStorage.getItem("SHARE_POST") &&
      this.props.loginDetails?.profile_complete
    ) {
      let url = sessionStorage.getItem("SHARE_POST");
      const { id, sub } = extractValuesFromUrl(url, "id", "type");

      if (id) {
        console.log("LOGIN VIA SHARE LINK");
        this.props.history.push(
          `/${this.props.workspaceType}/userPost/${id}/${sub}`
        );
      } else {
        this.props.history.push(`/${this.props.workspaceType}/home`);
        console.log("LOGIN VIA SHARE LINK NOT VALID DATA");
      }
      sessionStorage.setItem("SHARE_POST", "");
    } else if (
      sessionStorage.getItem("CHALLENGE_REDIRECT_PATH") &&
      this.props.loginDetails.profile_complete
    ) {
      console.log("LOGIN VIA CHALLENGE");
      //Challenge useLOGINr workspace check
      const url = sessionStorage.getItem("CHALLENGE_REDIRECT_PATH");
      const match = url.match(/\/(\d+)$/);

      if (match && match[1]) {
        this.props.history.push(`/challenge-description/${match[1]}`);
      }
      sessionStorage.setItem("CHALLENGE_REDIRECT_PATH", "");
    } else if (
      sessionStorage.getItem("CAREER_REDIRECT_PATH") &&
      this.props.loginDetails.profile_complete
    ) {
      //Challenge useLOGINr workspace check
      const url = sessionStorage.getItem("CAREER_REDIRECT_PATH");
      console.log("LOGIN VIA CAREER_REDIRECT_PATH", url);
      const parts = url.split("/");
      const id = parts[parts?.length - 2];
      const subjectName = parts[parts?.length - 1];
      console.log("LOGIN VIA CAREER_REDIRECT_PATH", id);
      if (id) {
        this.props.history.push(
          `/${this.props.workspaceType}/challengeDescription/${id}/${subjectName}`
        );
      }
      sessionStorage.setItem("CAREER_REDIRECT_PATH", "");
    } else if (
      sessionStorage.getItem("ADVERTISEMENT_PATH") &&
      this.props.loginDetails.profile_complete
    ) {
      //Challenge useLOGINr workspace check
      const url = sessionStorage.getItem("ADVERTISEMENT_PATH");
      console.log("LOGIN VIA ADVERTISEMENT_PATH", url);
      const parts = url.split("/");
      const id = parts[parts?.length - 3];
      const postType = parts[parts?.length - 2];
      console.log("LOGIN VIA ADVERTISEMENT_PATH", id);
      if (id) {
        this.props.history.push(
          `/${this.props.workspaceType}/advertisement/${id}/${postType}`
        );
      }
      sessionStorage.setItem("ADVERTISEMENT_PATH", "");
    } else if (sessionStorage.getItem("FRIEND_PATH")) {
      //Challenge useLOGINr workspace check
      const url = sessionStorage.getItem("FRIEND_PATH");
      console.log("LOGIN VIA FRIEND_PATH", url);
      const { id, sub } = extractValuesFromUrl(url, "id", "sub");
      console.log("FRIEND_PATH id", id);
      if (id) {
        this.props.history.push(`/${"default"}/friendDescription/${id}`);
      }
      sessionStorage.removeItem("FRIEND_PATH");
    } else if (
      sessionStorage.getItem("FAMOUS_COMMUNITY_PATH") &&
      this.props.loginDetails.profile_complete
    ) {
      const url = sessionStorage.getItem("FAMOUS_COMMUNITY_PATH");
      console.log("LOGIN VIA FAMOUS_COMMUNITY_PATH", url);
      const { id, sub } = extractValuesFromUrl(url, "id", "sub");
      // const parts = url.split("/");
      // const id = parts[parts?.length - 2];
      // const subjectName = parts[parts?.length - 1];
      console.log("LOGIN VIA FAMOUS_COMMUNITY_PATH Id", id);
      if (id) {
        this.props.history.push(
          `/${this.props.workspaceType}/groupDescription/${id}/${
            sub ? sub : "Yuukke"
          }`
        );
      }
      sessionStorage.removeItem("FAMOUS_COMMUNITY_PATH");
    } else if (localStorage.getItem("EVENT_REGISTER_PATH")) {
      const url = localStorage.getItem("EVENT_REGISTER_PATH");
      console.log("LOGIN VIA EVENT_REGISTER_PATH", url);
      const parts = url.split("/");
      const slug = parts[parts?.length - 1];
      console.log("LOGIN VIA EVENT_REGISTER_PATH Id", slug);
      if (slug) {
        this.props.history.push(`/eventRegistration/${slug}`);
      }
      localStorage.removeItem("EVENT_REGISTER_PATH");
    } else if (this.props.loginDetails.profile_complete) {
      console.log("LOGIN VIA NORMAL", this.props.wrongUrlStatus);
      if (this.props.wrongUrlStatus) {
        this.props.history.push(`/${this.props.unAuthUrl}/home`);
      } else {
        this.props.history.push(`/${this.props.workspaceType}/home`);
      }
    } else {
      console.log("LOGIN NOT MATCHING ANYTHING");
      // this.props.history.push(`/login`);
    }

    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    // alert("did "+this.props.tablocation)
    this.setState({
      tablocation: this.props.tablocation,
    });
    if (this.props.match.params.id) {
      this.setState({
        announcementId: this.props.match.params.id,
        tablocation: 0,
      });
    } else {
      this.setState({ announcementId: 0 });
    }
    if (this.props.peerState) {
      this.setState({ tablocation: 1 });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.announcementId) {
      // alert(this.props.location.announcementId);
      this.setState({
        tablocation: 0,
        announcementId: this.props.location.announcementId,
      });
      this.props.location.announcementId = 0;
    }
    if (this.props.tablocation != prevProps.tablocation) {
      // alert("didupdate "+prevProps.tablocation)
      this.setState({
        tablocation: prevProps.tablocation,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.tablocation != nextProps.tablocation) {
      // alert("didupdate "+prevProps.tablocation)
      this.setState({
        tablocation: nextProps.tablocation,
      });
    }
  }
  render() {
    const { t, tablocation } = this.props;
    // const { tablocation, showAskQuestion } = this.state;

    return (
      <>
        {/* <Container> */}
        {this.props.loginDetails &&
          this.props.defaultWorkSpaceId !== 0 &&
          (this.props.userRole === 1 || this.props.userRole === 2) && (
            <div className="workspace-button">
              <button className="mr-2" onClick={this.addAnnouncement}>
                + Announcement
              </button>

              <button onClick={this.editWorkSpace}>Edit WorkSpace</button>
            </div>
          )}
        {tablocation === 0 ? (
          <>
            <Home
              announcementId={
                this.state.announcementId ? this.state.announcementId : 0
              }
              squarehome={this.props.match.params.id}
            />
          </>
        ) : tablocation === 1 ? (
          <></>
        ) : (
          <>{/* <MyCommunity /> */}</>
        )}
        {/* </Container> */}
      </>
    );
  }

  handleshowAskQuestion() {
    this.setState({
      showAskQuestion: !this.state.showAskQuestion,
      tablocation: this.state.showAskQuestion ? 2 : null,
    });
    this.props.history.push(`/ask-a-question`);
  }

  movetoannouncement() {
    this.props.history.push("/add-announcement");
  }
  editWorkSpace = () => {
    this.props.history.push(`/${this.props.workspaceType}/update-form`);
  };
  addAnnouncement = () => {
    this.props.history.push(`/${this.props.workspaceType}/announcement-list`);
    // this.props.history.push(
    //   `/${this.props.workspaceType}/announcement-form/${"new"}/update-form`
    // );
  };
}

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    authUser: state.loginDetailsApi.authUser,
    workspaceType: state.loginDetailsApi.loginDetails?.userWorkSpace?.slug,
    workSpaceComplete: state.commonApi.workSpaceLogin,
    wrongUrlStatus: state.commonApi.wrongUrlStatus,
    unAuthUrl: state.commonApi.pathUrl,
    userRole: state.loginDetailsApi.loginDetails?.userWorkSpace?.role,
    defaultWorkSpaceId:
      state.loginDetailsApi.loginDetails?.userWorkSpace?.workSpaceId,
  };
};
const mapDispatchToProps = function (dispatch) {
  return {
    referralUserAddToWorkSpace: (name, dnsp) =>
      dispatch(referralUserAddToWorkSpace(name, dnsp)),
    workSpaceLoginStats: (status) => dispatch(workSpaceLoginStats(status)),
    workSpaceUserLogin: (id) => dispatch(workSpaceUserLogin(id)),
    getAnnouncementList: () => dispatch(getAnnouncementList()),
    getNotificationList: (notificationhit) =>
      dispatch(getNotificationList(notificationhit)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Square)));
