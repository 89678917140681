import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import yuukke from "../../img/default_avatar.jpg";
import { Chip, TextField } from "@material-ui/core";
import ProfileService from "../../api/ProfileService";
import PostService_old from "../../api/PostService_old";
import { Multiselect } from "react-widgets";
import { toast } from "react-toastify";
import Utils from "../../utils/Utils";
import Swal from "sweetalert2";
import { getId } from "../../utils";
import AuthService from "../../session/AuthService";
import { useDispatch } from "react-redux";
import { getLogoutData } from "../../redux/actions/logoutActions";
import StepperFileOption from "../Wizard/StepperFileOption";
import { API_URL } from "../../config/app-config";
import { MdOutlineAttachFile } from "react-icons/md";

const DirectoryRegistration = () => {
  const [state, setState] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    about: "",
    serviceImage: null,
  });
  const dispatch = useDispatch();

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  const handleClose = () => {
    console.log("State", state);
  };
  const updateDetails = (e) => {
    e.preventDefault();
    if (
      !state.userName ||
      !state.email ||
      !state.phoneNumber ||
      !state.businessName ||
      !state.about
    ) {
      toast.warning("Fill all the required fields ...");
    } else if (!Utils.emailValidation(state.email)) {
      toast.warning("Enter valid email address");
    } else {
      const formData = new FormData();
      formData.append("userName", state.userName);
      formData.append("email", state.email);
      formData.append("phoneNumber", state.phoneNumber);
      formData.append("serviceName", state.businessName);
      formData.append("serviceDetails", state.about);
      if (state.serviceImage) {
        state.serviceImage.forEach((item) => {
          formData.append("serviceImagev2", item);
        });
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      ProfileService.directoryRegister(formData, config)
        .then((response) => {
          console.log("response", response);
          if (response.data.data === "USER_EXIST") {
            Swal.fire({
              icon: "success",
              title:
                "You're already an existing user in Yuukke, your directory created successfully!",
              showConfirmButton: true,
            }).then((result) => {
              AuthService.removeAuth();
              dispatch(getLogoutData());
              AuthService.triggerLogout();
            });
          } else {
            Swal.fire({
              icon: "success",
              title:
                "Directory created successfully, you will get a mail shortly. Use that credential to login",
              showConfirmButton: true,
            }).then((result) => {
              AuthService.removeAuth();
              dispatch(getLogoutData());
              AuthService.triggerLogout();
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong , please try again later");
        });
    }
  };
  const serviceImageUpdate = (file) => {
    if (file) {
      updateState("serviceImage", file);
    }
  };
  const removeFiles = (name) => {
    updateState(name, null);
  };
  return (
    <Modal
      show="true"
      onHide={handleClose}
      style={{
        background:
          "linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(45, 113, 253, 1) 100%)",
      }}
    >
      <Modal.Header className="justify-content-center align-items-center bg-white flex-column">
        {/* <h6 style={{ fontWeight: "bold" }}>
          {" "}
          <img src={yuukke} class="animi-icon" alt="icon" />
          Business Directory Registration
        </h6> */}
        <img
          src="https://corporate.yuukke.com/bcav2/auth/image/be0ec7f0-e02b-4768-a1a0-7fddcf854370"
          alt="img"
          style={{ width: "24rem" }}
          className="h-auto"
        />
      </Modal.Header>
      <Card className="profileCard">
        <p
          style={{ fontSize: "12px" }}
          className="text-secondary mt-3 text-center"
        >
          Let us start with few details to get registered with Yuukke
        </p>
        <Card.Body className="profile-form">
          <div className="formFieldGroup p-3">
            <form onSubmit={updateDetails}>
              <label className="mt-2 form-label">User Name *</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="text"
                value={state.userName}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("userName", e.target.value);
                }}
                fullWidth={true}
                required
              />

              <label className="mt-2 form-label">Email *</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="email"
                value={state.email}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("email", e.target.value);
                }}
                fullWidth={true}
                required
              />

              <label className="mt-2 form-label">Phone Number *</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="number"
                value={state.phoneNumber}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("phoneNumber", e.target.value);
                }}
                fullWidth={true}
                required
              />
              <label className="mt-2 form-label">Business Name*</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="text"
                value={state.businessName}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("businessName", e.target.value);
                }}
                fullWidth={true}
                required
              />
              <label className="mt-2 form-label">About Your Business *</label>
              <textarea
                className="text-area"
                required
                value={state.about}
                type="text"
                onChange={(e) => {
                  updateState("about", e.target.value);
                }}
              />
              <label className="mt-2 form-label">Business logo</label>

              {!state.serviceImage && (
                <StepperFileOption
                  onUploadSuccess={serviceImageUpdate}
                  acceptedType=".jpg,.jpeg,.png,.svg"
                  count={1}
                  multiple={false}
                />
              )}
              {state.serviceImage &&
                state.serviceImage.length !== 0 &&
                state.serviceImage.map((image, index) => (
                  <ShowImageFile
                    files={image}
                    removeFiles={removeFiles}
                    keyFile="serviceImage"
                    index={index}
                    count={1}
                  />
                ))}
              <div className="pl-2 pr-2">
                <button
                  className="w-100 mt-4"
                  type="submit"
                  value="Submit"
                  // onClick={(e) => updateDetails(e)}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};

const ShowImageFile = (props) => {
  const file = props?.files;
  const name = file?.name ? "props?.files?.name" : "image";
  return (
    <div key={props.index}>
      <Chip
        icon={<MdOutlineAttachFile />}
        className="fileChip"
        label={name.substring(0, 12)}
        size="small"
        onDelete={() => props.removeFiles(props?.keyFile, props?.index)}
        variant="outlined"
      />

      {file && (
        <img
          style={{
            margin: "8px",
            width: "auto",
            height: "80px",
          }}
          src={
            file?.name
              ? URL.createObjectURL(file)
              : `${API_URL}auth/image/${file}`
          }
          alt="img"
        ></img>
      )}
    </div>
  );
};

export default DirectoryRegistration;
