import React, { Component } from "react";
import BgBanner from "./BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import UserInfo from "./UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import * as APPCONFIG from "../../../config/app-config.js";
import i18n from "../../../i18n";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import { AiOutlineEdit, AiOutlineSafety } from "react-icons/ai";
import Delete from "@material-ui/icons/Delete";
import BlockUi from "react-block-ui";
import { Card, CardContent } from "@material-ui/core";
import MyCommunity from "../HomeUpdated/MyCommunity";
import MarketPlaceCard from "../../../components/MarketPlaceCard.jsx";
import { CgProfile } from "react-icons/cg";
import { GoBriefcase } from "react-icons/go";
import { BsBookmarkStar, BsClipboard } from "react-icons/bs";
import { GrValidate } from "react-icons/gr";
const menus = [
  {
    title: "Profile",
    icon: <CgProfile />,
  },
  {
    title: "Value Match",
    icon: <AiOutlineSafety />,
  },
  {
    title: "Education",
    icon: <BsClipboard />,
  },
  {
    title: "Skills",
    icon: <BsBookmarkStar />,
  },
  {
    title: "Work Experience",
    icon: <GoBriefcase />,
  },
];
let newSkills = new Array(2);
class SkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      nameValue: false,
      experienceValue: false,
      editskillExperience: "",
      editskillName: "",
      edituserId: "",
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frSkillName: [],
      industrysList: [],
      skills: [
        {
          name: "student",
        },
        {
          name: "Sworking professional",
        },
        {
          name: "practicing profession (Auditoer,lawyer and etc)",
        },
        {
          name: "Entreprenur",
        },
      ],

      editSkills: false,
      editSkillsDetails: false,
      skillDetails: [],
      dynamicLangArray: [],
      activeMenu: 3,
    };

    this.handleInputSkill = this.handleInputSkill.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getProfilePhoto = this.getProfilePhoto.bind(this);
    this.getUserCoverPhoto = this.getUserCoverPhoto.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getUpdatedFriendsList = this.getUpdatedFriendsList.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.getCoverPhotos = this.getCoverPhotos.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.gotoprofession = this.gotoprofession.bind(this);
    this.gotoeducation = this.gotoeducation.bind(this);
    this.gotoprofile = this.gotoprofile.bind(this);
    this.gotoskills = this.gotoskills.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveSkills = this.saveSkills.bind(this);
    this.editSkills = this.editSkills.bind(this);
    this.backSkills = this.backSkills.bind(this);
    this.editSkillDetails = this.editSkillDetails.bind(this);
    this.editSkillsList = this.editSkillsList.bind(this);
    this.gotoBusinessProfile = this.gotoBusinessProfile.bind(this);
    this.gotoValueMatch = this.gotoValueMatch.bind(this);
    this.deleteSkillDetails = this.deleteSkillDetails.bind(this);
  }

  componentDidMount() {
    // this.getIndustry();
    if (
      this.props.loginDetails.profession_Id == 3 ||
      this.props.loginDetails.profession_Id == 4
    ) {
      this.setState({
        businessProfile: true,
      });
    } else {
      this.setState({
        businessProfile: false,
      });
    }
    this.getSkillsDetails();
    //this.getKnowledgePoints();

    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    console.log("friends****", this.props.FriendsList);
    this.setState({ userPhotoImage: profileImage });
    if (this.props.loginDetails.hasOwnProperty("cPhoto")) {
      this.setState({
        coverPhotoImage: banner,
      });
    } else {
      this.getCoverPhotos(this.props.loginDetails.cover_uuid);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      friendsList: AuthService.getFriendsList(),
    });
  }

  addTranslation = (e) => {
    if (!this.state.nameValue || !this.state.experienceValue) {
      toast.warn("Fill all the field");
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
        nameValue: false,
        experienceValue: false,
      });
    }
  };
  changeMenu = (index) => {
    if (index === 0) {
      this.props.history.push("/my-profile");
    } else if (index === 1) {
      this.props.history.push("/value-match");
    } else if (index === 2) {
      this.props.history.push("/my-education");
    } else if (index === 4) {
      this.props.history.push("/my-profession");
    }
  };
  initfunction() {
    this.getallHobbies();
    this.getallGrade();
    this.getFriendsList();
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }

  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frSkillName[index]) {
      var val = this.state.frSkillName;
      val.splice(index, 1);
      this.setState({
        frSkillName: val,
      });
    }
    this.setState({
      nameValue: true,
      experienceValue: true,
    });
    await this.setState({
      inputs: inpu,
    });

    if (inpu.length == 0) {
      this.setState({
        editSkills: false,
        editSkillsDetails: false,
        inputs: [1],
        nameValue: false,
        experienceValue: false,
      });
    }
  }

  async getCoverPhotos(coverPhoto) {
    console.log("coverPhotoImage");

    if (this.props.loginDetails.cover_uuid != "") {
      console.log("entering if");
      await this.setState({
        coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      });
      console.log(this.state.coverPhotoImage);
    } else {
      console.log("entering else");
      await this.setState({ coverPhotoImage: banner });
      console.log(this.state.coverPhotoImage);
    }
  }
  render() {
    console.log("blockedUserList", this.props.blockedUserList);
    const { t } = this.props;
    return (
      <Container>
        <ToastContainer position="top-right" autoClose={5000} />
        {/* <Card>
          <CardContent> */}
        <div className="profileMainWrapper">
          {!this.props.firstTime && (
            <h2 className="pageHeading">{t("Profile")}</h2>
          )}
          <div className="profileWrapper">
            {!this.props.firstTime && (
              <BgBanner
                coverPhoto={this.state.coverPhotoImage}
                profilePhoto={this.state.userPhotoImage}
                userName={"User name"}
                getUserPhoto={this.getProfilePhoto}
                getCoverPhoto={this.getUserCoverPhoto}
              />
            )}
            {!this.props.firstTime && (
              <Grid container spacing={2} className="userDetailsWrapper">
                <Grid item md={12}>
                  <UserInfo
                    name={this.props.loginDetails.dName}
                    designation={this.props.loginDetails.biography}
                    additionalInfo={this.props.loginDetails.hsTag}
                    pointsCount={this.state.userKnowledgepoint}
                    loginDetails={this.props.loginDetails}
                  />
                </Grid>
              </Grid>
            )}

            {!this.props.firstTime && (
              <div className="tabWrapper">
                <div className=" ">
                  <Container>
                    <div className="flexDefault  ">
                      <Tabs
                        TabIndicatorProps={{
                          style: { background: "#A00030" },
                        }}
                        value={this.state.tabIndex}
                        // indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="disabled tabs example"
                      >
                        <Tab label={t("PROFILE")} />
                        <Tab label={t("FRIENDS")} />
                        <Tab label={t("BLOCKED USER")} />
                        <Tab label={t("FEED")} />
                        <Tab label={t("BOOKMARKED POSTS")} />
                        {this.props.loginDetails?.isVerifySeller && (
                          <Tab label={t("PRODUCTS")} />
                        )}
                      </Tabs>
                    </div>
                  </Container>
                </div>
                <div className="profilepageWrapperProfileSetup">
                  {this.state.tabIndex === 5 ? (
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                        <MarketPlaceCard />
                      </Grid>
                    </Grid>
                  ) : this.state.tabIndex === 0 ? (
                    <div>
                      <Grid container spacing={0}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <div className="">
                            <Grid container spacing={1}>
                              <Grid item md={3} xs={12} sm={12} lg={3}>
                                <ul className="profile-card-v1">
                                  {menus.map((item, index) => (
                                    <li
                                      className={
                                        index === this.state.activeMenu
                                          ? "bg-color"
                                          : ""
                                      }
                                      key={index}
                                      onClick={() => this.changeMenu(index)}
                                    >
                                      {item.icon}
                                      <h4>{t(`${item.title}`)}</h4>
                                    </li>
                                  ))}
                                </ul>
                              </Grid>

                              <Grid item md={9} xs={12} sm={12} lg={9}>
                                {!this.state.editSkills &&
                                  !this.state.editSkillsDetails && (
                                    <>
                                      <div className="profileselectCardMobile">
                                        <Grid container spacing={2}>
                                          <Grid item md={6} sm={12} xs={12}>
                                            <h5
                                              style={{
                                                color: "#000000",
                                                // padding: "8px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {t("Skill Details")}
                                            </h5>
                                          </Grid>

                                          <Grid item md={6} sm={12} xs={12}>
                                            <div
                                              className=""
                                              style={{ float: "right" }}
                                            >
                                              <button onClick={this.editSkills}>
                                                + {t("Add Skills")}
                                              </button>
                                            </div>
                                          </Grid>

                                          <Grid
                                            item
                                            md={12}
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                          >
                                            {this.state.skillDetails.length >
                                              0 &&
                                              this.state.skillDetails.map(
                                                (skill, index) => (
                                                  <div className="educationContainerEdit">
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={5}
                                                        xs={6}
                                                        sm={5}
                                                        lg={5}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Skill Name")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {skill.skillName}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      {/* <Grid item md={12} xs={12} sm={12} lg={12}>
                                                                                                            <Grid container spacing={0}> */}
                                                      <Grid
                                                        item
                                                        md={5}
                                                        xs={6}
                                                        sm={5}
                                                        lg={5}
                                                      >
                                                        <div className="">
                                                          <p
                                                            style={{
                                                              color: "#000000",
                                                              padding: "8px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {t("Experience")}
                                                          </p>
                                                          <p
                                                            style={{
                                                              color: "gray",
                                                              paddingLeft:
                                                                "8px",
                                                              fontSize: "12px",
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {skill.experience}
                                                          </p>
                                                        </div>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={1}
                                                        xs={2}
                                                        sm={1}
                                                        lg={1}
                                                      >
                                                        <AiOutlineEdit
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            color: "#A00030",
                                                          }}
                                                          onClick={() =>
                                                            this.editSkillDetails(
                                                              skill.id
                                                            )
                                                          }
                                                        ></AiOutlineEdit>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md={1}
                                                        xs={2}
                                                        sm={1}
                                                        lg={1}
                                                      >
                                                        <Delete
                                                          onClick={() =>
                                                            this.deleteSkillDetails(
                                                              skill.id
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            color: "#A00030",
                                                          }}
                                                        />{" "}
                                                      </Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            {this.state.skillDetails.length ==
                                              0 && (
                                              <p>{t("No skills added yet")}</p>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </>
                                  )}
                                {this.state.editSkills && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.backSkills}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      <BlockUi
                                        tag="div"
                                        blocking={this.state.blocking}
                                        message={t("Loading, please wait")}
                                        color="#A00030"
                                      >
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                          >
                                            <h6>{t("Skill Details")}</h6>

                                            {this.state.inputs.length > 0 &&
                                              this.state.inputs.map(
                                                (entry, index) => (
                                                  <div>
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      >
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                            fontSize: "35px",
                                                            float: "right",
                                                            marginTop: "12px",
                                                          }}
                                                          class=""
                                                          onClick={() =>
                                                            this.removeTranslation(
                                                              entry,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          -{" "}
                                                        </button>
                                                      </Grid>{" "}
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      >
                                                        <h6>
                                                          {t("Expertise Name")}
                                                        </h6>
                                                        <TextField
                                                          style={{
                                                            borderRadius:
                                                              "10px",
                                                            marginBottom: "8px",
                                                          }}
                                                          fullWidth
                                                          variant="outlined"
                                                          type="text"
                                                          required=""
                                                          name="frSkillName"
                                                          onChange={(e) =>
                                                            this.handleInputSkill(
                                                              e,
                                                              index,
                                                              "skillName"
                                                            )
                                                          }
                                                          value={
                                                            this.state
                                                              .frSkillName &&
                                                            this.state
                                                              .frSkillName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frSkillName[
                                                              index
                                                            ].skillName
                                                          }
                                                        />
                                                      </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                      <h6>
                                                        {t(
                                                          "Experience Details"
                                                        )}
                                                      </h6>
                                                      <Grid
                                                        item
                                                        md={12}
                                                        xs={12}
                                                        sm={12}
                                                        lg={12}
                                                      >
                                                        <TextField
                                                          id="dropdown"
                                                          select
                                                          label=""
                                                          value={
                                                            this.state
                                                              .frSkillName &&
                                                            this.state
                                                              .frSkillName[
                                                              index
                                                            ] &&
                                                            this.state
                                                              .frSkillName[
                                                              index
                                                            ].experience
                                                          }
                                                          onChange={(e) =>
                                                            this.handleInputSkill(
                                                              e,
                                                              index,
                                                              "experience"
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth={true}
                                                          options={
                                                            this.state.skills
                                                          }
                                                        >
                                                          <MenuItem value="">
                                                            <>{t("Select")}</>
                                                          </MenuItem>
                                                          <MenuItem value="Beginner">
                                                            <>{t("Beginner")}</>
                                                          </MenuItem>
                                                          <MenuItem value="Intermediate">
                                                            <>
                                                              {t(
                                                                "Intermediate"
                                                              )}
                                                            </>
                                                          </MenuItem>
                                                          <MenuItem value="Experience">
                                                            <>{t("Expert")}</>
                                                          </MenuItem>
                                                        </TextField>
                                                      </Grid>
                                                    </Grid>
                                                  </div>
                                                )
                                              )}
                                            <Grid container spacing={0}>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                {
                                                  <div className="profileFormBtn">
                                                    <button
                                                      style={{
                                                        borderRadius: "10px",
                                                        padding: "8px",
                                                      }}
                                                      onClick={this.saveSkills}
                                                    >
                                                      {t("Save")}
                                                    </button>
                                                  </div>
                                                }
                                              </Grid>
                                              <Grid
                                                item
                                                md={6}
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                              >
                                                <button
                                                  style={{
                                                    cursor: "pointer",
                                                    marginTop: "20px",
                                                    float: "right",
                                                  }}
                                                  class=""
                                                  onClick={this.addTranslation}
                                                >
                                                  +
                                                </button>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </BlockUi>
                                    </div>
                                  </>
                                )}

                                {this.state.editSkillsDetails && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.backSkills}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          md={12}
                                          xs={12}
                                          sm={12}
                                          lg={12}
                                        >
                                          <h6 style={{ fontWeight: "bold" }}>
                                            {t("Update Skill Details")}
                                          </h6>

                                          {/* {this.state.inputs.length > 0 &&
                                                                                            this.state.inputs.map((entry, index) => ( */}
                                          <div>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                sm={12}
                                                lg={12}
                                              >
                                                <h6>{t("Skill Name")}</h6>
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editskillExperience"
                                                  value={
                                                    this.state
                                                      .editskillExperience
                                                  }
                                                  onChange={this.handleForm}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>
                                              <h6>{t("Experience Details")}</h6>
                                              <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                sm={12}
                                                lg={12}
                                              >
                                                <TextField
                                                  style={{
                                                    borderRadius: "10px",
                                                    paddingLeft: "2px",
                                                    marginBottom: "8px",
                                                  }}
                                                  fullWidth
                                                  id="dropdown"
                                                  select
                                                  label=""
                                                  variant="outlined"
                                                  type="text"
                                                  required=""
                                                  name="editskillName"
                                                  value={
                                                    this.state.editskillName
                                                  }
                                                  onChange={this.handleForm}
                                                >
                                                  <MenuItem value="">
                                                    <>{t("Select")}</>
                                                  </MenuItem>
                                                  <MenuItem value="Beginner">
                                                    <>{t("Beginner")}</>
                                                  </MenuItem>
                                                  <MenuItem value="Intermediate">
                                                    <>{t("Intermediate")}</>
                                                  </MenuItem>
                                                  <MenuItem value="Expert">
                                                    <>{t("Expert")}</>
                                                  </MenuItem>
                                                </TextField>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          {/* ))} */}
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              md={6}
                                              xs={6}
                                              sm={6}
                                              lg={6}
                                            >
                                              {
                                                <div className="profileFormBtn">
                                                  <button
                                                    style={{
                                                      borderRadius: "10px",
                                                      padding: "8px",
                                                    }}
                                                    onClick={
                                                      this.editSkillsList
                                                    }
                                                  >
                                                    {t("Update")}
                                                  </button>
                                                </div>
                                              }
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 1 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Friends")}
                          </p>
                        </Grid>
                        {this.props.FriendsList &&
                          this.props.FriendsList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                tabIndex={"Friends"}
                                friendId={data.userId}
                                fUserId={data.userProfile.userId}
                                image={data.profilePhoto}
                                name={`${data.userProfile.firstName} ${data.userProfile.lastName}`}
                                Dname={data.userProfile.displayName}
                                smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.FriendsList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No friends available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 2 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Blocked User")}
                          </p>
                        </Grid>
                        {this.props.blockedUserList &&
                          this.props.blockedUserList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                tabIndex={"BlockedUser"}
                                friendId={data.blockUserId}
                                fUserId={data.blockUserId}
                                image={data.profilePhoto}
                                name={data.userName}
                                Dname={data.userName}
                                // smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.blockedUserList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No More Blocked List Available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : (
                    (this.state.tabIndex === 3 ||
                      this.state.tabIndex === 4) && (
                      <div style={{ marginTop: "5px" }}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <MyCommunity
                              type={
                                this.state.tabIndex === 3 ? "Feed" : "BookMark"
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  }
  getIndustry() {
    // alert()
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log("industryList", industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
      console.log("industrysList:", lList);
    }
    this.getEducationDetails(lList);
  }
  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getWorkExperienceDetails(saveEducation).then((response) => {
      var result = response.data;

      if (response.data.status === 1) {
        this.setState({
          //   educationDetails: response.data.data.workExperience,
        });
        var educationData = response.data.data.workExperience;

        for (var j = 0; j < this.state.industrysList.length; j++) {
          for (var i = 0; i < educationData.length; i++) {
            if (this.state.industrysList[j].id === educationData[i].industry) {
              if (educationData[i].industry === 18) {
                this.setState({
                  businessProfile: true,
                });
              } else {
                this.setState({
                  businessProfile: false,
                });
              }
            }
          }
        }
      }
    });
  }
  editSkillDetails(editSkillDetails) {
    var educationData = [];
    console.log("edit", this.state.skillDetails);
    this.setState({ editSkillsDetails: true });
    for (var i = 0; i < this.state.skillDetails.length; i++) {
      if (this.state.skillDetails[i].id == editSkillDetails) {
        console.log("Education", this.state.skillDetails[i].userId);
        this.setState({
          editId: this.state.skillDetails[i].id,
          editskillExperience: this.state.skillDetails[i].skillName,
          editskillName: this.state.skillDetails[i].experience,
          edituserId: this.state.skillDetails[i].userId,
        });
      }
    }
  }

  handleInputSkill(event, index, type) {
    // alert(event)
    console.log("inputs values");
    console.log(event);
    if (type == "skillName") {
      if (event.target.value == "") {
        this.setState({
          nameValue: false,
        });
      } else {
        this.setState({
          nameValue: true,
        });
      }
    } else {
      if (event.target.value == "") {
        this.setState({
          experienceValue: false,
        });
      } else {
        this.setState({
          experienceValue: true,
        });
      }
    }
    var newSkills = this.state.frSkillName;
    console.log("type inputs values", type);
    console.log("type inputs event", event.target.value);
    if (newSkills[index]) {
      if (type == "skillName") {
        event.preventDefault();
        event.stopPropagation();
        // if (
        //   /^[A-Za-z]+$/.test(event.target.value) ||
        //   event.target.value == ""
        // ) {
        var obj = newSkills[index];
        obj.skillName = event.target.value;
        newSkills[index] = obj;
        // }
      } else {
        console.log("inputs values 1");
        console.log(event);
        console.log(event.target.value);
        var obj = newSkills[index];
        obj.experience = event.target.value;
        newSkills[index] = obj;
      }
    } else {
      if (type == "skillName") {
        event.preventDefault();
        event.stopPropagation();
        if (/^[A-Za-z]+$/.test(event.target.value)) {
          var obj = newSkills[index];
          var obj = {
            skillName: event.target.value.trim(),
            experience: "",
          };
          newSkills[index] = obj;
        } else {
          var obj = newSkills[index];
          var obj = {
            skillName: "",
            experience: "",
          };
          newSkills[index] = obj;
        }
      } else {
        var data = event;
        var obj = newSkills[index];
        var obj = {
          skillName: "",
          experience: event.target.value,
        };
        console.log("inputsss");
        console.log(obj);
        newSkills[index] = obj;
      }
    }

    this.setState({
      frSkillName: newSkills,
    });
    console.log("this.state.frSkillName");
    console.log(newSkills);
    console.log(this.state.frSkillName);
    //  this.setState({answers:answer_id.target.value})
  }
  handleForm(event) {
    // if (/^[A-Za-z]+$/.test(event.target.value) || event.target.value == "") {
    this.setState({ [event.target.name]: event.target.value });
    // }
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  gotoprofession() {
    this.props.history.push("/my-profession");
  }
  gotoPreference = () => {
    this.props.history.push("/my-preference");
  };
  gotoValueMatch() {
    this.props.history.push("/value-match");
  }
  gotoeducation() {
    this.props.history.push("/my-education");
  }

  gotoprofile() {
    this.props.history.push("/my-profile");
  }
  gotoBusinessProfile() {
    this.props.history.push("my-business-profile");
  }
  gotoskills() {
    this.props.history.push("/my-skills");
  }

  saveSkills() {
    if (this.state.nameValue && this.state.experienceValue) {
      this.setState({ blocking: true });
      var saveSkill = {
        education: this.state.frSkillName,
        firstTimeSetup: "true",
      };
      ProfileService.saveSkillDetails(saveSkill)
        .then((response) => {
          var result = response.data;

          var data = [];
          console.log(this.state.frSkillName);
          for (let i = 0; i < this.state.frSkillName.length; i++) {
            this.setState({ frSkillName: data, editSkills: false });
            this.getSkillsDetails();
          }
          this.setState({ blocking: false });
          toast.success("Saved successfully");
        })
        .catch((error) => {
          this.setState({ blocking: false });
          console.log(error);
        });
    } else {
      toast.warn("Fill all the field");
    }
  }

  getUpdatedFriendsList() {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        this.setState({
          friendsList: result.data.friendsList,
        });
        AuthService.storeFriendsList(result.data.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProfilePhoto(userPhoto) {
    this.setState({ userPhotoImage: `data:image/jpeg;base64,${userPhoto}` });
  }
  getUserCoverPhoto(coverPhoto) {
    this.setState({
      coverPhotoImage: `data:image/jpeg;base64,${coverPhoto}`,
      coverPhotoImageState: true,
    });
  }

  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.props.loginDetails.uId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        console.log(response);
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    var friend = AuthService.getFriendsList();
    this.setState({
      FriendsList: friend,
    });
  }

  editSkills() {
    this.setState({ editSkills: true });
  }

  backSkills() {
    this.setState({
      editSkills: false,
      editSkillsDetails: false,
      frSkillName: [],
      inputs: [1],
    });
  }
  editSkillsList() {
    if (
      this.state.editskillName == "" ||
      this.state.editskillExperience == ""
    ) {
      toast.warning("Please fill the all Filed..");
    } else {
      this.setState({ blocking: true });
      var saveSkills = {
        editId: this.state.editId,
        experience: this.state.editskillName,
        skillName: this.state.editskillExperience.trim(),
      };
      ProfileService.editSkillsList(saveSkills)
        .then((response) => {
          var result = response.data;
          var data = [];
          this.getSkillsDetails();
          toast.success(`${this.props.t("Updated Successfully")}`);
          this.setState({ blocking: false });
          this.setState({
            editSkillsDetails: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false });
          console.log(error);
        });
    }
  }
  getSkillsDetails() {
    var saveSkills = {};
    ProfileService.getSkillsDetails(saveSkills)
      .then((response) => {
        var result = response.data;

        console.log("skills details");
        console.log(response.data.data);
        if (response.data.status == 1) {
          this.setState({ skillDetails: response.data.data.skills });
          var skillsData = response.data.data.skills;

          console.log("education" + skillsData);
          console.log(skillsData);
          this.setState({
            skillsData,
          });

          //   Object.keys(skillsData).map((k) => {
          //     console.log(k);
          //     console.log(k.toString());

          //     this.setState({
          //       dynamicLangArray: [...this.state.dynamicLangArray, k],
          //     });
          //   });

          //   let unique_dynamic_key = [...new Set(this.state.dynamicLangArray)];

          //   unique_dynamic_key.map((k) => {
          //     var skillName = "";
          //     var experience = "";
          //     if (k in this.state.skillDetails) {
          //       console.log(k + " in announment");
          //       skillName = this.state.skillDetails[k].skillName;
          //       experience = this.state.skillDetails[k].experience;
          //     }

          //     var obj = {
          //       skillName: skillName,
          //       experience: experience,
          //     };

          //     this.setState({
          //       frSkillName: [...this.state.frSkillName, obj],
          //     });
          //   });

          if (this.state.frSkillName.length > 0) {
            this.setState({ inputs: [] });
            for (let k = 0; k < this.state.frSkillName.length; k++) {
              var input = 1;

              this.setState({
                inputs: [...this.state.inputs, input],
              });
            }
          }
        } else {
          this.setState({ skillDetails: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  deleteSkillDetails(id) {
    var data = {
      deleteId: id,
    };
    ProfileService.deleteSkillDetails(data)
      .then((response) => {
        toast.success("Deleted successfully");
        console.log(response);
        this.getSkillsDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    industryList: state.commonApi.industrylist.data,
    blockedUserList: state.commonApi.blockedUserList,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(SkillsContainer)));
