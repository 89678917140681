import React, { Component } from "react";
import AuthService from "../../session/AuthService";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import dummyimage from "../../img/default_avatar.jpg";
import FriendsFinder from "../../api/FriendsFinder";
import "../MyGroupsandMyEvents/GroupDescription/groupdesign.css";
import PostService from "../../api/PostService_old";
import * as APPCONFIG from "../../config/app-config.js";
import VideocamIcon from "@material-ui/icons/Videocam";
import "moment/locale/pt-br";
import MentorMeeting from "./MentorMeeting";
import { getNavigationType } from "../../redux/actions/commonApiActions";
import RequestUserList from "./RequestUserList";
import { toast } from "react-toastify";

class GroupConnectionCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      friendsList: [],
      receiverId: null,
      receiverName: "",
      showModalScreen: false,
      listRefresh: 0,
    };

    this.handleClose = this.handleClose.bind(this);
    this.videoCall = this.videoCall.bind(this);
    this.acceptToMentor = this.acceptToMentor.bind(this);
    this.rejectToMentor = this.rejectToMentor.bind(this);
    this.handleModalScreenVisibility =
      this.handleModalScreenVisibility.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
  }

  closeModel = () => {
    this.props.needClose();
  };

  render() {
    const { t } = this.props;
    const { userId, displayName, biography, type, requestList } = this.props;
    return (
      <>
        {requestList && (
          <>
            {this.props.needAssessment ? (
              <>
                <RequestUserList
                  acceptAll={this.acceptAll}
                  rejectToMentor={this.rejectToMentor}
                  acceptToMentor={this.acceptToMentor}
                  mentorId={this.props.mentorId}
                  closeModel={this.closeModel}
                  showFriendsProfile={this.showFriendsProfile}
                  listRefresh={this.state.listRefresh}
                />
              </>
            ) : (
              <div class="about-card" style={{ marginTop: "1.5rem" }}>
                <div class="about-card-header border-0 pb-0">
                  <h5 class="about-card-title">{t("Requested List")}</h5>
                </div>
                {this.props.requestedList.length !== 0 && (
                  <div class="mentor-selected-slot">
                    {this.props.requestedList.map((data) => (
                      <div
                        className="friendsCardWrapper-new flexDefault flex-wrap"
                        style={{ gap: "1rem" }}
                      >
                        <div
                          className="post-avatar post-avatar-story mb-auto"
                          style={{ height: "5rem", width: "5rem" }}
                        >
                          <img
                            onClick={() => this.showFriendsProfile(data.userId)}
                            class="friend-avatar-img rounded-circle"
                            src={`${APPCONFIG.API_URL}api/getUserPhoto/${
                              data.userId
                            }/${"name"}`}
                            alt="user"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = dummyimage;
                            }}
                          />
                        </div>

                        <div
                          className="d-flex flex-column mb-auto mt-3 align-items-center"
                          style={{ gap: "1rem" }}
                        >
                          <h4>{data.userName}</h4>
                          <p className="slot-box">
                            {t("Slot")} : {data?.timeSlot?.name}
                          </p>
                          <p className="slot-box">
                            {t("Day")} : {data?.day}
                          </p>
                          <div class=" d-flex">
                            <button
                              class="btn connectionredLabel-slot ml-0"
                              onClick={() => this.rejectToMentor(data.userId)}
                            >
                              {" "}
                              {t("Busy")}{" "}
                            </button>
                            <button
                              class="btn mb-0 connectiongreenLabel"
                              onClick={() => this.acceptToMentor(data.userId)}
                            >
                              {" "}
                              {t("Accept")}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {this.props.requestedList.length === 0 && (
                  <div className="FriendsCardWrapper m-3">
                    {" "}
                    {t("No more user list present")}
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {!requestList && (
          <div class="about-card" style={{ marginTop: "1.5rem" }}>
            <div class="about-card-header border-0 pb-0">
              <h5 class="about-card-title">{t("Accepted List")}</h5>
            </div>

            {this.props.acceptedList &&
              this.props.acceptedList.map((data) => (
                <div class="friendsCardWrapper m-4 ">
                  <div
                    className="d-flex align-items-center flex-wrap p-4"
                    style={{ gap: "1rem" }}
                  >
                    <div class="friendlist-avatar ">
                      {" "}
                      <img
                        class="friend-avatar-img rounded-circle"
                        src={`${APPCONFIG.API_URL}auth/image/${data?.profilePhoto}`}
                        alt="user"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = dummyimage;
                        }}
                      />{" "}
                    </div>
                    <h5>{data.userName}</h5>
                    <p className="slot-box">
                      {t("Day")} : {data?.day}
                    </p>

                    <p className="slot-box">
                      {t("Slot")} : {data?.timeSlot?.name}
                    </p>
                    {/* {data.createdDate}
                      {moment(data.createdDate).format('mm/dd/yyyy')} */}

                    <VideocamIcon
                      onClick={() => this.videoCall(data)}
                      style={{
                        cursor: "pointer",
                        color: "#db2828",
                        marginLeft: "auto",
                      }}
                    />
                  </div>
                  {/* <hr/> */}
                </div>
              ))}
            {this.props.acceptedList.length === 0 && (
              <div class="container">
                <div className="FriendsCardWrapper ">
                  {" "}
                  {t("No more user list present")}
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.showModalScreen && (
          <MentorMeeting
            mentorId={this.props.mentorId}
            receiverId={this.state.receiverId}
            receiverName={this.state.receiverName}
            showModalScreen={this.state.showModalScreen}
            handleClose={this.handleClose}
            handleModalScreenVisibility={this.handleModalScreenVisibility}
            mentor={this.props.loginDetails}
          />
        )}
      </>
    );
  }

  handleClose() {
    this.setState({
      showModalScreen: false,
    });
  }
  handleModalScreenVisibility(setModalScreenvisibility) {
    this.setState({ showModalScreen: setModalScreenvisibility });
  }
  videoCall(data) {
    this.setState({ showModalScreen: true });
    this.setState({ receiverId: data.userId });
    this.setState({ receiverName: data.receiverName });
    console.log("Data :", data);
  }
  acceptAll = () => {
    const id = this.props.requestedList.map((item) => item.userId);
    var data = {
      mentorId: this.props.mentorId,
      acceptIds: id,
    };
    PostService.mentorAllRequestAccept(data)
      .then((response) => {
        toast.success("Accepted all the list successfully");
        this.closeModel();
      })
      .catch((error) => {
        this.closeModel();
        console.log(error);
      });
  };
  acceptToMentor(id) {
    var data = {
      mentorId: this.props.mentorId,
      acceptId: id,
    };

    PostService.acceptToMentor(data)
      .then((response) => {
        toast.success("User accepted successfully , you can join the call");
        this.setState({
          listRefresh: id,
        });
        this.props.updateAcceptList(response.data.data.accepted.userId);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  rejectToMentor(id) {
    var data = {
      mentorId: this.props.mentorId,
      rejectId: id,
    };

    PostService.rejectToMentor(data)
      .then((response) => {
        toast.success("User rejected successfully");
        this.setState({
          listRefresh: id,
        });
        this.props.updateAcceptList(response.data.data.rejected.userId);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  chatNow = (user_id) => {
    this.props.history.push(`/messages/${user_id}`);
  };

  friendRequest(id) {
    let friendRequest = {
      friendsId: id,
      requestStatus: "requested",
    };
    FriendsFinder.friendRequest(friendRequest)
      .then((response) => {
        var result = response.data;
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        var result = response.data;
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  unfriendHit(uId) {
    let unFriend = {
      userId: this.props.loginDetails.uId,
      friendsId: uId,
    };

    FriendsFinder.unfriendHit(unFriend)
      .then((response) => {
        this.props.loadFriendsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showFriendsProfile = (userId) => {
    this.props.getNavigationType(1);
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  };
}
const mapDispacthToProps = (dispatch) => {
  return {
    getNavigationType: (type) => dispatch(getNavigationType(type)),
  };
};

const mapStateToProps = function (state) {
  return {
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(GroupConnectionCard)));
