import React, { Component } from "react";
import BgBanner from "./BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import UserInfo from "./UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import Tabs from "@material-ui/core/Tabs";
import * as APPCONFIG from "../../../config/app-config.js";
import Tab from "@material-ui/core/Tab";
import dummyimage from "../../../img/default_avatar.jpg";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import { AiOutlineEdit, AiOutlineSafety } from "react-icons/ai";
import { Multiselect } from "react-widgets";
import Chip from "@material-ui/core/Chip";
import BlockUi from "react-block-ui";
import { Card, CardContent } from "@material-ui/core";
import MyCommunity from "../HomeUpdated/MyCommunity";
import MarketPlaceCard from "../../../components/MarketPlaceCard.jsx";
import { CgProfile } from "react-icons/cg";
import { GoBriefcase } from "react-icons/go";
import { BsBookmarkStar, BsClipboard } from "react-icons/bs";
import { GrValidate } from "react-icons/gr";
import UserCalendarContainer from "../../FirstTimeProfile/UserCalendarContainer.jsx";
const menus = [
  {
    title: "Profile",
    icon: <CgProfile />,
  },
  {
    title: "Value Match",
    icon: <AiOutlineSafety />,
  },
  {
    title: "Education",
    icon: <BsClipboard />,
  },
  {
    title: "Skills",
    icon: <BsBookmarkStar />,
  },
  {
    title: "Work Experience",
    icon: <GoBriefcase />,
  },
];

let newSkills = new Array(2);

class ValueMatchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      nameValue: false,
      experienceValue: false,
      editskillExperience: "",
      technical: "",
      edituserId: "",
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frSkillName: [],
      industrysList: [],
      technicalValue: "",
      editValueDetails: true,
      technicalValueForUser: "",
      //   skills: [
      //     {
      //       name: "student",
      //     },
      //     {
      //       name: "Sworking professional",
      //     },
      //     {
      //       name: "practicing profession (Auditoer,lawyer and etc)",
      //     },
      //     {
      //       name: "Entreprenur",
      //     },
      //   ],

      editSkills: false,
      editSkillsDetails: false,
      skillDetails: [],
      dynamicLangArray: [],
      lookingFor: [],
      helpOthers: [],
      helpOthersList: [],
      accomplishment: "",
      accomplishmentUserValue: "",
      lookingForList: [],
      userList: [],
      expectionHelpList: [],
      ValueMatchData: [],
      userInterestName: [],
      userHelpName: [],
      expectId: "",
      expectationId: "",
      activeMenu: 1,
      // valueMatch:true,
      // profile:false,
      // education:false,
      // profession:false,
      // skills:false,
    };
    this.handleMultiSelect = this.handleMultiSelect.bind(this);
    this.lookingFor = this.lookingFor.bind(this);
    //this.handleInputSkill = this.handleInputSkill.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getProfilePhoto = this.getProfilePhoto.bind(this);
    this.getUserCoverPhoto = this.getUserCoverPhoto.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getUpdatedFriendsList = this.getUpdatedFriendsList.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.getCoverPhotos = this.getCoverPhotos.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.gotoprofession = this.gotoprofession.bind(this);
    this.gotoeducation = this.gotoeducation.bind(this);
    this.gotoprofile = this.gotoprofile.bind(this);
    this.gotoValueMatch = this.gotoValueMatch.bind(this);
    this.gotoskills = this.gotoskills.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.removeTranslation = this.removeTranslation.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveMatchSkills = this.saveMatchSkills.bind(this);
    this.editValues = this.editValues.bind(this);
    this.editDetails = this.editDetails.bind(this);
    this.editValueList = this.editValueList.bind(this);
    this.gotoBusinessProfile = this.gotoBusinessProfile.bind(this);
    this.expectationHelp = this.expectationHelp.bind(this);
    this.expectionInterest = this.expectionInterest.bind(this);
    this.getUserExpectation = this.getUserExpectation.bind(this);
  }

  componentDidMount() {
    this.getUserExpectation();
    this.expectationHelp();
    this.expectionInterest();

    if (
      this.props.loginDetails.profession_Id == 3 ||
      this.props.loginDetails.profession_Id == 4
    ) {
      this.setState({
        businessProfile: true,
      });
    } else {
      this.setState({
        businessProfile: false,
      });
    }
    // this.getSkillsDetails();
    //this.getKnowledgePoints();
    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    console.log("friends****", this.props.FriendsList);
    this.setState({ userPhotoImage: profileImage });
    if (this.props.loginDetails.hasOwnProperty("cPhoto")) {
      this.setState({
        coverPhotoImage: banner,
      });
    } else {
      this.getCoverPhotos(this.props.loginDetails.cover_uuid);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      friendsList: AuthService.getFriendsList(),
    });
  }

  addTranslation = (e) => {
    if (!this.state.nameValue || !this.state.experienceValue) {
      toast.warn("Fill all the field");
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
      });
    }
  };

  initfunction() {
    this.getallHobbies();
    this.getallGrade();
    this.getFriendsList();
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }

  async removeTranslation(record, index) {
    console.log("REMOVE TRANSLATION");
    console.log(index);
    console.log(this.state.inputs);
    var inpu = this.state.inputs;
    inpu.splice(index, 1);
    if (this.state.frSkillName[index]) {
      var val = this.state.frSkillName;
      val.splice(index, 1);
      this.setState({
        frSkillName: val,
      });
    }
    this.setState({
      nameValue: true,
      experienceValue: true,
    });
    await this.setState({
      inputs: inpu,
    });
  }

  async getCoverPhotos(coverPhoto) {
    console.log("coverPhotoImage");

    if (this.props.loginDetails.cover_uuid != "") {
      console.log("entering if");
      await this.setState({
        coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      });
      console.log(this.state.coverPhotoImage);
    } else {
      console.log("entering else");
      await this.setState({ coverPhotoImage: banner });
      console.log(this.state.coverPhotoImage);
    }
  }
  changeMenu = (index) => {
    if (index === 0) {
      this.props.history.push("/my-profile");
    } else if (index === 2) {
      this.props.history.push("/my-education");
    } else if (index === 3) {
      this.props.history.push("/my-skills");
    } else if (index === 4) {
      this.props.history.push("/my-profession");
    }
  };
  render() {
    const { t } = this.props;
    const { valueMatch, profile, education, profession, skills } = this.state;

    return (
      <Container>
        <ToastContainer position="top-right" autoClose={5000} />
        {/* <Card>
          <CardContent> */}
        <div className="profileMainWrapper">
          {!this.props.firstTime && (
            <h2 className="pageHeading">{t("Profile")}</h2>
          )}
          <div className="profileWrapper">
            {!this.props.firstTime && (
              <BgBanner
                coverPhoto={this.state.coverPhotoImage}
                profilePhoto={this.state.userPhotoImage}
                userName={"User name"}
                getUserPhoto={this.getProfilePhoto}
                getCoverPhoto={this.getUserCoverPhoto}
              />
            )}
            {!this.props.firstTime && (
              <Grid container spacing={2} className="userDetailsWrapper">
                <Grid item md={12}>
                  <UserInfo
                    name={this.props.loginDetails.dName}
                    designation={this.props.loginDetails.biography}
                    additionalInfo={this.props.loginDetails.hsTag}
                    pointsCount={this.state.userKnowledgepoint}
                  />
                </Grid>
              </Grid>
            )}

            {!this.props.firstTime && (
              <div className="tabWrapper">
                <div className=" ">
                  <Container>
                    <div className="flexDefault  ">
                      <Tabs
                        TabIndicatorProps={{
                          style: { background: "#A00030" },
                        }}
                        value={this.state.tabIndex}
                        // indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="disabled tabs example"
                      >
                        <Tab label={t("PROFILE")} />
                        <Tab label={t("FRIENDS")} />
                        <Tab label={t("BLOCKED USER")} />
                        <Tab label={t("FEED")} />
                        <Tab label={t("BOOKMARKED POSTS")} />

                        {this.props.loginDetails?.isVerifySeller && (
                          <Tab label={t("PRODUCTS")} />
                        )}
                      </Tabs>
                    </div>
                  </Container>
                </div>
                <div className="profilepageWrapperProfileSetup">
                  {this.state.tabIndex === 5 ? (
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                        <MarketPlaceCard />
                      </Grid>
                    </Grid>
                  ) : this.state.tabIndex === 6 ? (
                    <Grid container spacing={0}>
                      <Grid item md={12} xs={12} sm={12} lg={12}>
                        <UserCalendarContainer />
                      </Grid>
                    </Grid>
                  ) : this.state.tabIndex === 0 ? (
                    <div>
                      <Grid container spacing={0}>
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <div className="">
                            <Grid container spacing={1}>
                              <Grid item md={3} xs={12} sm={12} lg={3}>
                                <ul className="profile-card-v1">
                                  {menus.map((item, index) => (
                                    <li
                                      className={
                                        index === this.state.activeMenu
                                          ? "bg-color"
                                          : ""
                                      }
                                      key={index}
                                      onClick={() => this.changeMenu(index)}
                                    >
                                      {item.icon}
                                      <h4>{t(`${item.title}`)}</h4>
                                    </li>
                                  ))}
                                </ul>
                              </Grid>

                              <Grid item md={9} xs={12} sm={12} lg={9}>
                                {this.state.editValueDetails && (
                                  <>
                                    <div className="valueselectCard">
                                      {this.state.expectId == "" && (
                                        <>
                                          <Grid
                                            item
                                            md={6}
                                            sm={6}
                                            xs={6}
                                          ></Grid>

                                          <Grid item md={6} sm={6} xs={6}>
                                            <div
                                              className=""
                                              style={{ float: "left" }}
                                            >
                                              <button onClick={this.editValues}>
                                                + {t("Add")}
                                              </button>
                                            </div>
                                          </Grid>
                                        </>
                                      )}
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          md={12}
                                          lg={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          {this.state.expectId !== "" && (
                                            <div
                                              className="profileselectCard"
                                              style={{ height: "750px" }}
                                            >
                                              <div
                                                onClick={this.editValues}
                                                style={{
                                                  cursor: "pointer",
                                                  display: "inline-flex",
                                                  color: "#A00030",
                                                  float: "right",
                                                }}
                                              >
                                                <AiOutlineEdit
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                >
                                                  {" "}
                                                </AiOutlineEdit>{" "}
                                                {t("Edit")}
                                              </div>

                                              <p
                                                style={{
                                                  color: "#000000",
                                                  padding: "8px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {t("Looking For")}
                                              </p>
                                              <Grid container spacing={1}>
                                                {this.state.lookingFor &&
                                                  this.state.lookingFor.map(
                                                    (subject, index) => (
                                                      <Grid
                                                        item
                                                        lg={3}
                                                        md={3}
                                                        sm={6}
                                                        xs={6}
                                                      >
                                                        <Chip
                                                          className="myRequestCardDetails"
                                                          style={{
                                                            overflow: "hidden",
                                                            Whitespace:
                                                              "nowrap",
                                                            fontSize: "12px",
                                                            textOverflow:
                                                              "ellipsis",
                                                            borderRadius:
                                                              "10px",
                                                            backgroundColor:
                                                              "rgb(242,242,242)",
                                                          }}
                                                          label={
                                                            subject.expectionName
                                                          }
                                                        ></Chip>
                                                      </Grid>
                                                    )
                                                  )}
                                              </Grid>

                                              <p
                                                style={{
                                                  color: "#000000",
                                                  padding: "8px",
                                                  //   cursor: "pointer",
                                                }}
                                              >
                                                {t("I want to help Yuukke")}
                                              </p>
                                              <Grid container spacing={2}>
                                                {this.state.helpOthers &&
                                                  this.state.helpOthers.map(
                                                    (subject, index) => (
                                                      <Grid
                                                        item
                                                        lg={3}
                                                        md={3}
                                                        sm={6}
                                                        xs={6}
                                                      >
                                                        <Chip
                                                          className="myRequestCardDetails"
                                                          style={{
                                                            overflow: "hidden",
                                                            Whitespace:
                                                              "nowrap",
                                                            fontSize: "12px",
                                                            textOverflow:
                                                              "ellipsis",
                                                            borderRadius:
                                                              "10px",
                                                            backgroundColor:
                                                              "rgb(242,242,242)",
                                                          }}
                                                          label={
                                                            subject.expectionHelpName
                                                          }
                                                        ></Chip>
                                                      </Grid>
                                                    )
                                                  )}
                                              </Grid>

                                              <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                sm={12}
                                                lg={12}
                                              >
                                                <div className="">
                                                  <p
                                                    style={{
                                                      color: "#000000",
                                                      padding: "8px",
                                                      //    cursor: "pointer",
                                                    }}
                                                  >
                                                    {t("My Accomplishment")}
                                                  </p>
                                                  <p
                                                    style={{
                                                      color: "gray",
                                                      paddingLeft: "18px",
                                                      fontSize: "12px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {this.state.accomplishment}
                                                  </p>
                                                </div>
                                              </Grid>
                                            </div>
                                          )}
                                          {/* )
                                                )} */}
                                          {this.state.expectId == "" && (
                                            <p
                                              className=""
                                              style={{
                                                //float: "left",
                                                marginLeft: "20px",
                                              }}
                                            >
                                              {t("No Value added yet")}
                                            </p>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </>
                                )}

                                {!this.state.editValueDetails && (
                                  <>
                                    <div className="profileselectCard">
                                      <Grid item md={12} sm={12} xs={12}>
                                        <div
                                          className=""
                                          style={{
                                            float: "left",
                                            margin: "20px",
                                          }}
                                        >
                                          <button onClick={this.editValues}>
                                            {t("Back")}
                                          </button>
                                        </div>
                                      </Grid>

                                      {/* {this.state.inputs.length > 0 &&
                                              this.state.inputs.map(
                                                (entry, index) => ( */}
                                      <BlockUi
                                        tag="div"
                                        blocking={this.state.blocking}
                                        message={t("Loading, please wait")}
                                        color="#A00030"
                                      >
                                        <Grid container spacing={2}>
                                          <Grid
                                            item
                                            md={6}
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <h6>
                                              {" "}
                                              {t("What are you looking for ?")}
                                            </h6>
                                            <Multiselect
                                              className="profileFormInput aboutInputAddButton"
                                              required
                                              dataKey="expectationId"
                                              data={this.state.lookingForList}
                                              valueField="expectationId"
                                              textField="expectionName"
                                              value={this.state.lookingFor}
                                              onChange={(value) =>
                                                this.lookingFor(value)
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            md={6}
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <h6>
                                              {" "}
                                              {t(
                                                "How can you help others on Yuukke"
                                              )}{" "}
                                            </h6>
                                            <Multiselect
                                              className="profileFormInput aboutInputAddButton"
                                              required
                                              data={
                                                this.state.expectionHelpList
                                              }
                                              dataKey="expectationHelpId"
                                              valueField="expectationHelpId"
                                              textField="expectionHelpName"
                                              value={this.state.helpOthers}
                                              onChange={(value) =>
                                                this.handleMultiSelect(value)
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <h6>
                                              {" "}
                                              {t(
                                                "Are you technical ?( You are a programmer, scientist or engineer who can build the product without outside assistance )"
                                              )}
                                            </h6>
                                            <TextField
                                              fullWidth
                                              id="dropdown"
                                              select
                                              label=""
                                              variant="outlined"
                                              type="text"
                                              required=""
                                              name="technical"
                                              value={this.state.technical}
                                              onChange={this.handleForm}
                                            >
                                              <MenuItem value="Yes">
                                                <>{t("Yes")}</>
                                              </MenuItem>
                                              <MenuItem value="No">
                                                <>{t("No")}</>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </Grid>
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <h6>
                                              {" "}
                                              {t(
                                                "Impressive accomplishment"
                                              )}{" "}
                                            </h6>
                                            <textarea
                                              className="formInputsDescriptions"
                                              name="accomplishment"
                                              rows="4"
                                              cols="50"
                                              value={this.state.accomplishment}
                                              onChange={this.handleForm}
                                            />
                                          </Grid>{" "}
                                        </Grid>
                                        {/* )
                                               )} */}
                                        <Grid container spacing={0}>
                                          <Grid
                                            item
                                            md={6}
                                            xs={6}
                                            sm={6}
                                            lg={6}
                                          >
                                            {
                                              <div className="profileFormBtn">
                                                {this.state.expectId == "" && (
                                                  <button
                                                    style={{
                                                      borderRadius: "10px",
                                                      padding: "8px",
                                                    }}
                                                    onClick={
                                                      this.saveMatchSkills
                                                    }
                                                  >
                                                    {t("Save")}
                                                  </button>
                                                )}
                                                {this.state.expectId != "" && (
                                                  <button
                                                    style={{
                                                      borderRadius: "10px",
                                                      padding: "8px",
                                                    }}
                                                    onClick={this.editValueList}
                                                  >
                                                    {t("Update")}
                                                  </button>
                                                )}
                                              </div>
                                            }
                                          </Grid>
                                        </Grid>
                                      </BlockUi>
                                    </div>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 1 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Friends")}
                          </p>
                        </Grid>
                        {this.props.FriendsList &&
                          this.props.FriendsList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                friendId={data.userId}
                                fUserId={data.userProfile.userId}
                                image={data.profilePhoto}
                                name={`${data.userProfile.firstName} ${data.userProfile.lastName}`}
                                Dname={data.userProfile.displayName}
                                smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.FriendsList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No friends available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : this.state.tabIndex === 2 ? (
                    <div>
                      <Grid
                        container
                        spacing={2}
                        className="userDetailsWrapper-friend"
                      >
                        <Grid item md={12} xs={12} sm={12} lg={12}>
                          <p className="boldLabel" style={{ marginBottom: 10 }}>
                            {t("Blocked User")}
                          </p>{" "}
                        </Grid>
                        {this.props.blockedUserList &&
                          this.props.blockedUserList.map((data) => (
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <FriendsCard
                                tabIndex={"BlockedUser"}
                                friendId={data.blockUserId}
                                fUserId={data.blockUserId}
                                image={data.profilePhoto}
                                name={data.userName}
                                Dname={data.userName}
                                // smallDescription={data.userProfile.biography}
                                unfriendHits={this.getUpdatedFriendsList}
                              />
                            </Grid>
                          ))}
                        {this.props.blockedUserList.length === 0 && (
                          <Card>
                            <CardContent>
                              <center>
                                <p>{t("No More Blocked List Available")}</p>
                              </center>
                            </CardContent>
                          </Card>
                        )}{" "}
                      </Grid>
                    </div>
                  ) : (
                    (this.state.tabIndex === 3 ||
                      this.state.tabIndex === 4) && (
                      <div style={{ marginTop: "5px" }}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <MyCommunity
                              type={
                                this.state.tabIndex === 3 ? "Feed" : "BookMark"
                              }
                            />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    );
  }
  getUserExpectation() {
    let getFriendsList = {};
    ProfileService.getUserExpectation(getFriendsList)
      .then((response) => {
        var result = response.data.data.userExpectation;
        var expectionHelp = result.expectionHelpId;
        console.log("result ff", result);
        var expectionInterest = result.expectionInterestId;
        var userInterestId =
          this.props.expectationInterestList.expectionInterest;
        var userHelpId = this.props.expectationHelpList.expectionHelp;
        this.setState({
          accomplishment: result.technical,
          accomplishmentUserValue: result.technical,
          expectId: result.id,
        });
        if (result.accomplishment) {
          this.setState({
            technical: "Yes",
            technicalValueForUser: "Yes",
          });
        } else {
          this.setState({
            technical: "No",
            technicalValueForUser: "No",
          });
        }
        var lList = [];
        for (var i = 0; i < userInterestId.length; i++) {
          for (var j = 0; j < expectionInterest.length; j++) {
            if (
              expectionInterest[j].expectionInterestId ==
              userInterestId[i].expectationId
            ) {
              //   console.log("result ",userInterestId[i].expectionName,expectionInterest[j].expectionInterestId)
              lList.push({
                expectationId: userInterestId[i].expectationId,
                expectionName: userInterestId[i].expectionName,
              });
            }
          }
        }
        this.setState({ lookingFor: lList, userList: lList });
        console.log("result ", lList);
        var HelpList = [];

        console.log("result user", expectionHelp);
        console.log("result api", userHelpId);
        for (var i = 0; i < userHelpId.length; i++) {
          for (var j = 0; j < expectionHelp.length; j++) {
            if (
              expectionHelp[j].expectationHelpId ==
              userHelpId[i].expectationHelpId
            ) {
              HelpList.push({
                expectationHelpId: userHelpId[i].expectationHelpId,
                expectionHelpName: userHelpId[i].expectionHelpName,
              });
            }
          }
        }

        this.setState({ helpOthers: HelpList, helpOthersList: HelpList });
        console.log("result ", HelpList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  expectationHelp() {
    var expectionHelp = this.props.expectationHelpList;

    var lList = [];
    if (expectionHelp) {
      for (var i = 0; i < expectionHelp.expectionHelp.length; i++) {
        var obj = {};

        obj["expectationHelpId"] =
          expectionHelp.expectionHelp[i].expectationHelpId;
        obj["expectionHelpName"] =
          expectionHelp.expectionHelp[i].expectionHelpName;
        lList.push(obj);
      }
    }

    this.setState({ expectionHelpList: lList });
  }
  expectionInterest() {
    var expectionHelp = this.props.expectationInterestList;

    var lList = [];
    for (var i = 0; i < expectionHelp.expectionInterest.length; i++) {
      var obj = {};

      obj["expectationId"] = expectionHelp.expectionInterest[i].expectationId;
      obj["expectionName"] = expectionHelp.expectionInterest[i].expectionName;
      lList.push(obj);
    }
    this.setState({ lookingForList: lList });
  }

  // this.getEducationDetails(lList);

  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getWorkExperienceDetails(saveEducation).then((response) => {
      var result = response.data;

      if (response.data.status === 1) {
        this.setState({
          //   educationDetails: response.data.data.workExperience,
        });
        var educationData = response.data.data.workExperience;

        for (var j = 0; j < this.state.industrysList.length; j++) {
          for (var i = 0; i < educationData.length; i++) {
            if (this.state.industrysList[j].id === educationData[i].industry) {
              if (educationData[i].industry === 18) {
                this.setState({
                  businessProfile: true,
                });
              } else {
                this.setState({
                  businessProfile: false,
                });
              }
            }
          }
        }
      }
    });
  }
  editDetails() {
    var educationData = []; //editSkills tr
    console.log("edit", this.state.skillDetails);
    this.setState({ editSkillsDetails: true });
  }

  handleForm(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  gotoprofession() {
    this.props.history.push("/my-profession");
  }

  gotoeducation() {
    this.props.history.push("/my-education");
  }
  gotoPreference = () => {
    this.props.history.push("/my-preference");
  };
  gotoprofile() {
    this.props.history.push("/my-profile");
  }
  gotoValueMatch() {
    // this.setState({
    //   valueMatch:true,
    //   profile:false,
    //   education:false,
    //   profession:false,
    //   skills:false,

    // })
    this.props.history.push("/value-match");
  }
  gotoBusinessProfile() {
    this.props.history.push("my-business-profile");
  }
  gotoskills() {
    this.props.history.push("/my-skills");
  }

  saveMatchSkills() {
    var technicalValue = false;
    if (this.state.technical == "Yes") {
      technicalValue = true;
    } else {
      technicalValue = false;
    }
    if (
      this.state.accomplishment != "" &&
      this.state.helpOthers != "" &&
      this.state.technical != "" &&
      this.state.lookingFor != ""
    ) {
      var helpOthersList = [];
      var lookingForList = [];
      for (var i = 0; i < this.state.helpOthers.length; i++) {
        helpOthersList.push(this.state.helpOthers[i].expectationHelpId);
      }
      for (var i = 0; i < this.state.lookingFor.length; i++) {
        lookingForList.push(this.state.lookingFor[i].expectationId);
      }

      console.log("this.state.helpOthers", helpOthersList);
      this.setState({ blocking: true });
      var saveSkill = {
        expectionHelpId: helpOthersList,
        expectionInterestId: lookingForList,
        technical: this.state.accomplishment,
        accomplishment: technicalValue,
      };
      ProfileService.saveUserExpectation(saveSkill)
        .then((response) => {
          this.editValues();
          var result = response.data;

          var data = [];
          console.log(this.state.frSkillName);
          this.setState({ blocking: false });
          this.getUserExpectation();
          this.setState({
            //  skillDetails:result.data.userExpectation
          });

          toast.success("Saved successfully");
        })
        .catch((error) => {
          console.log(error);
          this.setState({ blocking: false });
          this.editValues();
        });
    } else {
      toast.warn("Fill all the field");
    }
  }

  getUpdatedFriendsList() {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        this.setState({
          friendsList: result.data.friendsList,
        });
        AuthService.storeFriendsList(result.data.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleMultiSelect(friend_value) {
    this.setState({
      helpOthers: friend_value,
    });
  }
  lookingFor(looking_For) {
    this.setState({
      lookingFor: looking_For,
    });
  }

  getProfilePhoto(userPhoto) {
    this.setState({ userPhotoImage: `data:image/jpeg;base64,${userPhoto}` });
  }
  getUserCoverPhoto(coverPhoto) {
    this.setState({
      coverPhotoImage: `data:image/jpeg;base64,${coverPhoto}`,
      coverPhotoImageState: true,
    });
  }

  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.props.loginDetails.uId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        console.log(response);
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    var friend = AuthService.getFriendsList();
    this.setState({
      FriendsList: friend,
    });
  }

  editValues() {
    console.log("helpOthers", this.state.helpOthers);
    this.setState({
      editValueDetails: !this.state.editValueDetails,
      lookingFor: this.state.userList,
      helpOthers: this.state.helpOthersList,
      accomplishment: this.state.accomplishmentUserValue,
      technical: this.state.technicalValueForUser,
    });
  }

  editValueList() {
    var technicalValue = false;
    if (this.state.technical == "Yes") {
      technicalValue = true;
    } else {
      technicalValue = false;
    }
    console.log("this.state.helpOthers", this.state.helpOthers);
    if (this.state.technical)
      if (
        this.state.accomplishment != "" &&
        this.state.helpOthers != "" &&
        this.state.technical != "" &&
        this.state.lookingFor != ""
      ) {
        var helpOthersList = [];
        var lookingForList = [];
        for (var i = 0; i < this.state.helpOthers.length; i++) {
          helpOthersList.push(this.state.helpOthers[i].expectationHelpId);
        }
        for (var i = 0; i < this.state.lookingFor.length; i++) {
          lookingForList.push(this.state.lookingFor[i].expectationId);
        }
        this.setState({ blocking: true });
        var saveSkill = {
          expectationId: this.state.expectId,
          expectionHelpId: helpOthersList,
          expectionInterestId: lookingForList,
          technical: this.state.accomplishment,
          accomplishment: technicalValue,
        };
        ProfileService.updateUserExpectation(saveSkill)
          .then((response) => {
            this.setState({ blocking: false });
            this.editValues();
            var result = response.data;

            var data = [];
            console.log(this.state.frSkillName);
            this.getUserExpectation();
            this.setState({
              //  skillDetails:result.data.userExpectation
            });
            toast.success(`${this.props.t("Updated Successfully")}`);
          })
          .catch((error) => {
            this.setState({ blocking: false });
            this.editValues();
            console.log(error);
          });
      } else {
        toast.warn(`${this.props.t("Fill all the field")}`);
      }
  }
  getSkillsDetails() {
    var saveSkills = {};
    ProfileService.getSkillsDetails(saveSkills)
      .then((response) => {
        var result = response.data;

        console.log("skills details");
        console.log(response.data.data);
        if (response.data.status == 1) {
          this.setState({ skillDetails: response.data.data.skills });
          var skillsData = response.data.data.skills;

          console.log("education" + skillsData);
          console.log(skillsData);
          this.setState({
            skillsData,
          });

          //   Object.keys(skillsData).map((k) => {
          //     console.log(k);
          //     console.log(k.toString());

          //     this.setState({
          //       dynamicLangArray: [...this.state.dynamicLangArray, k],
          //     });
          //   });

          //   let unique_dynamic_key = [...new Set(this.state.dynamicLangArray)];

          //   unique_dynamic_key.map((k) => {
          //     var skillName = "";
          //     var experience = "";
          //     if (k in this.state.skillDetails) {
          //       console.log(k + " in announment");
          //       skillName = this.state.skillDetails[k].skillName;
          //       experience = this.state.skillDetails[k].experience;
          //     }

          //     var obj = {
          //       skillName: skillName,
          //       experience: experience,
          //     };

          //     this.setState({
          //       frSkillName: [...this.state.frSkillName, obj],
          //     });
          //   });

          if (this.state.frSkillName.length > 0) {
            this.setState({ inputs: [] });
            for (let k = 0; k < this.state.frSkillName.length; k++) {
              var input = 1;

              this.setState({
                inputs: [...this.state.inputs, input],
              });
            }
          }
        } else {
          this.setState({ skillDetails: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    industryList: state.commonApi.industrylist.data,
    expectationInterestList: state.commonApi.expectationInterestList,
    expectationHelpList: state.commonApi.expectationHelpList,
    blockedUserList: state.commonApi.blockedUserList,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(withRouter(ValueMatchContainer)));
