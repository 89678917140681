import React, { Component } from "react";
import AuthService from "../session/AuthService";
import FriendsFinder from "../api/FriendsFinder";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router";
import ForumService from "../api/ForumService";
//import dummyimage from '../img/avatar_dummy.png'
import dummyimage from "../img/default_avatar.jpg";
import banner from "../img/banner.jpg";
import ProfileService from "../api/ProfileService";
import { connect } from "react-redux";
import SuggestedFriendProfile from "../screens/Home/ProfileFriends/SuggestedFriendProfile";
import { API_URL } from "../config/app-config";
import { getUserRequestList } from "../redux/actions/PeerMenuListActions";
import { giveUserAction, premiumUser } from "../utils";
import { needPhotVerification } from "../redux/actions/commonApiActions";
import { toast } from "react-toastify";

let myProfileDialog = {
  backgroundColor: "#ffffff",
  color: "#000",
  width: "500px",
  minHeight: "100px",
  padding: "0px",
  borderRadius: "10px",
};
class PeopleCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64String: "",
      coverPhoto: "",
      base64StringState: false,
      mutualFriendsCount: 0,
      showFriendsProfile: false,
    };
    this.friendIgnore = this.friendIgnore.bind(this);
    this.load = this.load.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
    this.getUserSchoolAndClass = this.getUserSchoolAndClass.bind(this);
    this.getCoverPhoto = this.getCoverPhoto.bind(this);
    this.getSchoolName = this.getSchoolName.bind(this);
    this.showFriendsProfile = this.showFriendsProfile.bind(this);
    this.closeFriendsProfile = this.closeFriendsProfile.bind(this);
  }

  componentDidMount() {
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);
    this.setState({}, () => {
      // this.load(this.props.userId, this.props.subjects)
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId)
      this.setState({}, () => {
        // this.load(this.props.userId, this.props.subjects)
      });
  }

  render() {
    const { t } = this.props;
    const {
      profilePhoto,
      coverPhoto,
      imgSrc,
      id,
      userId,
      name,
      mutualFriends,
      Dname,
      userName,
      biography,
      country,
      nameLocation,
      profession,
      premiumData,
    } = this.props;
    return (
      <div className="peopleCardWrapperGroup">
        <img
          className="bannerImg"
          src={coverPhoto ? API_URL + "auth/image/" + coverPhoto : banner}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = banner;
          }}
          alt={name}
        ></img>
        <div className="w3-row">
          <div className="w3-col w3-container w3-green">
            <div>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => this.showFriendsProfile(userId)}
                className="userImg"
                src={
                  profilePhoto
                    ? API_URL + "auth/image/" + profilePhoto
                    : dummyimage
                }
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyimage;
                }}

                // src={this.state.base64String === "" ? dummyimage : this.state.base64String} alt={name}
              />
            </div>
          </div>
        </div>
        <div className="peopleCardInfor">
          {/* <h2>{name}</h2> */}
          <h2
            style={{ cursor: "pointer" }}
            onClick={() => this.showFriendsProfile(userId)}
          >
            {userName}
            {premiumData && premiumUser(premiumData) && (
              <img
                src={premiumUser(premiumData)}
                alt=""
                className="ml-1"
                style={{ width: "20px" }}
              />
            )}
          </h2>
          {/* <p lassName="navMenuTextoverFlow">{biography}</p> */}
          {/* {this.props.tagsValue && (
            <p>{this.getSearchedTagValue(this.props.tagsValue)}</p>
          )} */}
          <p lassName="navMenuTextoverFlow">{profession}</p>
          <p lassName="navMenuTextoverFlow">{country}</p>
          <p lassName="navMenuTextoverFlow">{nameLocation}</p>
          {/* <p>{this.state.schoolName} - {this.state.className}</p>
          <p>{this.state.subjectslist ? this.state.subjectslist.substring(0,40):"-"} {(this.state.subjectslist && this.state.subjectslist.length >39) && "..."}</p>
       */}
        </div>
        <div className="peopleCardAddRemove flex">
          <div
            className="peopleCardAdd"
            onClick={() => this.friendRequest(userId)}
          >
            <p>{t("Connect")}</p>
          </div>
        </div>
        {/* {window.innerWidth <= 768 ? (
          <>
         
            <SkyLight
              dialogStyles={{
                backgroundColor: "#ffffff",
                color: "#000",
                width: "280px",
                minHeight: "280px",
                padding: "0px",
                borderRadius: "10px",
                marginRight:"14px"
              }}
              afterClose={this.closeFriendsProfile}
              hideOnOverlayClicked
              ref={(ref) => (this.simpleDialog = ref)}
            >
              <div className="">
                {this.state.showFriendsProfile && (
                  <SuggestedFriendProfile uid={userId} />
                )}
              </div>
            </SkyLight>
            
          </>
        ) : (
          window.innerWidth > 768 && (
            <>
              <SkyLight
                dialogStyles={{
                  backgroundColor: "#ffffff",
                  color: "#000",
                  width: "500px",
                  minHeight: "250px",
                  padding: "0px",
                  borderRadius: "10px",
                }}
                afterClose={this.closeFriendsProfile}
                hideOnOverlayClicked
                ref={(ref) => (this.simpleDialog = ref)}
              >
                <div className="">
                  {this.state.showFriendsProfile && (
                   <SuggestedFriendProfile uid={userId} />
                  )}
                </div>
              </SkyLight>
            </>
          )
        )} */}
        {/* <SkyLight      dialogStyles={myProfileDialog} afterClose={this.closeFriendsProfile} hideOnOverlayClicked ref={ref => this.simpleDialog = ref}>
                      <div className="">
                        {this.state.showFriendsProfile &&
                          <SuggestedFriendProfile uid={userId} />
                        }

                      </div>
                    </SkyLight> */}
      </div>
    );
  }
  getSearchedTagValue = (tags) => {
    const searchKeyword = this.props.searchData || "";

    console.log("Tag Value", searchKeyword);

    if (Array.isArray(tags) && tags.length > 0) {
      const result = tags.filter((val) =>
        val.tagName.toLowerCase().includes(searchKeyword.toLocaleLowerCase())
      );
      console.log("Tag searchvalue", result);

      if (result.length > 0) {
        return result[0]?.tagName;
      } else {
        return tags[0].tagName;
      }
    }
    return "-------";
  };
  showFriendsProfile(userId) {
    // this.setState({
    //   showFriendsProfile: true
    // })
    // this.simpleDialog.show()
    this.props.history.push(
      `/${this.props.workspaceType}/friendDescription/${userId}`,
      { from: window.location.pathname }
    );
  }
  closeFriendsProfile() {
    this.setState({
      showFriendsProfile: false,
    });
  }
  load(data, subjects) {
    // this.getBase64String(data);
    // this.getUserSchoolAndClass(data)
    // this.getCoverPhoto(data);
    this.getSchoolName(subjects);
  }

  getUserSchoolAndClass(data) {
    let schoolandclass = {
      userId: data,
    };

    FriendsFinder.getUserSchoolAndClass(schoolandclass)
      .then((response) => {
        var result = response.data;

        if (!result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({ schoolName: "", className: "" });
        } else if (result.data.hasOwnProperty("userSchoolAndClass")) {
          this.setState({
            schoolName: result.data.userSchoolAndClass.schoolName,
            className: result.data.userSchoolAndClass.className,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getSchoolName(subjects) {
    var subjectName = new Array();
    if (this.props.subjectsList && this.props.subjectsList.data) {
      for (let i = 0; i < this.props.subjectsList.data.length; i++) {
        for (let j = 0; j < subjects.length; j++) {
          if (
            this.props.subjectsList.data[i].subjectId == subjects[j].subjectId
          ) {
            subjectName.push(this.props.subjectsList.data[i].subjectName);
          }
        }
      }
      var subjects = subjectName.join(",");
      await this.setState({ subjectslist: subjects });
    }
  }

  getCoverPhoto(uid) {
    let coverPhoto = {
      userId: uid,
    };
    var response = ForumService.getUserCoverPhotoCache(coverPhoto);
    console.log(response);
    if (response) {
      var value = response.data.data;
      if (value && value.coverPhoto) {
        this.setState({ coverPhoto: response.data.data.coverPhoto });
        // console.log("cache "+base64String)
      }
    } else {
      ProfileService.getUsercoverPhoto(coverPhoto)

        .then((response) => {
          var result = response.data;
          ForumService.setUserCoverPhotoCache(coverPhoto, response);
          this.setState({
            coverPhoto: result.data.coverPhoto,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({ base64String: base64String, base64StringState: true });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({
              base64String: base64String,
              base64StringState: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  friendIgnore(id) {
    let friendIgnore = {
      recommendfriendsId: id,
      status: "ignored",
    };
    FriendsFinder.recommendedfriendIgnore(friendIgnore)
      .then((response) => {
        this.props.ignoreSent();
        var result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  notify = () => {
    toast.info(
      <div>
        <p>Your limit has been reached.</p>
        <p>Please recharge your account to continue using the service.</p>
        <a href="http://localhost:3000/premium-subscription">Recharge Now</a>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "center-toast",
      }
    );
  };

  friendRequest(id) {
    if (giveUserAction(this.props.loginDetails?.profile_complete)) {
      console.log("user-action");
      this.props.needPhotVerification(true);
    } else {
      let friendRequest = {
        friendsId: id,
        requestStatus: "requested",
      };
      FriendsFinder.friendRequest(friendRequest)
        .then((response) => {
          if (response.data.data !== "expired") {
            toast.success("Friend request sent successfully");
            this.props.requestSent(id);

            this.props.getUserRequestList();
            var result = response.data;
          } else {
            this.notify();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

//export default withTranslation()(withRouter(PeopleCard));

const mapStateToProps = function (state) {
  return {
    subjectsList: state.commonApi.subjectslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getUserRequestList: () => dispatch(getUserRequestList()),
    needPhotVerification: (data) => dispatch(needPhotVerification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(PeopleCard)));
