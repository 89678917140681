import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "react-widgets/styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Avatar, Chip, Grid } from "@material-ui/core";
import { getJobRequestByOtherUser } from "../../redux/actions/myRequestActions";
import dummyimage_banner from "../../img/Yuukke_Cover.jpg";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Switch from "../../components/Switch";
import * as APPCONFIG from "../../config/app-config.js";
import { getMyCommunityList } from "../../redux/actions/subjectActions";
import { MdOutlineGroups } from "react-icons/md";
import GroupsCard from "../Community/Subjects/GroupsCard";
import BlockUi from "react-block-ui";

let filterDataKeys = [];
let filterTags = [];

class MyCommunityCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: [],
      searchValue: "",
      filterKeys: [],
      addFilterList: [],
      filterTags: [],
      tagList: [],
    };
  }

  componentDidMount() {
    filterTags.length = 0;
    filterDataKeys.length = 0;
    if (this.props.tagList) {
      const checkBoxStatus = this.props.filterList.map((data) => {
        data.isActive = false;
        return data;
      });

      this.setState({
        tagList: this.props.tagList,
        filterData: checkBoxStatus,
        searchValue: "",
      });
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props.tagList && this.props.tagList !== previousProps.tagList) {
      this.setState({
        tagList: this.props.tagList,
      });
    }
    if (this.props.type !== previousProps.type) {
      filterTags.length = 0;
      filterDataKeys.length = 0;
      const checkBoxStatus = this.props.filterList.map((data) => {
        data.isActive = false;
        return data;
      });

      this.setState({
        filterData: checkBoxStatus,
        searchValue: "",
      });
    }
    if (this.props.enterKey !== previousProps.enterKey) {
      console.log("Need to api call in enter");
      if (
        this.state.searchValue ||
        this.state.filterTags?.length !== 0 ||
        this.state.filterTags?.length !== 0 ||
        this.state.filterKeys?.length !== 0
      )
        this.findGroups();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className="aboutMyRequestWrapper">
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                paddingBottom: "5px",
              }}
            >
              {t("Filter by community..")}
            </p>
            <div class="d-flex flex-wrap gap-2">
              {this.state.filterKeys &&
                this.state.filterKeys.map((data) => (
                  <Chip
                    style={{
                      overflow: "hidden",
                      Whitespace: "nowrap",
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      maxWidth: "138px",
                    }}
                    avatar={<Avatar>{data.charAt(0).toUpperCase()}</Avatar>}
                    label={data}
                    onDelete={() => this.deleteList(data)}
                  />
                ))}
            </div>

            <hr class="hr-tag" />
            {
              <div className="myRequestTagCard">
                <div
                  className="homePostInputWrapper"
                  style={{ marginTop: "12px" }}
                >
                  <TextField
                    id=""
                    label=""
                    style={{ width: "100%", height: "35px" }}
                    variant="outlined"
                    onChange={(e) => this.searchGroups(e.target.value)}
                    placeholder={t("Search Community...")}
                    value={this.state.searchValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div class="mt-2">
                  {this.state.filterData &&
                    this.state.filterData.length > 0 &&
                    this.state.filterData.map((data, index) => (
                      <div
                        key={index}
                        class="d-flex mt-3"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => this.selectedList(data.key, index)}
                      >
                        <div>
                          <p className={data.isActive ? "bg-color" : ""}>
                            {t(`${data.filerType}`)}
                          </p>{" "}
                        </div>
                        <div class="ml-auto">
                          <input
                            checked={data.isActive}
                            className="react-switch-checkbox"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  style={{ width: "100%", marginTop: "12px" }}
                  onClick={this.findGroups}
                >
                  {t("Search Community")}
                </button>
              </div>
            }
          </div>
          <div className="aboutMyRequestWrapper mt-3">
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                paddingBottom: "5px",
              }}
            >
              {t("Filter by tags..")}
            </p>
            <div class="d-flex flex-wrap gap-2">
              {this.state.filterTags &&
                this.state.filterTags.map((data) => (
                  <Chip
                    style={{
                      overflow: "hidden",
                      Whitespace: "nowrap",
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                      maxWidth: "138px",
                    }}
                    avatar={
                      <Avatar>{data?.tagName?.charAt(0).toUpperCase()}</Avatar>
                    }
                    label={data.tagName}
                    onDelete={() => this.deleteTagList(data.tagId)}
                  />
                ))}
            </div>

            <hr class="hr-tag" />
            {
              <div className="myRequestTagCard">
                {/* <div
                  className="homePostInputWrapper"
                  style={{ marginTop: "12px" }}
                >
                  <TextField
                    id=""
                    label=""
                    style={{ width: "100%", height: "35px" }}
                    variant="outlined"
                    onChange={(e) => this.searchGroups(e.target.value)}
                    placeholder="Search Tags..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div> */}

                <div className="mt-2 community-tag-wrapper">
                  {this.state.tagList &&
                    this.state.tagList.length > 0 &&
                    this.state.tagList.map((data, index) => (
                      <div
                        key={index}
                        class="d-flex mt-3"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.selectedTagList(data.tagId, data.tagName)
                        }
                      >
                        <div>
                          <p className={data.isActive ? "bg-color" : ""}>
                            {data.tagName}
                          </p>{" "}
                        </div>
                      </div>
                    ))}
                  {this.state.tagList && this.state.tagList.length === 0 && (
                    <div class="d-flex justify-content-center">
                      {t("TagList is empty ..")}
                    </div>
                  )}
                </div>
                <button
                  style={{ width: "100%", marginTop: "12px" }}
                  onClick={this.findGroups}
                >
                  {t("Search Tags")}
                </button>
              </div>
            }
          </div>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          style={{ marginBottom: "6rem" }}
        >
          <div className="announcementWrapperOuter">
            <BlockUi
              tag="div"
              message={t("Updating community's . . .")}
              style={this.props.communityLoading ? { minHeight: "90px" } : {}}
              className="wallLoadingPage"
              blocking={this.props.communityLoading}
            >
              <Grid container spacing={2}>
                {this.props.groupList &&
                  this.props.groupList.length > 0 &&
                  this.props.groupList.map((data, index) => (
                    <>
                      {this.props.type === "allForumCommunity" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            key={index}
                          >
                            <GroupsCard
                              type={data.communityTypeName}
                              grouporeventFiles={data.grouporeventFiles}
                              fileType={data.fileType}
                              userId={data.userId}
                              subjectDescription={data.subjectDescription}
                              displayName={data.displayName}
                              subjectId={data.subjectId}
                              tagsValue={data.tagsValue}
                              tagListId={data.tagListId}
                              status={data.status}
                              subjectName={data.subjectName}
                              followCount={data.subjectFollowByUserCount}
                              questionsCount={data.questionsCount}
                              createdDate={data.createdDate}
                              expiryDate={data.expiryDate}
                              weeksCount={data.questionsAskedWeek}
                              lastUpdated={data.lastUpdated}
                              memberShipPrice={data.memberShipPrice}
                              memberShipType={data.memberShipType}
                              membersCount={data.membersCount}
                              event={this.props.event}
                              loginDetails={this.props.loginDetails}
                              startDate={data.startDate}
                              searchData={this.props.searchValue}
                              communityTypeName={data.communityTypeName}
                            />
                          </Grid>
                        </>
                      ) : (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <div
                            key={index}
                            className={
                              this.props.communityLoading
                                ? "skeleton-loader-group"
                                : "peopleInvitationCardWrapper"
                            }
                            style={{
                              borderRadius: "10px",
                              marginBottom: "16px",
                            }}
                            onClick={() =>
                              this.openPage(
                                data.communityTypeName,
                                data.subjectName,
                                data.subjectId
                              )
                            }
                          >
                            <Grid
                              container
                              spacing={2}
                              className={
                                this.props.communityLoading ? "d-none" : ""
                              }
                            >
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div class="community-v2-card">
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      data.fileType === "image"
                                        ? `${APPCONFIG.API_URL}auth/image/${data?.grouporeventFiles[0]?.fileName}`
                                        : dummyimage_banner
                                    }
                                    alt="user"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = dummyimage_banner;
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                item
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                style={{ wordBreak: "break-all" }}
                              >
                                {" "}
                                <h6 className="font-weight-bold text-overflow-v2 mb-1">
                                  {data.subjectName}
                                </h6>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "gray",
                                  }}
                                >
                                  {data.communityTypeName}
                                </p>
                                <div class="d-flex">
                                  <MdOutlineGroups
                                    style={{
                                      width: "20px",
                                      height: "24px",
                                      color: "#A00030",
                                      paddingBottom: "5px",
                                    }}
                                  />{" "}
                                  {data.membersCount <= 1 ? (
                                    <p className="memberCount">
                                      {data.membersCount} {t("Member")}
                                    </p>
                                  ) : (
                                    <p className="memberCount">
                                      {data.membersCount} {t("Members")}
                                    </p>
                                  )}
                                </div>
                              </Grid>
                              {/* <Grid item lg={2} md={2} sm={12} xs={12}>
                          <button style={{ float: "right" }}>Apply</button>
                        </Grid> */}
                            </Grid>
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}{" "}
              </Grid>
            </BlockUi>

            {!this.props.communityLoading &&
              this.props.searchListPage > 0 &&
              this.props.groupList.length !== 0 &&
              this.props.searchListPage - 1 != this.props.pageNo && (
                <div class="d-flex justify-content-center mt-4">
                  <button onClick={this.onCallingSeeMore}>
                    {t("See more")}
                  </button>
                </div>
              )}
            {!this.props.communityLoading &&
              this.props.groupList.length === 0 && (
                <div className="aboutMyRequestWrapper mt-4">
                  <p style={{ textAlign: "center" }}>
                    {t("No more community available")}
                  </p>
                </div>
              )}
          </div>
        </Grid>
      </Grid>
    );
  }
  findGroups = () => {
    let tagData =
      this.state.filterTags &&
      this.state.filterTags.map((data) => {
        return data.tagId;
      });
    let data = {
      page: 0,
      searchKey: this.state.searchValue,
      filterKeys: this.state.filterKeys,
      tagIds: tagData,
      type: this.props.type,
    };
    this.props.getMyCommunityList(data, false);
    this.props.removePageNumber();
    console.log("data", data);
  };
  onCallingSeeMore = () => {
    let tagData =
      this.state.filterTags &&
      this.state.filterTags.map((data) => {
        return data.tagId;
      });
    this.props.onCallingSeeMore(
      this.state.searchValue,
      this.state.filterKeys,
      tagData
    );
  };
  openPage = (type, subjectName, id) => {
    var gName = subjectName.trim();
    gName = gName.replace(/[, ]+/g, "-").trim().toLowerCase();
    if (type === "Forum Community") {
      this.props.history.push(
        `/${this.props.workspaceType}/forum-community/${id}/${gName}`,
        { from: window.location.pathname }
      );
    } else {
      this.props.history.push(
        `/${this.props.workspaceType}/groupDescription/${id}/${gName}`
      );
    }
  };

  searchGroups = (e) => {
    this.setState({
      searchValue: e,
    });
  };
  deleteTagList = async (key) => {
    let stateData = this.state.tagList;
    if (this.state.filterTags.length > 0) {
      filterTags = this.state.filterTags.filter(
        (element) => element.tagId !== key
      );
      const checkBoxStatus = stateData.forEach((data) => {
        if (data.tagId === key) {
          return (data.isActive = false);
        }
      });

      await this.setState({ filterTags: filterTags });
      this.findGroups();
    }
  };
  deleteList = async (key) => {
    let stateData = this.state.filterData;
    if (this.state.filterKeys.length > 0) {
      filterDataKeys = this.state.filterKeys.filter(
        (element) => element !== key
      );
      const checkBoxStatus = stateData.forEach((data) => {
        if (data.key === key) {
          return (data.isActive = false);
        }
      });
      await this.setState({ filterKeys: filterDataKeys });
      this.findGroups();
    }
  };
  selectedList = (key, index) => {
    let count = this.state.filterKeys.length;
    let stateData = this.state.filterData;
    if (this.state.filterKeys.length > 0) {
      filterDataKeys = this.state.filterKeys.filter(
        (element) => element !== key
      );
      const checkBoxStatus = stateData.forEach((data) => {
        if (data.key === key) {
          return (data.isActive = false);
        }
      });

      this.setState({ filterKeys: filterDataKeys });
    }
    if (count == filterDataKeys.length) {
      filterDataKeys.push(key);
      this.setState({ filterKeys: filterDataKeys });
      const checkBoxStatus = stateData.map((data) => {
        if (data.key === key) {
          data.isActive = true;
        }
        return data;
      });

      this.setState({ filterKeys: filterDataKeys });
    }
  };
  selectedTagList = (id, tagName) => {
    let count = this.state.filterTags.length;
    let stateData = this.state.tagList;
    if (this.state.filterTags.length > 0) {
      filterTags = this.state.filterTags.filter(
        (element) => element.tagId !== id
      );
      const checkBoxStatus = stateData.forEach((data) => {
        if (data.tagId === id) {
          return (data.isActive = false);
        }
      });

      this.setState({ filterTags: filterTags });
    }
    if (count == filterTags.length) {
      filterTags.push({ tagId: id, tagName: tagName });
      this.setState({ filterTags: filterTags });
      const checkBoxStatus = stateData.map((data) => {
        if (data.tagId === id) {
          data.isActive = true;
        }
        return data;
      });
      console.log("Filter key", checkBoxStatus);
      this.setState({ filterTags: filterTags, tagList: checkBoxStatus });
    }

    // console.log("Filter key", this.state.filterData);
  };
}
const mapStateToProps = function (state) {
  return {
    tagList: state.subjects.communityTags,
    communityLoading: state.subjects.communityLoading,
    loginDetails: state.loginDetailsApi.loginDetails,
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    getMyCommunityList: (data, page) =>
      dispatch(getMyCommunityList(data, page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(MyCommunityCard)));
