import React, { Component } from "react";
import BgBanner from "./BgBanner";
import Grid from "@material-ui/core/Grid";
import FriendsCard from "./FriendsCard";
import UserInfo from "./UserInfo";
import Container from "@material-ui/core/Container";
import AuthService from "../../../session/AuthService";
import FriendsFinder from "../../../api/FriendsFinder";
import ProfileService from "../../../api/ProfileService";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as APPCONFIG from "../../../config/app-config.js";
import dummyimage from "../../../img/default_avatar.jpg";
import { storePersonalLanguage } from "../../../redux/actions/profileActions";
import { storeLoginDetails } from "../../../redux/actions/loginDetailsActions";
import banner from "../../../img/banner.jpg";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router";
import TextField from "@material-ui/core/TextField";
import { AiOutlineEdit } from "react-icons/ai";
import BlockUi from "react-block-ui";
import valueMatch from "../../../img/valueMatch.png";
import skillPic from "../../../img/skill.png";
import educationPic from "../../../img/education.png";
import workExperiencePic from "../../../img/workExperience.png";
import profilePic from "../../../img/profile.png";
import { IoSettingsOutline } from "react-icons/io5";
import { Card, CardContent } from "@material-ui/core";
import MyCommunity from "../HomeUpdated/MyCommunity";
let newSkills = new Array(2);
class SkillsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      nameValue: false,
      experienceValue: false,
      editskillExperience: "",
      editskillName: "",
      edituserId: "",
      tabIndex: 0,
      friendsList: [],
      userKnowledgepoint: "",
      coverPhotoImage: "",
      savebtnState: false,
      inputs: [1],
      saveButtonEnable: false,
      frSkillName: [],
      industrysList: [],
      preference: "",

      editSkills: false,
      editSkillsDetails: false,
      skillDetails: [],
      dynamicLangArray: [],
    };

    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getProfilePhoto = this.getProfilePhoto.bind(this);
    this.getUserCoverPhoto = this.getUserCoverPhoto.bind(this);
    this.getKnowledgePoints = this.getKnowledgePoints.bind(this);
    this.getUpdatedFriendsList = this.getUpdatedFriendsList.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.getCoverPhotos = this.getCoverPhotos.bind(this);
    this.getFriendsList = this.getFriendsList.bind(this);
    this.getallHobbies = this.getallHobbies.bind(this);
    this.getallGrade = this.getallGrade.bind(this);
    this.gotoprofession = this.gotoprofession.bind(this);
    this.gotoeducation = this.gotoeducation.bind(this);
    this.gotoprofile = this.gotoprofile.bind(this);
    this.gotoskills = this.gotoskills.bind(this);
    this.addTranslation = this.addTranslation.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.saveSkills = this.saveSkills.bind(this);
    this.backSkills = this.backSkills.bind(this);
    this.editSkillDetails = this.editSkillDetails.bind(this);
    this.editSkillsList = this.editSkillsList.bind(this);
    this.gotoBusinessProfile = this.gotoBusinessProfile.bind(this);
    this.gotoValueMatch = this.gotoValueMatch.bind(this);
    this.deleteSkillDetails = this.deleteSkillDetails.bind(this);
  }

  componentDidMount() {
    this.setState({ preference: this.props.loginDetails.pLanguage });
    if (
      this.props.loginDetails.profession_Id == 3 ||
      this.props.loginDetails.profession_Id == 4
    ) {
      this.setState({
        businessProfile: true,
      });
    } else {
      this.setState({
        businessProfile: false,
      });
    }
    //  this.getSkillsDetails();
    //this.getKnowledgePoints();

    const data = this.props.loginDetails.profile_uuid;
    const profileImage = data
      ? `${APPCONFIG.API_URL}auth/image/${data}`
      : dummyimage;
    console.log("friends****", this.props.FriendsList);
    this.setState({ userPhotoImage: profileImage });
    if (this.props.loginDetails.hasOwnProperty("cPhoto")) {
      this.setState({
        coverPhotoImage: banner,
      });
    } else {
      this.getCoverPhotos(this.props.loginDetails.cover_uuid);
    }
    let langValue = AuthService.getCommonLanguages();
    i18n.changeLanguage(langValue);

    this.setState({
      friendsList: AuthService.getFriendsList(),
    });
  }

  addTranslation = (e) => {
    if (!this.state.nameValue || !this.state.experienceValue) {
      toast.warn("Fill all the field");
    } else {
      var input = this.state.inputs;
      input.push(1);
      this.setState({
        inputs: input,
        nameValue: false,
        experienceValue: false,
      });
    }
  };

  initfunction() {
    this.getallHobbies();
    this.getallGrade();
    this.getFriendsList();
  }
  handleChangeTab(event, newIndex) {
    this.setState({
      tabIndex: newIndex,
      showAskQuestion: false,
    });
  }

  async getCoverPhotos(coverPhoto) {
    console.log("coverPhotoImage");

    if (this.props.loginDetails.cover_uuid != "") {
      console.log("entering if");
      await this.setState({
        coverPhotoImage: `${APPCONFIG.API_URL}auth/image/${coverPhoto}`,
      });
      console.log(this.state.coverPhotoImage);
    } else {
      console.log("entering else");
      await this.setState({ coverPhotoImage: banner });
      console.log(this.state.coverPhotoImage);
    }
  }
  render() {
    const { t } = this.props;
    return (
      <div className={this.props.firstTime ? "" : "screenInnerGap"}>
        <Container>
          <ToastContainer position="top-right" autoClose={5000} />
          {/* <Card>
          <CardContent> */}
          <div className="profileMainWrapper">
            {!this.props.firstTime && (
              <h2 className="pageHeading" style={{ marginTop: "40px" }}>
                {t("Profile")}
              </h2>
            )}
            <div className="profileWrapper">
              {!this.props.firstTime && (
                <BgBanner
                  coverPhoto={this.state.coverPhotoImage}
                  profilePhoto={this.state.userPhotoImage}
                  userName={"User name"}
                  getUserPhoto={this.getProfilePhoto}
                  getCoverPhoto={this.getUserCoverPhoto}
                />
              )}
              {!this.props.firstTime && (
                <Grid container spacing={2} className="userDetailsWrapper">
                  <Grid item md={12}>
                    <UserInfo
                      name={this.props.loginDetails.dName}
                      designation={this.props.loginDetails.biography}
                      additionalInfo={this.props.loginDetails.hsTag}
                      pointsCount={this.state.userKnowledgepoint}
                      loginDetails={this.props.loginDetails}
                    />
                  </Grid>
                </Grid>
              )}

              {!this.props.firstTime && (
                <div className="tabWrapper">
                  <div className=" ">
                    <Container>
                      <div className="flexDefault  ">
                        <Tabs
                          TabIndicatorProps={{
                            style: { background: "#A00030" },
                          }}
                          value={this.state.tabIndex}
                          // indicatorColor="primary"
                          textColor="primary"
                          onChange={this.handleChangeTab}
                          aria-label="disabled tabs example"
                        >
                          <Tab label={t("PROFILE")} />
                          <Tab label={t("FRIENDS")} />
                          <Tab label={t("BLOCKED USER")} />
                          <Tab label={t("FEED")} />
                          <Tab label={t("BOOKMARKED POSTS")} />
                        </Tabs>
                      </div>
                    </Container>
                  </div>
                  <div className="profilepageWrapperProfileSetup">
                    {this.state.tabIndex === 0 ? (
                      <div>
                        <Grid container spacing={0}>
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <div className="">
                              <Grid container spacing={1}>
                                <Grid item md={3} xs={12} sm={12} lg={3}>
                                  <div className="profileselectCard">
                                    <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoprofile}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          color: "#000000",
                                          // fontSize: "15px",
                                        }}
                                      >
                                        <img
                                          src={profilePic}
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "11px",
                                            width: "34px",
                                            height: "34px",
                                          }}
                                        />
                                        {t("Profile")}
                                      </p>
                                    </div>
                                    <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoValueMatch}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          color: "#000000",
                                          // fontSize: "15px",
                                        }}
                                      >
                                        <img
                                          src={valueMatch}
                                          style={{
                                            cursor: "pointer",

                                            marginRight: "7px",
                                            width: "34px",
                                            height: "34px",
                                          }}
                                        />{" "}
                                        {t("Value Match")}
                                      </p>
                                    </div>
                                    <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoeducation}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          color: "#000000",
                                          // fontSize: "15px",
                                        }}
                                      >
                                        <img
                                          src={educationPic}
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "8px",
                                            width: "34px",
                                            height: "34px",
                                          }}
                                        />{" "}
                                        {t("Education")}
                                      </p>
                                    </div>
                                    <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoskills}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          color: "#000000",
                                          // fontSize: "15px",
                                        }}
                                      >
                                        <img
                                          src={skillPic}
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "13px",
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                        {t("Skills")}
                                      </p>
                                    </div>
                                    <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoprofession}
                                    >
                                      <p
                                        className=""
                                        style={{
                                          color: "#000000",
                                          // fontSize: "15px",
                                        }}
                                      >
                                        <img
                                          src={workExperiencePic}
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "14px",
                                            width: "29px",
                                            height: "29px",
                                          }}
                                        />
                                        {t("Work Experience")}
                                      </p>
                                    </div>
                                    <div
                                      className="navHomeProfile"
                                      tabindex="1"
                                      onClick={this.gotoPreference}
                                    >
                                      <p style={{ color: "#000000" }}>
                                        <IoSettingsOutline
                                          style={{
                                            width: "26px",
                                            height: "26px",
                                            marginRight: "17px",
                                            color: "#A00030",
                                          }}
                                        />

                                        {t("Preferences")}
                                      </p>
                                    </div>
                                    {/* {this.state.businessProfile && (
                                      <div
                                        className="navHomeProfession"
                                        tabindex="1"
                                        onClick={this.gotoBusinessProfile}
                                      >
                                        <p
                                          className=""
                                          style={{
                                            color: "#000000",
                                            fontSize: "15px",
                                          }}
                                        >
                                          <FcViewDetails
                                            style={{
                                              marginRight: "8px",
                                              width: "22px",
                                              height: "22px",
                                              color: "#000000",
                                            }}
                                          />
                                          {t("Business Profile")}
                                        </p>
                                      </div>
                                    )} */}
                                  </div>
                                </Grid>

                                <Grid item md={9} xs={12} sm={12} lg={9}>
                                  {!this.state.editSkills &&
                                    !this.state.editSkillsDetails && (
                                      <>
                                        <div className="profileselectCardMobile">
                                          <Grid container spacing={2}>
                                            <Grid item md={6} sm={12} xs={12}>
                                              <h5
                                                style={{
                                                  color: "#000000",
                                                  //padding: "8px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {t("Preferences Details")}
                                              </h5>
                                            </Grid>

                                            <Grid item md={6} sm={12} xs={12}>
                                              <div
                                                className=""
                                                style={{ float: "right" }}
                                              >
                                                <button
                                                  onClick={this.editProfile}
                                                >
                                                  <AiOutlineEdit
                                                    style={{
                                                      cursor: "pointer",
                                                      width: "25px",
                                                      height: "25px",
                                                    }}
                                                  />
                                                  {t("Edit Preference")}{" "}
                                                </button>
                                              </div>
                                            </Grid>
                                          </Grid>
                                          <div
                                            className="prefferenceCard"
                                            class="mt-2"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid
                                                item
                                                md={3}
                                                lg={3}
                                                sm={3}
                                                xs={6}
                                              >
                                                {t("Preference")} :
                                              </Grid>
                                              <Grid
                                                item
                                                md={3}
                                                lg={3}
                                                sm={3}
                                                xs={6}
                                              >
                                                <p>
                                                  {this.state.preference === ""
                                                    ? "English"
                                                    : this.state.preference}
                                                </p>
                                              </Grid>{" "}
                                            </Grid>{" "}
                                          </div>{" "}
                                        </div>
                                      </>
                                    )}
                                  {this.state.editSkills && (
                                    <>
                                      <div className="profileselectCard">
                                        <Grid item md={12} sm={12} xs={12}>
                                          <div
                                            className=""
                                            style={{
                                              float: "left",
                                              //  margin: "20px",
                                            }}
                                          >
                                            <button onClick={this.backSkills}>
                                              {t("Back")}
                                            </button>
                                          </div>
                                        </Grid>

                                        <BlockUi
                                          tag="div"
                                          blocking={this.state.blocking}
                                          message={t("Loading, please wait")}
                                          color="#A00030"
                                        >
                                          <Grid container spacing={0}>
                                            <Grid
                                              item
                                              md={12}
                                              xs={12}
                                              sm={12}
                                              lg={12}
                                            >
                                              <h6>{t("Preferences")}</h6>

                                              <Grid container spacing={3}>
                                                <Grid
                                                  item
                                                  md={6}
                                                  xs={6}
                                                  sm={6}
                                                  lg={8}
                                                >
                                                  <TextField
                                                    id="dropdown"
                                                    select
                                                    label=""
                                                    value={
                                                      this.state.preference
                                                    }
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    onChange={this.handleForm}
                                                    name="preference"
                                                  >
                                                    <MenuItem value="">
                                                      <>{t("Select")}</>
                                                    </MenuItem>
                                                    <MenuItem value="English">
                                                      <>{t("English")}</>
                                                    </MenuItem>
                                                    <MenuItem value="Tamil">
                                                      <>{t("Tamil")}</>
                                                    </MenuItem>
                                                  </TextField>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md={6}
                                                  xs={6}
                                                  sm={6}
                                                  lg={4}
                                                >
                                                  {
                                                    <button
                                                      style={{
                                                        borderRadius: "10px",
                                                        padding: "8px",
                                                      }}
                                                      onClick={this.savePrefer}
                                                    >
                                                      {t("Save")}
                                                    </button>
                                                  }
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </BlockUi>
                                      </div>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ) : this.state.tabIndex === 1 ? (
                      <div>
                        <Grid
                          container
                          spacing={2}
                          className="userDetailsWrapper-friend"
                        >
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <p
                              className="boldLabel"
                              style={{ marginBottom: 10 }}
                            >
                              {t("Friends")}
                            </p>
                          </Grid>
                          {this.props.FriendsList &&
                            this.props.FriendsList.map((data) => (
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <FriendsCard
                                  friendId={data.userId}
                                  fUserId={data.userProfile.userId}
                                  image={data.profilePhoto}
                                  name={`${data.userProfile.firstName} ${data.userProfile.lastName}`}
                                  Dname={data.userProfile.displayName}
                                  smallDescription={data.userProfile.biography}
                                  unfriendHits={this.getUpdatedFriendsList}
                                />
                              </Grid>
                            ))}
                          {this.props.FriendsList.length === 0 && (
                            <Card>
                              <CardContent>
                                <center>
                                  <p>{t("No friends available")}</p>
                                </center>
                              </CardContent>
                            </Card>
                          )}{" "}
                        </Grid>
                      </div>
                    ) : this.state.tabIndex === 2 ? (
                      <div>
                        <Grid
                          container
                          spacing={2}
                          className="userDetailsWrapper-friend"
                        >
                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <p
                              className="boldLabel"
                              style={{ marginBottom: 10 }}
                            >
                              {t("Blocked User")}
                            </p>{" "}
                          </Grid>
                          {this.props.blockedUserList &&
                            this.props.blockedUserList.map((data) => (
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <FriendsCard
                                  tabIndex={"BlockedUser"}
                                  friendId={data.blockUserId}
                                  fUserId={data.blockUserId}
                                  image={data.profilePhoto}
                                  name={data.userName}
                                  Dname={data.userName}
                                  // smallDescription={data.userProfile.biography}
                                  unfriendHits={this.getUpdatedFriendsList}
                                />
                              </Grid>
                            ))}
                          {this.props.blockedUserList &&
                            this.props.blockedUserList.length === 0 && (
                              <Card>
                                <CardContent>
                                  <center>
                                    <p>{t("No More Blocked List Available")}</p>
                                  </center>
                                </CardContent>
                              </Card>
                            )}{" "}
                        </Grid>
                      </div>
                    ) : (
                      (this.state.tabIndex === 3 ||
                        this.state.tabIndex === 4) && (
                        <div style={{ marginTop: "5px" }}>
                          <Grid container spacing={3}>
                            <Grid item md={12} xs={12} sm={12} lg={12}>
                              <MyCommunity
                                type={
                                  this.state.tabIndex === 3
                                    ? "Feed"
                                    : "BookMark"
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
  getIndustry() {
    // alert()
    var industryList = this.props.industryList;
    console.log("loginDetails", this.props.loginDetails);
    console.log("industryList", industryList);
    if (industryList) {
      var lList = [];
      for (var i = 0; i < industryList.length; i++) {
        var obj = {};
        obj["id"] = industryList[i].industryId;
        obj["name"] = industryList[i].industryName;
        lList.push(obj);
      }

      this.setState({ industrysList: lList });
      console.log("industrysList:", lList);
    }
    this.getEducationDetails(lList);
  }
  getEducationDetails() {
    var saveEducation = {};
    ProfileService.getWorkExperienceDetails(saveEducation).then((response) => {
      var result = response.data;

      if (response.data.status === 1) {
        this.setState({
          //   educationDetails: response.data.data.workExperience,
        });
        var educationData = response.data.data.workExperience;

        for (var j = 0; j < this.state.industrysList.length; j++) {
          for (var i = 0; i < educationData.length; i++) {
            if (this.state.industrysList[j].id === educationData[i].industry) {
              if (educationData[i].industry === 18) {
                this.setState({
                  businessProfile: true,
                });
              } else {
                this.setState({
                  businessProfile: false,
                });
              }
            }
          }
        }
      }
    });
  }
  editSkillDetails(editSkillDetails) {
    var educationData = [];
    console.log("edit", this.state.skillDetails);
    this.setState({ editSkillsDetails: true });
    for (var i = 0; i < this.state.skillDetails.length; i++) {
      if (this.state.skillDetails[i].id == editSkillDetails) {
        console.log("Education", this.state.skillDetails[i].userId);
        this.setState({
          editId: this.state.skillDetails[i].id,
          editskillExperience: this.state.skillDetails[i].skillName,
          editskillName: this.state.skillDetails[i].experience,
          edituserId: this.state.skillDetails[i].userId,
        });
      }
    }
  }

  handleForm(event) {
    //if (/^[A-Za-z]+$/.test(event.target.value) || event.target.value == "") {
    this.setState({ [event.target.name]: event.target.value });
    // }
  }

  handleClickEdit() {
    this.setState({ savebtnState: false });
  }

  gotoprofession() {
    this.props.history.push("/my-profession");
  }
  gotoValueMatch() {
    this.props.history.push("/value-match");
  }
  gotoeducation() {
    this.props.history.push("/my-education");
  }

  gotoprofile() {
    this.props.history.push("/my-profile");
  }
  gotoBusinessProfile() {
    this.props.history.push("my-business-profile");
  }
  gotoskills() {
    this.props.history.push("/my-skills");
  }
  savePrefer = (event) => {
    event.preventDefault();
    event.preventDefault();
    event.stopPropagation();
    if (this.state.preference === "") {
      toast.warning("Please select any one of the language");
    } else {
      const formData = new FormData();
      formData.append("personalLanguage", this.state.preference);

      const config = { headers: { "content-type": "multipart/form-data" } };
      ProfileService.userPersonalLanguageSave(formData, config)
        .then((response) => {
          var result = response.data;
          toast.success("Saved successfully");
          AuthService.registerAuth("", result.data);

          this.props.storeLoginDetails(
            result.data,
            this.props.authUser.loginType
          );
          this.changeLanguage(result.data.pLanguage);
          this.setState({
            editSkills: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false, editSkills: false });
          console.log(error);
        });
    }
  };

  changeLanguage(lang) {
    i18n.changeLanguage(lang);

    if (lang === "English") {
      AuthService.storeCommonLanguages("en");
      this.props.storePersonalLanguage("en");
      //  this.props.getAllCommonSubjectList(false);
      this.props.getAllCommonCurriculumTrackList(false);

      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else if (lang === "French") {
      AuthService.storeCommonLanguages("fr");
      this.props.storePersonalLanguage("fr");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else if (lang === "Luxembourgish") {
      AuthService.storeCommonLanguages("lx");
      this.props.storePersonalLanguage("lx");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else if (lang === "Tamil") {
      AuthService.storeCommonLanguages("ta");
      this.props.storePersonalLanguage("ta");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    } else {
      AuthService.storeCommonLanguages("en");
      this.props.storePersonalLanguage("en");
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
    }
  }

  saveSkills() {
    if (this.state.nameValue && this.state.experienceValue) {
      this.setState({ blocking: true });
      var saveSkill = {
        education: this.state.frSkillName,
        firstTimeSetup: "true",
      };
      ProfileService.saveSkillDetails(saveSkill)
        .then((response) => {
          var result = response.data;

          var data = [];
          console.log(this.state.frSkillName);
          for (let i = 0; i < this.state.frSkillName.length; i++) {
            this.setState({ frSkillName: data, editSkills: false });
            this.getSkillsDetails();
          }
          this.setState({ blocking: false });
          toast.success("Saved successfully");
        })
        .catch((error) => {
          this.setState({ blocking: false });
          console.log(error);
        });
    } else {
      toast.warn("Fill all the field");
    }
  }

  getUpdatedFriendsList() {
    let getFriendsList = {};
    FriendsFinder.getFriendsList(getFriendsList)
      .then((response) => {
        var result = response.data;
        this.setState({
          friendsList: result.data.friendsList,
        });
        AuthService.storeFriendsList(result.data.friendsList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProfilePhoto(userPhoto) {
    this.setState({ userPhotoImage: `data:image/jpeg;base64,${userPhoto}` });
  }
  getUserCoverPhoto(coverPhoto) {
    this.setState({
      coverPhotoImage: `data:image/jpeg;base64,${coverPhoto}`,
      coverPhotoImageState: true,
    });
  }

  async getKnowledgePoints() {
    let getKnowledgePoints = { userId: this.props.loginDetails.uId };
    var response = ProfileService.getKPCache(getKnowledgePoints);
    if (response) {
      var result = response.data;
      if (result.data === null) {
        this.setState({ userKnowledgepoint: 0 });
      } else {
        this.setState({ userKnowledgepoint: result.data });
      }
    } else {
      ProfileService.getKnowledgePointByUser(getKnowledgePoints)
        .then((response) => {
          var result = response.data;
          if (result.data === null) {
            this.setState({ userKnowledgepoint: 0 });
          } else {
            ProfileService.setKPCache(getKnowledgePoints, response);
            this.setState({ userKnowledgepoint: result.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getallHobbies() {
    ProfileService.getallHobbies()
      .then((response) => {
        console.log(response);
        var result = response.data;
        AuthService.storeHobbiesList(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getallGrade() {
    ProfileService.getAllGrades()
      .then((response) => {
        var result = response.data;
        AuthService.storeGradeList(result.data.grade);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getFriendsList() {
    var friend = AuthService.getFriendsList();
    this.setState({
      FriendsList: friend,
    });
  }

  editProfile = () => {
    this.setState({ editSkills: true });
  };

  backSkills() {
    this.setState({
      editSkills: false,
      editSkillsDetails: false,
      frSkillName: [],
      inputs: [1],
    });
  }
  editSkillsList() {
    if (
      this.state.editskillName == "" ||
      this.state.editskillExperience == ""
    ) {
      toast.warning("Please fill the all Filed..");
    } else {
      this.setState({ blocking: true });
      var saveSkills = {
        editId: this.state.editId,
        experience: this.state.editskillName,
        skillName: this.state.editskillExperience.trim(),
      };
      ProfileService.editSkillsList(saveSkills)
        .then((response) => {
          var result = response.data;
          var data = [];
          this.getSkillsDetails();
          toast.success(`${this.props.t("Updated Successfully")}`);
          this.setState({ blocking: false });
          this.setState({
            editSkillsDetails: false,
          });
        })
        .catch((error) => {
          this.setState({ blocking: false });
          console.log(error);
        });
    }
  }

  deleteSkillDetails(id) {
    var data = {
      deleteId: id,
    };
    ProfileService.deleteSkillDetails(data)
      .then((response) => {
        toast.success("Deleted successfully");
        console.log(response);
        this.getSkillsDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

const mapStateToProps = function (state) {
  console.log("Loading Notification Props");
  console.log(state.loginDetailsApi.loginDetails);

  return {
    subjectsList: state.commonApi.subjectslist,
    languageList: state.commonApi.languagelist,
    tagList: state.commonApi.taglist,
    curriculumtrackList: state.commonApi.curriculumtracklist,
    schoolList: state.commonApi.schoollist,
    FriendsList: state.commonApi.friendslist,
    loginDetails: state.loginDetailsApi.loginDetails,
    industryList: state.commonApi.industrylist.data,
    authUser: state.loginDetailsApi.authUser,
    blockedUserList: state.commonApi.blockedUserList,
  };
};

const mapDispacthToProps = (dispatch) => {
  return {
    storePersonalLanguage: (language) =>
      dispatch(storePersonalLanguage(language)),
    storeLoginDetails: (loginDetails, type) =>
      dispatch(storeLoginDetails(loginDetails, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withTranslation()(withRouter(SkillsContainer)));
