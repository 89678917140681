import React, { Component } from "react";
import UserImg from "../UserImg";
import moment from "moment";
import { withRouter } from "react-router";
import ProfileService from "../../api/ProfileService";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ToastContainer, toast } from "react-toastify";
import ForumService from "../../api/ForumService";
import { connect } from "react-redux";
import { getAdvertisementData } from "../../redux/actions/squareHomeActions";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { API_URL } from "../../config/app-config";
import { setSelectedUserAction } from "../../redux/actions/chatActions";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
class NotificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userImg: "",
      collapseMenu: false,
    };

    this.deleteNotification = this.deleteNotification.bind(this);
    this.directNotification = this.directNotification.bind(this);
    this.getBase64String = this.getBase64String.bind(this);
  }

  componentDidMount() {
    // this.getBase64String(this.props.nSenderId);
  }
  componentDidUpdate(previousDay) {
    if (this.props.tabIndex !== previousDay.tabIndex) {
      this.setState({
        collapseMenu: false,
      });
    }
  }

  render() {
    const {
      userName,
      type,
      description,
      time,
      isNotAnswered,
      notificationType,
      notificationId,
      dashBoardType,
      questionId,
      url,
      data,
      status,
    } = this.props;

    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="notificationCard flexDefault flexAlignCenter flexJustifySpacebetween">
          <div class="d-flex">
            <UserImg
              size="small"
              imgSrc={
                API_URL +
                "api/getUserPhoto/" +
                this.props.nSenderId +
                "/" +
                "name"
              }
              // imgSrc={`${API_URL}auth/image/${this.props.nSenderId}`}
              imgAlt={userName}
              onClickImg={this.onClickImg}
            />
            {/* <div className="headerIcon">
            <p>{this.props.notificationLength}</p>
          </div> */}
            <span
              class="badge badge-pill  badge-info"
              style={{ maxHeight: "1.2rem" }}
            >
              {this.props.notificationLength}
            </span>
          </div>
          <div
            className="notificationCardUserInfo"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.directNotification(
                notificationType,
                notificationId,
                dashBoardType,
                questionId,
                type,
                data,
                status,
                this.props.postDetail
              )
            }
          >
            <div>
              <p className="NCUserName">{userName}</p>
            </div>
            <p className="NCDescription">{description}</p>
            {type === "instantMeeting" && (
              <div>
                <a href={url} target="_blank">
                  {this.props.url}
                </a>
              </div>
            )}
            <p className="NCTimeGray">
              {moment(time).format("DD/MM/yy , hh mm A")}
            </p>
          </div>
          <div className="NCTime">
            {this.props.notificationDetail?.length > 1 ? (
              <>
                <MdOutlineKeyboardArrowDown
                  className={
                    !this.state.collapseMenu
                      ? "down-arrow svg-icons text-secondary"
                      : "svg-icons text-secondary"
                  }
                  onClick={() =>
                    this.setState({ collapseMenu: !this.state.collapseMenu })
                  }
                />
              </>
            ) : (
              <div
                className="notify-action-icon"
                onClick={() => this.deleteProcess(data, true)}
              >
                <HighlightOffIcon />
              </div>
            )}
          </div>
        </div>
        <div className="p-2">
          {this.state.collapseMenu &&
            this.props.notificationDetail?.length > 1 &&
            this.props.data.map((item) => (
              <div className="notification-card notificationCard flexDefault flexAlignCenter flexJustifySpacebetween mb-2">
                <div class="d-flex">
                  <UserImg
                    size="small"
                    imgSrc={
                      API_URL +
                      "api/getUserPhoto/" +
                      item.notificationSenderId +
                      "/" +
                      "name"
                    }
                    // imgSrc={`${API_URL}auth/image/${this.props.nSenderId}`}
                    imgAlt={item.status}
                    onClickImg={this.onClickImg}
                  />
                  {/* <div className="headerIcon">
                  <p>{this.props.notificationLength}</p>
                </div> */}
                </div>
                <div
                  className="notificationCardUserInfo"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.directNotification(
                      item.notificationType,
                      item.notificationId,
                      item.type,
                      item.questionId,
                      item.type,
                      [item],
                      item.status,
                      item.data
                    )
                  }
                >
                  <div>{/* <p className="NCUserName">{item.status}</p> */}</div>
                  <p className="NCDescription">{description}</p>
                  {type === "instantMeeting" && (
                    <div>
                      <a href={url} target="_blank">
                        {this.props.url}
                      </a>
                    </div>
                  )}
                  <p className="NCTimeGray">
                    {moment(item.createdDate).format("DD/MM/yy , hh mm A")}
                  </p>
                </div>
                <div className="NCTime">
                  <div
                    className="notify-action-icon text-secondary mr-1"
                    onClick={() => this.deleteProcess([item], true)}
                  >
                    <HighlightOffIcon />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </>
    );
  }
  onClickImg = () => {};

  deleteNotification(typeid, notificationId, dashhBoardType, question_id) {
    let deleteNotification = {
      notificationId: notificationId,
    };
    ProfileService.deleteNotification(deleteNotification)
      .then((response) => {
        var result = response.data;

        this.props.deletesingleNotifications(notificationId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteCustomNotification = (notificationId, objectArray, nothide) => {
    let deleteNotification = {
      customNotificationId: notificationId,
    };
    ProfileService.custom_delete_notification(deleteNotification)
      .then((response) => {
        if (!nothide) {
          this.props.handleClick();
        }

        this.props.deleteCustomNotification(objectArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  directNotification(
    typeid,
    notificationId,
    dashBoardType,
    question_id,
    type,
    data,
    status,
    postDetail
  ) {
    if (data.length > 1) {
      this.setState({ collapseMenu: !this.state.collapseMenu });
    } else {
      console.log("totalList profileDetails", postDetail);
      console.log("totalList profileDetails typeid", typeid);
      console.log("totalList profileDetails dashBoardType", dashBoardType);
      console.log("totalList profileDetails status", status);

      var path = "search";

      if (typeid === 9) {
        let username = this.props.notification
          ? this.props.notification.split(" ")[0]
          : "";
        var userData = {
          userId: this.props.nSenderId,
          userName: username,
          userType: 1,
          profilePhoto: "",
        };
        console.log("userData", userData);
        this.props.setSelectedUserAction(userData);
        this.deleteProcess(data);
        // path = `/${this.props.workspaceType}/mynetwork`;
        // path = `/friendDescription/${this.props.nSenderId}`;
      } else {
        if (
          typeid === 3 &&
          (dashBoardType === "public" ||
            dashBoardType === "group" ||
            dashBoardType === "mentor") &&
          status === "calendar"
        ) {
          path = `/${this.props.workspaceType}/calendar`;
        } else if (typeid === 3 && dashBoardType === "mentor") {
          path = `/${this.props.workspaceType}/calendar`;
        } else if (typeid === 10) {
          if (postDetail) {
            let id = JSON.parse(postDetail);
            // this.props.getAdvertisementData(id.postId, id.typeData);
            path = `/${this.props.workspaceType}/advertisement/${id.postId}/${id.typeData}`;
          }
        } else if (
          typeid === 3 &&
          (dashBoardType === "post_subscribe" ||
            dashBoardType === "post_subscribe_info" ||
            dashBoardType === "private_post_share" ||
            dashBoardType === "private_post_mention")
        ) {
          if (Object.keys(postDetail).length !== 0) {
            let data = JSON.parse(postDetail);
            path = `/${this.props.workspaceType}/userPost/${data.postId}/${data.typeData}`;
          } else {
            path = `/${this.props.workspaceType}/home`;
          }
        } else if (
          typeid === 3 &&
          (dashBoardType ===
            "private-forum-community-answer-and-reply-mention" ||
            dashBoardType ===
              "private-forum-community-answer-and-reply-mention" ||
            dashBoardType === "private-forum-community-question-mention" ||
            dashBoardType === "community_subscribe_info")
        ) {
          if (Object.keys(postDetail).length !== 0) {
            let id = JSON.parse(postDetail);
            // if (
            //   dashBoardType == "private-forum-community-answer-and-reply-mention"
            // ) {

            path = `/${this.props.workspaceType}/forum-description/${id.postId}/forum-answer/${id?.subjectId} `;
            // } else {
            // path = `/${this.props.workspaceType}/forum-community/${
            //   id?.subjectId
            // }/${"sub"}`;
            // }
          } else {
            path = `/${this.props.workspaceType}/groupsList`;
          }
        } else if (typeid === 3 && dashBoardType === "groups") {
          if (
            status === "Announcement" &&
            postDetail &&
            !this.props.notification.includes("A new community has")
          ) {
            path = `/${this.props.workspaceType}/calendar`;
          } else if (postDetail) {
            if (Object.keys(postDetail).length !== 0) {
              let id = JSON.parse(postDetail);
              if (id?.communityType === 2) {
                path = `/${this.props.workspaceType}/forum-community/${
                  id?.subjectId
                }/${"sub"}`;
              } else {
                path = `/${this.props.workspaceType}/groupDescription/${
                  id?.subjectId
                }/${"sub"}`;
              }
            } else {
              path = `/${this.props.workspaceType}/groupsList`;
            }
          }
        } else if (postDetail && typeid === 11 && status === "requested") {
          if (Object.keys(postDetail).length !== 0) {
            let id = JSON.parse(postDetail);

            path = `/${this.props.workspaceType}/request-dis/${
              id?.jobId
            }/${"other-req"}`;
          }
        } else if (postDetail && typeid === 11 && status === "challenge") {
          if (Object.keys(postDetail).length !== 0) {
            let id = JSON.parse(postDetail);

            path = `/${this.props.workspaceType}/challengeDescription/${this.props.referenceId}/${id?.subjectName}`;
          }
        } else if (postDetail && typeid === 11 && status === "Announcement") {
          if (Object.keys(postDetail).length !== 0) {
            let id = JSON.parse(postDetail);

            path = `/${this.props.workspaceType}/learning-hub`;
          }
        } else if (
          postDetail &&
          typeid === 11 &&
          dashBoardType === "resource"
        ) {
          if (Object.keys(postDetail).length !== 0) {
            let id = JSON.parse(postDetail);

            path = `/${this.props.workspaceType}/learningAppDesription/${this.props.referenceId}/${id?.subjectName}`;
          }
        } else if (typeid === 3 && dashBoardType === "post_subscribe") {
          path = `/${this.props.workspaceType}/home`;
        } else if (typeid === 1 && dashBoardType === "friend_send") {
          if (Object.keys(postDetail).length !== 0) {
            path = `/${this.props.workspaceType}/friendDescription/${this.props.nSenderId}`;
          } else {
            path = `/${this.props.workspaceType}/mynetwork`;
          }
          // path = `/friendDescription/${this.props.nSenderId}`;
        } else if (typeid === 2 && dashBoardType === "friend_accept") {
          path = `/${this.props.workspaceType}/friendDescription/${this.props.nSenderId}`;
        } else if (typeid === 3 && type === "instantMeeting") {
          path = `/meeting`;
        } else if (typeid === 5 && dashBoardType === "question") {
          path = `/community/questions/${question_id}`;
        } else if (
          typeid === 5 &&
          (dashBoardType === "answer" || dashBoardType === "answerReply")
        ) {
        } else if (typeid === 5 && dashBoardType === "questionUpvotes") {
          path = `/community/questions/${question_id}`;
        } else if (typeid === 5 && dashBoardType === "upVotesAnswer") {
          path = `/community/questions/${question_id}`;
        } else if (typeid == 4) {
          path = `/${this.props.workspaceType}/announcement/${this.props.referenceId}`;
        } else {
          // sessionStorage.setItem('APP_REDIRECT_PATH', path)
          path = `/${this.props.workspaceType}/home`;
          this.props.history.push(`/redirect`);
        }

        sessionStorage.setItem("APP_REDIRECT_PATH", path);
        this.props.history.push(`/redirect`);
        if (data) {
          this.deleteProcess(data);
        }
        // this.deleteNotification(typeid, notificationId, dashhBoardType, question_id)
      }
    }
  }
  deleteProcess = (data, nothide) => {
    let notificationId = [];
    let objectArray = [];
    data &&
      data.forEach((element) => {
        notificationId.push(element.notificationId);
        objectArray.push({
          notificationId: element.notificationId,
        });
      });
    console.log("array", objectArray);
    this.deleteCustomNotification(notificationId, objectArray, nothide);
  };

  getBase64String(userId) {
    let getBase64String = { userId: userId };
    var response = ForumService.getUserPhotoCache(getBase64String);
    if (response) {
      var value = response.data.data;
      if (value && value.userPhoto) {
        var base64String = "";
        base64String = `data:image;base64,${response.data.data.userPhoto}`;
        this.setState({ userImg: base64String, base64StringState: true });
      }
    } else {
      ForumService.getUserPhoto(getBase64String)
        .then((response) => {
          ForumService.setUserPhotoCache(getBase64String, response);
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.userPhoto
          ) {
            var base64String = "";
            base64String = `data:image;base64,${response.data.data.userPhoto}`;
            this.setState({ userImg: base64String, base64StringState: true });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
const mapDispacthToProps = (dispatch) => {
  return {
    getAdvertisementData: (id, type) =>
      dispatch(getAdvertisementData(id, type)),
    setSelectedUserAction: (data) => dispatch(setSelectedUserAction(data)),
  };
};
const mapStateToProps = function (state) {
  return {
    workspaceType: state.loginDetailsApi.loginDetails.userWorkSpace?.slug,
    loginDetails: state.loginDetailsApi.loginDetails,
  };
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(withRouter(NotificationCard));
