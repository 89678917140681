import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDirectory,
  userFavourite,
} from "../../redux/actions/directoryActions";
import * as APPCONFIG from "../../config/app-config.js";
import BlockUi from "react-block-ui";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import {
  Avatar,
  Chip,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Grid } from "@material-ui/core";
import { giveUserAction } from "../../utils";
import { needPhotVerification } from "../../redux/actions/commonApiActions";
import dummyimage from "../../img/home/directory.jpg";
import { MdLocationOn, MdOutlineBookmarkBorder } from "react-icons/md";
import { BsBookmarkCheckFill, BsBookmarkPlusFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import dImage from "../../images/coaching.png";
import defaultAvatar from "../../images/icons/default_bg.png";

function DirectoryList(props) {
  const dispatch = useDispatch();
  const { t } = props;
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const { directoryList, loaderStatus, seeMoreOption, activePage } =
    useSelector((state) => state.directory);
  const [location, setLocation] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const [serviceCategory, setServiceCategory] = useState("select");
  const areaOfInterest = useSelector(
    (state) => state.commonApi.areaofinterestlist
  );
  const workspaceType = useSelector(
    (state) => state.loginDetailsApi.loginDetails?.userWorkSpace?.slug
  );
  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  useEffect(() => {
    // if (props.location.state?.from === "back") {
    //   console.log("Back option");
    //   setPage(activePage ? activePage + 1 : 1);
    // } else {
    let data = {
      searchKey: "",
      location: "",
      category: "",
      owner: false,
      userId: loginDetails.uId,
      bookmark: false,
    };
    console.log("location", props.location.state);

    if (props?.location?.state?.type === "USER_ID_FILTER") {
      console.log("List based on user id");
      data.userId = props?.location?.state?.userId
        ? props?.location?.state?.userId
        : loginDetails.uId;
      data.type = "USER_ID_FILTER";
      data.owner = true;
      setClearFilter(true);
    }

    dispatch(getAllDirectory(data, 0, false));
    setPage(1);
    // }
  }, []);
  const clearFilterData = () => {
    let data = {
      searchKey: "",
      location: "",
      category: "",
      owner: false,
      userId: loginDetails.uId,
      bookmark: false,
    };
    setClearFilter(false);
    dispatch(getAllDirectory(data, 0, false));
    setPage(1);
  };
  const seeMoreDirectory = () => {
    let data = {
      searchKey: searchKey,
      location: location,
      category: serviceCategory === "select" ? "" : serviceCategory,
      owner: filterType === "all" ? false : true,
      bookmark: filterType === "bookmark" ? true : false,
      // userId: loginDetails.uId,
    };
    if (clearFilter) {
      data.userId = props?.location?.state?.userId
        ? props?.location?.state?.userId
        : loginDetails.uId;
      data.type = "USER_ID_FILTER";
      data.owner = true;
    }
    dispatch(getAllDirectory(data, page, true));
    setPage(page + 1);
  };
  const searchDirectory = (type, value) => {
    let data = {
      searchKey: searchKey,
      location: location,
      category: serviceCategory === "select" ? "" : serviceCategory,
      owner: filterType === "all" ? false : true,
      // userId: loginDetails.uId,
      bookmark: value === "bookmark" ? true : false,
    };
    if (type === "searchKey") {
      setSearchKey(value);
      data.searchKey = value;
    } else if (type === "location") {
      setLocation(value);
      data.location = value;
    } else if (type === "filterType") {
      setFilterType(value);
      data.owner = value === "all" || value === "bookmark" ? false : true;
    } else {
      setServiceCategory(value);
      data.category = value === "select" ? "" : value;
    }
    if (clearFilter) {
      data.userId = props?.location?.state?.userId
        ? props?.location?.state?.userId
        : loginDetails.uId;
      data.type = "USER_ID_FILTER";
      data.owner = true;
    }
    dispatch(getAllDirectory(data, 0, false));
  };
  const directoryDescription = (directoryId) => {
    props.history.push(`/${workspaceType}/directory-details/${directoryId}`);
  };
  const createNew = () => {
    if (giveUserAction(loginDetails?.profile_complete)) {
      console.log("user-action");
      dispatch(needPhotVerification(true));
    } else {
      props.history.push(
        `/${workspaceType}/business/user-directory/${"new-directory"}`
      );
    }
  };
  const bookMarkStatus = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    Swal.fire({
      // title: logoutTitle,
      text: item.bookmark
        ? "Do you want remove this directory in favourite ⭐ "
        : "Do you want add this directory in favourite ⭐",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        let data = {
          directoryId: item.directoryId,
          active: !item.bookmark,
        };
        dispatch(userFavourite(data));
        toast.success(
          item.bookmark ? "Removed successfully" : "Added successfully"
        );
      }
    });
  };
  return (
    <BlockUi
      tag="div"
      message={t("Loading, please wait")}
      style={{ minHeight: "130px" }}
      className="wallLoadingPage"
      blocking={loaderStatus}
    >
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <h3 className="listHomeTitle">{t("Business Directory")}</h3>
      </div>
      <div className="spaceFiletCard newPostCardWrapper">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id=""
              label=""
              style={{ width: "100%", height: "100%" }}
              variant="outlined"
              value={searchKey}
              onChange={(e) => searchDirectory("searchKey", e.target.value)}
              placeholder={t("Search service & product ...")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <div className="d-flex flex-wrap directory-wrap align-items-center">
          <TextField
            className="text-filed-border"
            style={{ background: "white" }}
            id=""
            label=""
            variant="outlined"
            value={location}
            placeholder={t("Search location") + "..."}
            onChange={(e) => searchDirectory("location", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />{" "}
          <TextField
            style={{ maxWidth: "15rem" }}
            className="text-filed-border"
            id="dropdown"
            select
            label=""
            variant="outlined"
            type="text"
            required=""
            name="serviceCategory"
            value={serviceCategory}
            onChange={(e) => {
              searchDirectory("serviceCategory", e.target.value);
            }}
          >
            <MenuItem value="select">Select Category</MenuItem>
            {areaOfInterest &&
              areaOfInterest.map((item) => (
                <MenuItem value={item.areaName} id={item.areaName}>
                  {item.areaName}
                </MenuItem>
              ))}
          </TextField>
          {clearFilter ? (
            <Chip
              style={{
                overflow: "hidden",
                Whitespace: "nowrap",
                fontSize: "12px",
                textOverflow: "ellipsis",
                textTransform: "capitalize",
                maxWidth: "138px",
                border: "1px solid",
                background: "white",
              }}
              label={"Clear Filter"}
              onDelete={() => clearFilterData()}
            />
          ) : (
            <TextField
              className="text-filed-border"
              id="dropdown"
              select
              label=""
              variant="outlined"
              type="text"
              required=""
              name="filterType"
              value={filterType}
              onChange={(e) => {
                searchDirectory("filterType", e.target.value);
              }}
            >
              <MenuItem value="all">Listing</MenuItem>
              <MenuItem value="me">Your Listing</MenuItem>
              <MenuItem value="bookmark">Favourite</MenuItem>
            </TextField>
          )}
          {/* {serviceCategory && (
              <Chip
                style={{
                  overflow: "hidden",
                  Whitespace: "nowrap",
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  textTransform: "capitalize",
                  background: "aliceblue",
                }}
                avatar={
                  <Avatar>{serviceCategory.charAt(0).toUpperCase()}</Avatar>
                }
                label={serviceCategory}
                onDelete={() => searchDirectory("serviceCategory", "")}
              />
            )} */}
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-12 col-md-12 col-lg-4  ">
          <section
            className="cardWrapper-2 font-bold d-flex align-items-center flex-column  "
            style={{ gap: "2rem" }}
          >
            <p className="text-secondary text-center">
              {t("Do you have a business you would like to promote") + "?"}
            </p>
            <button onClick={() => createNew()} className="b-17">
              {t("ENROLL YOUR SERVICE")}
            </button>
            <img src={dImage} style={{ width: "12rem" }} alt="logo" />
          </section>
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          {directoryList &&
            directoryList.map((item) => (
              <section
                key={item?.slug}
                className="d-flex ring   justify-content-center cardWrapper-2 mb-4 flex-wrap position-relative"
                style={{ gap: "2rem", cursor: "pointer" }}
                onClick={() => directoryDescription(item?.slug)}
              >
                <div className="bd-container">
                  <img
                    src={`${APPCONFIG.API_URL}auth/image/${item?.serviceImage}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = dummyimage;
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <section className="d-flex gap-2 flex-wrap">
                    <img
                      style={{ cursor: "pointer", width: "4rem" }}
                      // onClick={() =>
                      //   this.showFriendsProfile(this.state.postValue.userId)
                      // }
                      className="rounded-circle"
                      src={
                        APPCONFIG.API_URL +
                        "api/getUserPhoto/" +
                        item.userId +
                        "/" +
                        item.userName
                      }
                      alt="user"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = defaultAvatar;
                      }}
                    />{" "}
                    <ul className="m-0 p-0" style={{ listStyle: "none" }}>
                      <li className="font-weight-bold text-capitalize mt-1">
                        {item.userName}
                      </li>
                      <li
                        className="text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {item.location}
                      </li>
                    </ul>
                  </section>
                  <h5 className="font-weight-bold mt-3 text-secondary">
                    {item.serviceName}
                  </h5>
                  <p
                    className="elpsis-line"
                    dangerouslySetInnerHTML={{
                      __html: item.serviceDetails,
                    }}
                  />
                  {item.serviceCategory && (
                    <p className="mt-3 bg-color" style={{ fontSize: "12px" }}>
                      Category : {item.serviceCategory}
                    </p>
                  )}
                </div>
                {loginDetails.uId !== item.userId && (
                  <>
                    {item.bookmark ? (
                      <BsBookmarkCheckFill
                        className="directory-mark bg-color"
                        onClick={(e) => bookMarkStatus(e, item)}
                      />
                    ) : (
                      <BsBookmarkPlusFill
                        className="directory-mark"
                        onClick={(e) => bookMarkStatus(e, item)}
                      />
                    )}
                  </>
                )}
              </section>
            ))}
          {!loaderStatus &&
            seeMoreOption &&
            directoryList &&
            directoryList.length > 0 && (
              <div
                class="d-flex justify-content-center mt-4 "
                style={{ marginBottom: "12rem" }}
              >
                <button onClick={seeMoreDirectory}>{t("See more")}</button>
              </div>
            )}
          {!loaderStatus && directoryList && directoryList.length === 0 && (
            <div className="aboutMyRequestWrapper mt-4">
              <p style={{ textAlign: "center" }}>
                {t("No more directory available")}
              </p>
            </div>
          )}
        </div>
      </div>
      {/* <section>
                <img
                  src={`${APPCONFIG.API_URL}auth/image/${item?.serviceImage}`}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = dummyimage;
                  }}
                />
              </section>
              <section className="w-100">
                <div>
                  <h5 className="m-0 pt-3" style={{ fontWeight: "400" }}>
                    {item?.serviceName}
                  </h5>
                  <p
                    className="text-secondary mt-2"
                    style={{ fontSize: "10px" }}
                  >
                    {item?.serviceCategory}
                  </p>
                  <p
                    className="font-weight-bold text-secondary mt-2"
                    style={{ fontSize: "13px", testTransform: "capitalize" }}
                  >
                    <MdLocationOn
                      className="h-auto mr-1"
                      style={{ width: "17px" }}
                    />
                    {item?.location ? item?.location : "-"}
                  </p>
                </div>
                <button onClick={() => directoryDescription(item?.slug)}>
                  View
                </button>
              </section>
              {loginDetails.uId !== item.userId && (
                <>
                  {item.bookmark ? (
                    <BsBookmarkCheckFill
                      style={{ color: "aqua" }}
                      className="directory-mark"
                      onClick={(e) => bookMarkStatus(e, item)}
                    />
                  ) : (
                    <BsBookmarkPlusFill
                      className="directory-mark"
                      onClick={(e) => bookMarkStatus(e, item)}
                    />
                  )}
                </>
              )} */}
    </BlockUi>
  );
}

export default withTranslation()(withRouter(DirectoryList));
