import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ProfileService from "../../api/ProfileService";
import { toast } from "react-toastify";
import Utils from "../../utils/Utils";
import Swal from "sweetalert2";
import AuthService from "../../session/AuthService";
import { useDispatch } from "react-redux";
import { getLogoutData } from "../../redux/actions/logoutActions";
import StepperFileOption from "../Wizard/StepperFileOption";
import { API_URL } from "../../config/app-config";
import { MdOutlineAttachFile } from "react-icons/md";
import yuukke from "../../img/default_avatar.jpg";

const professionalList = [
  "Student",
  "Working professional",
  "Practicing profession (Auditoer,lawyer and etc)",
  "Entrepreneur",
  "Homemaker",
  "Freelancer",
];
const YuukkeEventForm = () => {
  const [state, setState] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    professional: "Working professional",
    slug: "",
  });
  const dispatch = useDispatch();

  const updateState = (name, value) => {
    setState((previous) => ({
      ...previous,
      [name]: value,
    }));
  };
  useEffect(() => {
    const slug =
      window.location.pathname.slice(
        window.location.pathname.lastIndexOf("/") + 1
      ) ?? "";
    updateState("slug", slug);
    console.log("slug", slug);
  }, [window.location.pathname]);
  const handleClose = () => {
    console.log("State", state);
  };
  const updateDetails = (e) => {
    e.preventDefault();
    if (!state.userName || !state.email || !state.phoneNumber) {
      toast.warning("Fill all the required fields ...");
    } else if (!Utils.emailValidation(state.email)) {
      toast.warning("Enter valid email address");
    } else {
      let data = {
        name: state.userName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        slug: state.slug,
        professional: state.professional,
      };
      ProfileService.eventRegister(data)
        .then((response) => {
          console.log("response", response);

          Swal.fire({
            icon: "success",
            title:
              "Thank you for showing your interest ,you will get meeting link via mail shortly",
            showConfirmButton: true,
          }).then((result) => {
            AuthService.removeAuth();
            dispatch(getLogoutData());
            AuthService.triggerLogout();
          });
        })
        .catch((error) => {
          toast.error("Something went wrong , please try again later");
        });
    }
  };

  return (
    <Modal
      show="true"
      onHide={handleClose}
      style={{
        background:
          "linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(45, 113, 253, 1) 100%)",
      }}
    >
      <Modal.Header className="justify-content-center align-items-center bg-white flex-column">
        {/* <h6 style={{ fontWeight: "bold" }}>
          {" "}
          <img
            src="https://corporate.yuukke.com/bcav2/auth/image/7e5c936f-e44c-4ee9-a9c2-29a9cb1606a5"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = yuukke;
            }}
            // src={yuukke}
            class="animi-icon"
            alt="icon"
          />
          Effective Marketing Doing More With Less
        </h6> */}
        <img
          src="https://corporate.yuukke.com/bcav2/auth/image/7486ebc9-c107-482a-9e5c-02e798d5e851"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = yuukke;
          }}
          alt="img"
          style={{ borderRadius: "8px" }}
          className="h-auto w-auto"
        />
      </Modal.Header>
      <Card className="profileCard">
        <p
          style={{ fontSize: "12px", textAlign: "justify" }}
          className="text-secondary p-3"
        >
          Women And Financial Independence - Ms Neha Singh
        </p>
        <Card.Body className="profile-form">
          <div className="formFieldGroup p-3">
            <form onSubmit={updateDetails}>
              <label className="mt-2 form-label">Name *</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="text"
                value={state.userName}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("userName", e.target.value);
                }}
                fullWidth={true}
                required
              />

              <label className="mt-2 form-label">Email *</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="email"
                value={state.email}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("email", e.target.value);
                }}
                fullWidth={true}
                required
              />

              <label className="mt-2 form-label">Phone Number *</label>
              <TextField
                id="textField"
                label=""
                variant="outlined"
                type="number"
                value={state.phoneNumber}
                style={{ background: "white" }}
                onChange={(e) => {
                  updateState("phoneNumber", e.target.value);
                }}
                fullWidth={true}
                required
              />
              <label className="mt-2 form-label">Profession *</label>

              <FormControl variant="outlined" fullWidth>
                <Select
                  name=""
                  value={state.professional}
                  onChange={(e) => updateState("professional", e.target.value)}
                  label=""
                >
                  {professionalList.map((item, index) => (
                    <MenuItem value={item}>
                      <em>{item}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="pl-2 pr-2">
                <button
                  className="w-100 mt-4"
                  type="submit"
                  value="Submit"
                  // onClick={(e) => updateDetails(e)}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
};

const ShowImageFile = (props) => {
  const file = props?.files;
  const name = file?.name ? "props?.files?.name" : "image";
  return (
    <div key={props.index}>
      <Chip
        icon={<MdOutlineAttachFile />}
        className="fileChip"
        label={name.substring(0, 12)}
        size="small"
        onDelete={() => props.removeFiles(props?.keyFile, props?.index)}
        variant="outlined"
      />

      {file && (
        <img
          style={{
            margin: "8px",
            width: "auto",
            height: "80px",
          }}
          src={
            file?.name
              ? URL.createObjectURL(file)
              : `${API_URL}auth/image/${file}`
          }
          alt="img"
        ></img>
      )}
    </div>
  );
};

export default YuukkeEventForm;
