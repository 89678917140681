import { Chip, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import StepperFileOption from "../Wizard/StepperFileOption";
import TextEditorQuestion from "../../components/TextEditorQuestion";
import { toast } from "react-toastify";
import Utils from "../../utils/Utils";
import ForumService from "../../api/ForumService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { MdOutlineAttachFile } from "react-icons/md";
import {
  getDirectoryCategoryList,
  getSingleDirectory,
} from "../../redux/actions/directoryActions";
import { API_URL } from "../../config/app-config";
import { Typeahead } from "react-bootstrap-typeahead";

const DirectoryForm = (props) => {
  const { t } = props;
  const [loaderStatus, setLoaderStatus] = useState(false);
  const areaOfInterest = useSelector(
    (state) => state.commonApi.areaofinterestlist
  );
  const workspaceName = useSelector(
    (state) => state.loginDetailsApi.loginDetails?.userWorkSpace?.slug
  );

  const loginDetails = useSelector(
    (state) => state.loginDetailsApi.loginDetails
  );
  const [category, setCategory] = useState([]);

  const dispatch = useDispatch();
  console.log("loginDetails", loginDetails);
  const [state, setState] = useState({
    email: loginDetails.email,
    serviceCategory: "",
    serviceName: "",
    phoneNumber: loginDetails.phoneNumber ? loginDetails.phoneNumber : "",
    userName: loginDetails.dName,
    // slotTime: new Date(),
    serviceImage: null,
    galleryImage: null,
    serviceDetails: "",
    location: loginDetails.location ? loginDetails.location : "",
    editState: false,
    editorValue: "",
    removeIds: [],
    serviceType: "service",
    availableInOnline: false,

    // cost: 0,
  });
  const { singleDirectoryList, directoryCategoryList } = useSelector(
    (state) => state.directory
  );
  useEffect(() => {
    console.log("Params", props.match?.params?.slug);
    if (props.match?.params?.slug !== "new-directory") {
      dispatch(getSingleDirectory(props.match?.params?.slug));
      updateState("editState", true);
    }

    if (directoryCategoryList?.length === 0) {
      dispatch(getDirectoryCategoryList(""));
    }
  }, []);
  useEffect(() => {
    if (state.editState && singleDirectoryList?.directoryId) {
      console.log("Update-state", singleDirectoryList);
      // if(singleDirectoryList?.serviceCategory){
      //   dispatch(getDirectoryCategoryList("A"));

      // }
      setState({
        email: singleDirectoryList?.email,
        serviceCategory: singleDirectoryList?.serviceCategory,
        serviceName: singleDirectoryList?.serviceName,
        phoneNumber: singleDirectoryList.phoneNumber
          ? singleDirectoryList.phoneNumber
          : "",
        userName: singleDirectoryList.userName,
        // slotTime: new Date(),
        serviceImage: [singleDirectoryList.serviceImage],
        galleryImage: singleDirectoryList.galleryImages
          ? singleDirectoryList.galleryImages
          : [],
        serviceDetails: singleDirectoryList.serviceDetails,
        location: singleDirectoryList.location
          ? singleDirectoryList.location
          : loginDetails.location,
        editState: true,
        editorValue: singleDirectoryList.serviceDetails,
        removeIds: [],
        directoryId: singleDirectoryList.directoryId,
        serviceType: singleDirectoryList.serviceType,
        availableInOnline: singleDirectoryList.onlineAvailable,
      });
    }
  }, [singleDirectoryList]);

  useEffect(() => {
    if (state.editState && singleDirectoryList?.directoryId) {
      const data = directoryCategoryList.find(
        (item) => item.id === singleDirectoryList?.parentCategoryId
      );
      console.log("update category", data);
      if (data) {
        setCategory([data]);
      }
    }
  }, [directoryCategoryList, singleDirectoryList]);
  console.log("state", state);

  const updateState = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const serviceImageUpdate = (file) => {
    if (file) {
      updateState("serviceImage", file);
    }
  };
  const galleryImageUpdate = (file) => {
    if (file) {
      updateState(
        "galleryImage",
        state.editState ? state.galleryImage.concat(file) : file
      );
    }
  };
  const editorStateValues = (values) => {
    // var htmlString = values;
    // var plainString = htmlString.replace(/<[^>]+>/g, "");
    // if (plainString.length === 1) {
    //   updateState("serviceDetails", values);
    // } else {
    updateState("serviceDetails", values);
    // }
  };
  const removeFiles = (name) => {
    updateState(name, null);
  };
  const removeGalleryFiles = (file, imageIndex) => {
    if (state.galleryImage) {
      const imageList = state.galleryImage.filter(
        (item, index) => index !== imageIndex
      );
      console.log("imageList", imageList);
      state.removeIds.push(imageIndex);
      if (imageList && imageList?.length !== 0) {
        updateState("galleryImage", imageList);
      } else {
        updateState("galleryImage", null);
      }
    }
  };

  const updateDetails = () => {
    if (!state.email || !Utils.emailValidation(state.email)) {
      toast.warning(`${props.t("Enter Valid Email")}`);
    } else if (
      !state.email ||
      // !state.serviceCategory ||
      !state.serviceName ||
      !state.userName ||
      !state.serviceDetails ||
      !state.location
    ) {
      toast.warning(`${props.t("Fill all the required fields")}`);
    } else if (category.length === 0) {
      toast.warning("Select your directory related category ...");
    } else if (!state.serviceImage) {
      toast.warning(`${props.t("Add service image")}`);
    } else {
      const formData = new FormData();
      formData.append("serviceName", state.serviceName);
      formData.append("serviceCategory", category[0]?.value);
      formData.append("categoryId", category[0]?.id ?? 0);
      // formData.append("slotTime", moment(state.slotTime).format("LT"));
      formData.append("serviceDetails", state.serviceDetails);
      // formData.append("cost", state.cost);
      formData.append("userName", state.userName);
      formData.append("phoneNumber", state.phoneNumber);
      formData.append("email", state.email);
      formData.append("userId", loginDetails.uId);
      formData.append("location", state.location);
      formData.append("serviceType", state.serviceType);
      formData.append("onlineAvailable", state.availableInOnline);
      // formData.append("")
      if (state.editState) {
        if (!state.removeIds) {
          formData.append("removeGalleryImage", state.removeIds);
        }
        formData.append("directoryId", state.directoryId);
        if (state?.serviceImage[0]?.name) {
          formData.append("serviceImagev2", state?.serviceImage[0]);
        }
        if (state.galleryImage) {
          const list = state.galleryImage?.reduce((result, item) => {
            console.log("list item", item);

            if (item?.name) {
              result.push(item);
            }
            return result;
          }, []);
          console.log("list", list);
          if (list) {
            list.map((item) => {
              formData.append("galleryImagesv2", item);
            });
          }
        }
      } else {
        formData.append("serviceImagev2", state?.serviceImage[0]);
        if (state.galleryImage) {
          state.galleryImage?.map((item) => {
            formData.append("galleryImagesv2", item);
          });
        }
      }
      for (var pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
      console.log(" state : ", state, "state");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      setLoaderStatus(true);
      ForumService.saveOrUpdateDirectoryList(formData, config)
        .then((response) => {
          setLoaderStatus(false);
          console.log(" response : ", response);
          if (response.data.status == -1) {
            toast.error("Saving Error");
          } else {
            toast.success("Details Update Successfully");

            props.history.push(
              // `/${workspaceName}/directory-details/${props.match?.params?.slug}`
              `/${workspaceName}/directory-details/${response.data.data?.directory?.slug}`
            );
          }
        })
        .catch(() => {
          toast.error("Saving Error");
          props.history.push(
            `/${workspaceName}/directory-details/${props.match?.params?.slug}`
          );
        });
    }
  };
  const backtoList = () => {
    props.history.push(`/${workspaceName}/directory`);
  };
  const setSingleSelections = (e) => {
    console.log("update-1", e);
    // dispatch(getDirectoryCategoryList(e));
  };

  console.log("state", category);
  return (
    <>
      <BlockUi
        tag="div"
        message={t("Loading, please wait")}
        style={{ minHeight: "130px" }}
        className="wallLoadingPage"
        blocking={loaderStatus}
      >
        <div className="askAQuestionCardWrapper">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between  col-12">
              <h4>
                {state.editState
                  ? t("Update Your Directory")
                  : t("Create Your Directory")}
              </h4>
              <p
                onClick={backtoList}
                className="bg-color"
                style={{ cursor: "pointer" }}
              >
                {" "}
                <ArrowBackIcon className="mr-2" />
                {t("Back")}
              </p>
            </div>
            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">{t("Name")} *</p>
              <TextField
                type="userName"
                variant="outlined"
                value={state.userName}
                onChange={(e) => updateState("userName", e.target.value)}
              />{" "}
            </div>
            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">{t("Email")} *</p>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                value={state.email}
                style={{ backgroundColor: "white" }}
                onChange={(e) => updateState("email", e.target.value)}
              />{" "}
            </div>

            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">{t("Phone Number")} </p>
              <TextField
                type="number"
                variant="outlined"
                value={state.phoneNumber}
                style={{ backgroundColor: "white" }}
                onChange={(e) => updateState("phoneNumber", e.target.value)}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 15))}
              />{" "}
            </div>
            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">
                {state.serviceType === "service"
                  ? t("Business Name")
                  : t("Product Name")}{" "}
                *
              </p>
              <TextField
                type="serviceName"
                variant="outlined"
                value={state.serviceName}
                style={{ backgroundColor: "white" }}
                onChange={(e) => updateState("serviceName", e.target.value)}
              />{" "}
            </div>
            {/* <div className="d-flex flex-column">
            <p class="text-secondary">Cost *</p>
            <TextField
              type="number"
              variant="outlined"
              value={state.cost}
              style={{ backgroundColor: "white" }}
              onChange={(e) => updateState("cost", e.target.value)}
              onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
            />{" "}
          </div> */}

            {/* <div className="d-flex flex-column">
            <p class="text-secondary">{t("Slot Time")} </p>
            <ThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  value={state.slotTime}
                  onChange={(e) => updateState("slotTime", e)}
                  clearable
                  ampm={false}
                  style={{
                    border: "2px solid #80808059",
                    padding: "2px",
                    borderRadius: "4px",
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </div> */}
            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">Business Location *</p>
              <TextField
                type="text"
                variant="outlined"
                value={state.location}
                style={{ backgroundColor: "white" }}
                onChange={(e) => updateState("location", e.target.value)}
              />{" "}
            </div>

            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary ">{t("Select Directory Type")} *</p>
              <TextField
                className="text-filed-border w-100"
                id="dropdown"
                select
                label=""
                variant="outlined"
                type="text"
                required=""
                name="serviceType"
                value={state.serviceType}
                onChange={(e) => {
                  updateState("serviceType", e.target.value);
                }}
              >
                <MenuItem value="service">Business Service</MenuItem>
                <MenuItem value="product">Product</MenuItem>
              </TextField>
            </div>
            <div className="col-12 mt-3">
              <p class="text-secondary">
                {state.serviceType === "service"
                  ? t("Service Category")
                  : t("Product Category")}{" "}
                *
              </p>
              <Typeahead
                id="basic-typeahead-single"
                // labelKey="name"
                onChange={(e) => setCategory(e)}
                onInputChange={(e) => setSingleSelections(e)}
                options={directoryCategoryList}
                selected={category}
                placeholder="Search Category's..."
              />
              {/* <TextField
                fullWidth
                id="dropdown"
                select
                label=""
                variant="outlined"
                type="text"
                required=""
                name="serviceCategory"
                value={state.serviceCategory}
                onChange={(e) => {
                  updateState("serviceCategory", e.target.value);
                }}
              >
                {directoryCategoryList &&
                  directoryCategoryList.map((item) => (
                    <MenuItem value={item.categoryValue} id={item.id}>
                      {item.categoryName}
                    </MenuItem>
                  ))}
              </TextField> */}
            </div>
            <div className="col-12  gap-2 mt-2">
              <div
                className="d-flex"
                style={{ cursor: "pointer", width: "fit-content" }}
                onClick={(e) => {
                  updateState("availableInOnline", !state.availableInOnline);
                }}
              >
                <input
                  type="checkbox"
                  value={state.availableInOnline}
                  defaultChecked={state.availableInOnline}
                  checked={state.availableInOnline}
                  id="morningTimeSelect"
                />

                <p class="text-secondary ml-2">Available in Online</p>
              </div>
            </div>
            <div className="col-12 mt-3">
              <p class="text-secondary">
                {state.serviceType === "service"
                  ? t("Service Details")
                  : t("Product Details")}{" "}
                *
              </p>

              <div
                style={{
                  border: "2px solid #80808059",
                  borderRadius: "7px",
                }}
              >
                <TextEditorQuestion
                  editorStateValue={editorStateValues}
                  editorValue={state.editorValue}
                />
              </div>
            </div>

            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">
                {state.serviceType === "service"
                  ? t("Business Logo")
                  : t("Product Image")}{" "}
                *
              </p>

              {!state.serviceImage && (
                <StepperFileOption
                  onUploadSuccess={serviceImageUpdate}
                  acceptedType=".jpg,.jpeg,.png,.svg"
                  count={1}
                  multiple={false}
                />
              )}
              {state.serviceImage &&
                state.serviceImage.length !== 0 &&
                state.serviceImage.map((image, index) => (
                  <ShowImageFile
                    files={image}
                    removeFiles={removeFiles}
                    keyFile="serviceImage"
                    index={index}
                    count={1}
                  />
                ))}
            </div>
            <div className="d-flex flex-column col-12 col-md-6 mt-3">
              <p class="text-secondary">
                {state.serviceType === "service"
                  ? t("Business Gallery Images")
                  : t("Product Gallery Images")}{" "}
              </p>

              {(!state.galleryImage || state.editState) && (
                <StepperFileOption
                  onUploadSuccess={galleryImageUpdate}
                  acceptedType=".jpg,.jpeg,.png,.svg"
                  count={8}
                  multiple={true}
                />
              )}
              {state.galleryImage && (
                <div className="d-flex flex-wrap">
                  {state.galleryImage.length !== 0 &&
                    state.galleryImage.map((image, index) => (
                      <ShowImageFile
                        files={image}
                        removeFiles={removeGalleryFiles}
                        keyFile="galleryImage"
                        index={index}
                      />
                    ))}
                </div>
              )}
            </div>

            <div className="grid-wrap-dir col-12 mt-3">
              <button style={{ width: "100%" }} onClick={updateDetails}>
                {state.editState ? t("Update") : t("Create")}
              </button>
            </div>
          </div>
        </div>
      </BlockUi>
    </>
  );
};

const ShowImageFile = (props) => {
  const file = props?.files;
  const name = file?.name ? "props?.files?.name" : "image";
  return (
    <div>
      <Chip
        icon={<MdOutlineAttachFile />}
        className="fileChip"
        label={name.substring(0, 12)}
        size="small"
        onDelete={() => props.removeFiles(props?.keyFile, props?.index)}
        variant="outlined"
      />

      {file && (
        <img
          style={{
            margin: "8px",
            width: "80px",
            height: "80px",
          }}
          src={
            file?.name
              ? URL.createObjectURL(file)
              : `${API_URL}auth/image/${file}`
          }
          alt="img"
        ></img>
      )}
    </div>
  );
};

export default withTranslation()(withRouter(DirectoryForm));
